@use '../abstracts' as *;

/* sitemap
*************************************************** */
.sitemap--menu {
  flex: 1;
  &:not(:last-child) {
    @include mq(sp, min, ps) {
      margin-right: perc(80,1080,'%');
    }
    @include mq(sp) {
      margin-bottom: 24px;
    }
  }
}
.sitemap--menu a,
.sitemap--menu span {
  display: block;
}
.sitemap--menu a {
  position: relative;
}
.sitemap--menu a:hover {
  color: var(--clr-main, #1D7FB7);
}
.sitemap--menu > li > a {
  font-size: rem(18);
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: .5em;
  margin-bottom: 1.25em;
}
.sitemap_sub_nav--wrap {
  padding-left: 1.25em;
  margin-bottom: 1.5em;
}
.sitemap_sub_nav a {
  padding-top: .4em;
  padding-bottom: .4em;
  padding-left: 1.25em;
}
.sitemap_sub_nav a::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid var(--clr-main, #1D7FB7);
  border-right: 1px solid var(--clr-main, #1D7FB7);
  transform: rotate(45deg);
}