@use '../abstracts' as *;
.home {
  line-height: 2.5;
  @include mq(sp) {
    line-height: 2.2;
  }
  .bg_gray {
    position: absolute;
    background-color: $gry-d7;
    opacity: .5;
  }
  .bg_grn {
    position: absolute;
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_water_green.png);
    opacity: .5;
  }
  //準備中
  .preparation {
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 20px;;
    }
  }
}

/* hero
********************************************** */
.hero {
  position: relative;
  height: vw(928);
  @include mq(1920) {
    height: 928px;
  }
  @include mq(1500) {
    height: clamp(680px,57.3vw,760px);
  }
  @include mq(1400) {
    height: clamp(680px,57.3vw,720px);
  }
  @include mq(sp) {
    height: inherit;
  }
  .bg_gray {
    position: absolute;
    background-color: $gry-d7;
    opacity: .5;
    z-index: -1;
    &.bg_gray01 {
      bottom: 0;
      left: 0;
      width: vw(776);
      height: vw(600);
      border-radius: 0 50px 50px 0;
      @include mq(1500) {
        bottom: 60px;
        width: vw(800);
      }
    }
    &.bg_gray02 {
      top: -136px;
      right: 0;
      width: vw(600);
      height: vw(856);
      border-radius: 0 0 0 50px;
    }
  }
  .deco {
    position: absolute;
    &.deco01 {
      right: vw(60);
      bottom: vw(222);
      width: vw(442);
      z-index: 1;
      @include mq(1500) {
        display: none;
      }
    }
    &.deco02 {
      left: vw(238);
      bottom: vw(-42);
      width: vw(408);
    }
    &.deco03 {
      left: vw(1125);
      bottom: vw(-42);
      width: vw(218);
    }
  }
  .banner {
    position: absolute;
    right: vw(48);
    bottom: min(vw(188),188px);
    width: vw(304);
    z-index: 1;
    @include mq(1500) {
      bottom: vw(228);
    }
    @include mq(1300) {
      bottom: vw(188);
    }
    img {
      transition: var(--transit-default, all .4s ease-out);
      &:hover {
        opacity: .7 !important;
      }
    }
  }
  .btm_imgarea {
    position: absolute;
    bottom: vw(-380);
    right: 0;
    width: vw(448);
    height: vw(504);
    .bg_gray {
      top: vw(118);
      right: 0;
      width: vw(352);
      height: vw(320);
      border-radius: 20px 0 0 20px;
      z-index: -1;
    }
    .btm_img {
      position: absolute;
      width: 100%;
      border-radius: 20px 0 0 20px;
    }
    .btm_deco {
      position: absolute;
      left: vw(40);
      bottom: 0;
      width: vw(360);
      @include mq(1700) {
        left: inherit;
        right: 16px;
        bottom: 32px;
        width: vw(260);
      }
    }
  }
  .hero_inner {
    display: grid;
    justify-content: space-between;
    grid-template-columns: vw(704) calc(vw(302) + vw(56)) vw(712);
    width: 100%;
    padding-top: 16px;
    padding-right: vw(48);
    @include mq(1920) {
      grid-template-columns: 704px calc(vw(302) + vw(56)) 712px;
    }
    @include mq(1500) {
      grid-template-columns: 40vw 26vw 40vw;
      min-height: 600px;
    }
    @include mq(sp) {
      display: block;
      min-height: inherit;
    }
    .hero_txt_slider {
      position: absolute;
      left: 0;
      bottom: vw(-110);
      column-gap: 50px;
      animation: hero_txt_slider 150s linear infinite;
      .hero--catch {
        font-size: vw(120);
        line-height: 1;
        white-space: nowrap;
      }
    }
    .img01 {
      position: relative;
      @include mq(sp) {
        position: absolute;
      }
      img {
        width: 100%;
        border-radius: 0 40px 40px 0;
        @include mq(sp) {
          width: 50%;
          border-radius: 0 20px 20px 0;
        }
        &.deco04 {
          bottom: 50px;
          right: vw(-190);
          width: vw(210);
          max-width: inherit;
          z-index: -1;
          @include mq(1500) {
            display: none;
          }
          @include mq(sp) {
            display: block;
          }
        }
      }
    }
    .hero_ttl {
      position: relative;
      padding-top: 56px;
      padding-left: vw(56);
      @include mq(1280) {
        padding-top: 96px;
      }
      @include mq(sp) {
        width: 100%;
      }
      .img {
        position: absolute;
        bottom: max(vw(-60),-60px);
        right: vw(-154);
        width: vw(400);
        height: vw(300);
        z-index: 1;
        &::before {
          content: "";
          position: absolute;
          top: -8px;
          right: -8px;
          width: 50%;
          height: 50%;
          background-color: $gry-f2;
          border-radius: 0 20px 0 0;
        }
        img {
          width: 100%;
          border-radius: 20px;
        }
      }
      .ttl {
        margin-left: vw(30);
        font-size: rem(50);
        @include lh(50,88);
        letter-spacing: .1em;
        z-index: 1;
      }
      .txt {
        margin-top: 12px;
        @include lh(16,48);
      }
    }
    .img02 {
      padding-top: 40px;
      @include mq(1280) {
        padding-top: 80px;
      }
      @include mq(sp) {
        // position: absolute;
      }
      img {
        width: 100%;
        border-radius: 30px;
      }
    }
  }
}

/* hero_sp
********************************************** */
.hero_sp {
  padding-top: 100px;
  .hero_sp_top {
    position: relative;
    .bg_gray01 {
      top: 20%;
      left: 0;
      width: 80%;
      height: 70%;
      z-index: -1;
      border-radius: 0 40px 40px 0;
    }
    .top {
      padding-right: 5%;
      .img01 {
        flex: 1;
        max-width: 400px;
        margin-right: 30px;
        img {
          border-radius: 0 16px 16px 0;
          margin-bottom: 20px;
        }
      }
      .ttl {
        font-size: sprem(30);
        line-height: 1.8;
        letter-spacing: .1em;
      }
    }
    .btm {
      padding-left: 5%;
      .txt {
        padding-top: 10px;
      }
      .img02 {
        position: relative;
        flex: 1;
        max-width: 400px;
        margin-left: 12px;
        .deco01 {
          position: absolute;
          left: 24px;
          bottom: 8px;
          transform: translateX(-100%);
          width: 120px;
        }
        .img {
          position: relative;
          border-radius: 16px 0 0 16px;
        }
      }
    }
  }
  .hero_sp_btm {
    position: relative;
    padding-top: 80px;
    .hero_txt_slider {
      position: absolute;
      top: 8px;
      left: 0;
      column-gap: 18px;
      animation: hero_txt_slider 100s linear infinite;
      z-index: 1;
      .hero--catch {
        font-size: sprem(50);
        line-height: 1;
        white-space: nowrap;
      }
    }
    .img01 {
      position: relative;
      width: 60%;
      max-width: 400px;
      &::before {
        content: "";
        position: absolute;
        right: -8px;
        bottom: -8px;
        width: 80%;
        height: 50%;
        z-index: -1;
        background-color: $gry-f2;
        border-radius: 0 20px 0 0;
      }
      .img {
        border-radius: 0 10px 10px 0;
      }
      .deco02 {
        position: absolute;
        top: -32px;
        right: 60px;
        width: 200px;
        transform: translateX(100%);
      }
    }
    .img02 {
      position: relative;
      width: 70%;
      max-width: 400px;
      margin-left: auto;
      margin-top: -10vw;
      z-index: -2;
      .img {
        width: 100%;
        max-width: inherit;
        border-radius: 10px 0 0 10px;
      }
      .deco03 {
        position: absolute;
        top: spvw(70);
        left: 24px;
        width: 120px;
        transform: translateX(-100%);
      }
    }
    .banner {
      width: 96%;
      max-width: 400px;
      margin: -16vw auto 0;
      img {
        width: 100%;
      }
    }
  }
}

/* home_concept
********************************************** */
.home_concept {
  padding-top: 42px;
  @include mq(sp) {
    padding-top: 48px;
  }
  .concept_top {
    margin-left: vw(246);
    @include mq(1500) {
      margin-left: vw(156);
    }
    @include mq(sp) {
      align-items: center;
      width: 90%;
      margin: 0 auto 40px;
    }
    .left  {
      padding-top: 56px;
      @include mq(sp) {
        padding-top: 0;
      }
      img {
        position: absolute;
        bottom: vw(40);
        left: vw(-100);
        width: vw(130);
        @include mq(sp) {
          bottom: 10px;
          left: -70px;
          width: 66px;
        }
      }
      .en {
        line-height: 1;
        @include mq(sp) {
          line-height: 2;
        }
      }
      .catch {
        margin-left: 35px;
        font-size: rem(40);
        @include lh(40,76);
        @include mq(1500) {
          margin-left: 24px;
        }
        @include mq(sp) {
          margin-left: 8px;
          font-size: sprem(32);
        }
      }
    }
    .right {
      position: relative;
      width: 100%;
      max-width: 1008px;
      background-image: url(/wp/wp-content/themes/standard_sass/images/top/concept_bg_map.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
      &::before {
        content: "";
        display: block;
        padding-top: 67.46%;
        @include mq(sp) {
          content: inherit;
        }
      }
      .right--wrapper {
        margin-top: 70px;
        @include mq(sp) {
          margin-top: 36px;
        }
        .home_ttl {
          .en {
            margin-bottom: 36px;
            @include mq(sp) {
              margin-bottom: 18px;
            }
          }
          .jp {
            margin-bottom: 16px;
            @include mq(sp) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .concept_btm {
    margin-top: vw(-160);
    @include mq(sp) {
      margin-top: 0;
    }
    .ttlarea {
      position: relative;
      @include mq(sp) {
        width: 90%;
        max-width: 400px;
        margin: 0 auto;
      }
      .deco {
        position: absolute;
        right: calc(50% - 270px);
        transform: translateX(100%);
        @include mq(sp) {
          position: inherit;
          justify-content: space-between;
          width: 100%;
          right: inherit;
          transform: inherit;
        }
        .bubble {
          position: relative;
          width: 160px;
          height: 152px;
          margin-right: 16px;
          background-image: url(/wp/wp-content/themes/standard_sass/images/top/concept_bg_balloon.svg);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          @include mq(1500) {
            width: 130px;
            height: 126px;
          }
          @include mq(sp) {
            width: 100px;
            height: 92px;
            margin-right: 0;
            background-image: url(/wp/wp-content/themes/standard_sass/images/top/concept_bg_balloon_re.svg);
          }
          .txt {
            font-size: rem(20);
            text-align: center;
            @include lh(20,36);
            letter-spacing: 0;
            @include mq(1500) {
              font-size: rem(16);
            }
            @include mq(sp) {
              font-size: sprem(12);
            }
            .point {
              font-size: rem(32);
              @include lh(32,36);
              @include mq(1500) {
                font-size: rem(26);
              }
              @include mq(sp) {
                font-size: sprem(20);
              }
            }
          }
        }
        .chara {
          margin-top: -60px;
          @include mq(1500) {
            width: 140px;
            margin-top: 0;
            object-fit: contain;
          }
          @include mq(sp) {
            width: 96px;
          }
        }
      }
      .home_ttl {
        margin-bottom: 32px;
        text-align: center;
        .en {
          margin-top: -30px;
          font-size: rem(20);
        }
      }
    }
    .slidearea {
      .concept_slider {
        .slider_itme {
          &:nth-child(odd) {
            .item--wrapper {
              @include mq(sp) {
                padding-bottom: 24px;
              }
            }
          }
          &:nth-child(even) {
            .item--wrapper {
              padding-top: 48px;
              @include mq(sp) {
                padding-top: 24px;
              }
            }
          }
          .item--wrapper {
            padding: 0 vw(16);
            @include mq(sp) {
              padding: 0 spvw(12);
            }
            .img {
              position: relative;
              margin-bottom: 14px;
            }
            .txtarea {
              .term {
                font-size: rem(20);
                line-height: 2;
                letter-spacing: .05em;
              }
              .ttl {
                font-size: rem(20);
                line-height: 2;
                letter-spacing: .05em;
              }
            }
          }
        }
      }
    }
  }
}

/* home_point
********************************************** */
.home_point {
  padding-top: 82px;
  @include mq(1750) {
    padding-bottom: 100px;
  }
  @include mq(sp) {
    padding-top: 40px;
  }
  .point_inner {
    display: grid;
    grid-template-columns: calc((100vw - 630px) / 2) 1fr calc((100vw - 630px) / 2);
    justify-content: space-between;
    width: 100%;
    @include mq(1500) {
      align-items: center;
    }
    @include mq(sp) {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 30px;
      grid-template-areas:
        "img1 img1"
        "txtarea txtarea"
        "img2 img2";
    }
    .imgarea01 {
      @include mq(sp) {
        grid-area: img1;
        padding-left: 10px;
      }
      .deco01 {
        position: absolute;
        top: 0;
        right: vw(-186);
        width: vw(276);
        @include mq(1500) {
          right: vw(-106);
        }
        @include mq(sp) {
          display: none;
        }
      }
      .img01 {
        width: vw(480);
        margin-left: vw(48);
        margin-bottom: vw(56);
        border-radius: 20px;
        @include mq(sp) {
          display: none;
        }
      }
      .img02 {
        width: vw(544);
        margin-bottom: vw(60);
        @include mq(sp) {
          display: none;
        }
      }
      .img03 {
        border-radius: 0 20px 20px 0;
        width: vw(600);
        @include mq(sp) {
          display: none;
        }
      }
      .img_sp {
        width: 100%;
      }
    }
    .txtarea {
      @include mq(sp) {
        grid-area: txtarea;
      }
      position: relative;
      width: 630px;
      padding-top: vw(100);
      padding-bottom: vw(60);
      z-index: 1;
      @include mq(1500) {
        padding-top: inherit;
      }
      @include mq(sp) {
        width: 90%;
        margin: 0 auto;
      }
      .home_ttl {
        white-space: nowrap;
        @include mq(sp) {
          white-space: inherit;
        }
        .bubble {
          margin-bottom: vw(20);
          font-size: rem(24);
          @include lh(24,32);
          letter-spacing: .05em;
          @include mq(sp) {
            margin-bottom: 10px;
            font-size: sprem(16);
          }
        }
        .jp-48 {
          margin-bottom: vw(36);
          @include mq(sp) {
            margin-bottom: 18px;
            font-size: sprem(28);
          }
        }
      }
      .txt {
        @include mq(sp) {
          font-size: sprem(13);
        }
      }
      .txt01 {
        margin-bottom: vw(42);
        @include mq(sp) {
          margin-bottom: 20px;
        }
      }
      h3.home_ttl {
        margin-bottom: vw(36);
        @include mq(sp) {
          margin-bottom: 18px;
        }
      }
      .txt02,
      .txt03 {
        margin-bottom: vw(36);
        @include mq(sp) {
          margin-bottom: 18px;
        }
      }
      .txt04 {
        margin-bottom: vw(60);
        @include mq(sp) {
          margin-bottom: 30px;
        }
      }
      .btn {
        a {
          width: 100%;
          max-width: 368px;
          padding: 30px 36px;
          margin: 0 auto;
          @include mq(sp) {
            padding: 24px 36px;
          }
        }
      }
    }
    .imgarea02 {
      position: relative;
      @include mq(sp) {
        grid-area: img2;
        padding-right: 10px;
      }
      .bg_gray {
        position: absolute;
        top: 0;
        right: 0;
        width: 53.48%;
        border-radius: 40px 0 0 40px;
        background-color: $gry-d7;
        z-index: -1;
        @include mq(sp) {
          display: none;
        }
        &::before {
          content: "";
          display: block;
          padding-top: 215.9%;
        }
      }
      .img01 {
        position: relative;
        display: block;
        margin-top: vw(150);
        margin-left: auto;
        @include mq(sp) {
          display: none;
        }
        .img {
          display: block;
          width: vw(608);
          border-radius: 20px 0 0 20px;
        }
        .deco01 {
          position: absolute;
          bottom: vw(-190);
          right: vw(136);
          width: vw(298);
        }
      }
      .img02 {
        position: absolute;
        top: 0;
        left: vw(-72);
        @include mq(1400) {
          left: vw(-30);
        }
        @include mq(1400) {
          left: spvw(-6);
        }
        @include mq(sp) {
          display: none;
        }
        &::before {
          content: "";
          position: absolute;
          right: -8px;
          bottom: -8px;
          width: 90%;
          height: 90%;
          background-color: $gry-f2;
          border-radius: 0 0 8px 0;
        }
        .img {
          position: relative;
          width: vw(248);
          border-radius: 8px;
          z-index: 1;
        }
        .deco02 {
          position: absolute;
          top: 0;
          left: vw(-90);
          width: vw(98);
          @include mq(sp) {
            display: none;
          }
        }
      }
      .img03 {
        position: relative;
        margin-top: vw(464);
        margin-left: vw(-5);
        @include mq(sp) {
          display: none;
        }
        .deco03 {
          position: absolute;
          top: vw(-240);
          left: vw(192);
          width: vw(300);
        }
        .img {
          display: block;
          width: vw(592);
          border-radius: 20px;
        }
      }
      .img_sp {
        width: 100%;
      }
    }
  }
}

/* home_review
********************************************** */
.home_review {
  padding-top: 40px;
  padding-bottom: vw(160);
  @include mq(sp) {
    padding-bottom: 80px;
  }
  .review_box {
    position: relative;
    width: 90%;
    max-width: 1312px;
    padding: 100px 40px max(vw(100),60px);
    margin: 0 auto;
    background-color: #c7e0c480;
    border-radius: 64px;
    @include mq(1750) {
      margin: 0 auto;
    }
    @include mq(sp) {
      padding: 50px 20px 30px;
      border-radius: 20px;
    }
    .bg_txt {
      position: absolute;
      bottom: -.075em;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: vw(240);
      line-height: 1;
      letter-spacing: .01em;
      white-space: nowrap;
      z-index: -1;
      @include mq(sp) {
        display: none;
      }
    }
    .deco01 {
      position: absolute;
      top: vw(-106);
      left: vw(96);
      width: vw(340);
      @include mq(sp) {
        top: -88px;
        left: 0;
        width: 176px;
      }
    }
    .deco02 {
      position: absolute;
      left: vw(-114);
      bottom: vw(-146);
      width: vw(400);
      @include mq(sp) {
        display: none;
        // left: -22px;
        // bottom: -54px;
        // width: 138px;
      }
    }
    .deco03 {
      position: absolute;
      right: vw(-92);
      bottom: vw(18);
      width: vw(276);
      @include mq(sp) {
        right: -16px;
        bottom: -56px;
        width: 136px;
      }
    }
    h2.home_ttl {
      margin-bottom: 62px;
      .en {
        position: absolute;
        top: -.5em;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
      .jp {
        @include lh(32,48);
        @include mq(sp) {
          font-size: sprem(18);
        }
      }
    }
    .content_box {
      position: relative;
      max-width: 920px;
      padding: 24px;
      margin: 0 auto 36px;
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0px 5px 5px 0 rgba(153, 136, 119, .2);
      @include mq(sp) {
        padding: 26px 12px;
        margin-bottom: 24px;
        border-radius: 8px;
      }
      .chara {
        position: absolute;
        top: vw(-186);
        right: vw(-228);
        width: vw(322);
        @include mq(sp) {
          top: -56px;
          left: -30px;
          right: inherit;
          width: 170px;
        }
      }
      .box--wrapper {
        gap: 108px;
        @include mq(sp) {
          gap: 0;
        }
        .rgt {
          position: relative;
          height: 110px;
          padding-top: 14px;
          @include mq(sp) {
            height: auto;
            padding-top: 0;
            margin-bottom: 16px;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: -54px;
            width: 1px;
            height: 100%;
            background-color: $grn-3f;
            @include mq(sp) {
              content: inherit;
            }
          }
          .home_ttl {
            text-align: right;
            .jp-16 {
              line-height: 1;
              .en {
                display: inline-block;
              }
            }
          }
        }
        .lft {
          .num {
            margin-right: 36px;
            color: $grn-3f;
            font-size: rem(80);
            font-weight: 500;
            line-height: 1;
            letter-spacing: normal;
            @include mq(sp) {
              margin-right: 0;
              margin-bottom: 10px;
              font-size: sprem(60);
            }
          }
          .stararea {
            gap: 4px;
            .star {
              width: 64px;
              @include mq(sp) {
                width: min(spvw(50), 64px);
              }
            }
          }
        }
      }
    }
    .btmarea {
      .txt {
        @include lh(16,32);
        &.txt01 {
          margin-bottom: 30px;
          @include mq(sp) {
            margin-bottom: 18px;
          }
        }
      }
      .btn {
        a {
          width: 100%;
          max-width: 400px;
          padding: 34px 36px;
          margin: 0 auto 10px;
          color: #fff;
          background-color: $pin-d7;
          border: 1px solid $pin-d7;
          @include mq(sp) {
            padding: 28px 36px;
          }
          svg {
            position: absolute;
            top: calc(50% - 6px);
            right: 16px;
            display: block;
            width: 14px;
            fill: #fff;
          }
          &:hover {
            color: $pin-d7;
            background-color: #fff;
            svg {
              fill: $pin-d7;
            }
          }
        }
      }
    }
  }
}

/* home_form
********************************************** */
.home_form {
  padding: 100px 0;
}

/* home_about
********************************************** */
.home_about {
  @include mq(sp) {
    padding-top: 40px;
  }
  .about_inner {
    position: relative;
    padding-bottom: vw(196);
    @include mq(sp) {
      padding-top: 80px;
    }
    &::before {
      content: "";
      position: absolute;
      top: vw(224);
      left: 0;
      width: 100%;
      height: calc(100% - vw(224));
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_lightgreen.jpg);
      z-index: -10;
    }
    .bg_rainbow {
      position: absolute;
      top: vw(120);
      left: 0;
      width: 100%;
    }
    .house01 {
      position: absolute;
      top: vw(-132);
      right: vw(48);
      width: vw(480);
      @include mq(sp) {
        top: -56px;
        left: 24px;
        right: inherit;
        width: 156px;
      }
    }
    .ttlarea {
      position: relative;
      gap: vw(76);
      width: 98%;
      min-height: 600px;
      max-width: 1720px;
      margin-bottom: vw(208);
      @include mq(sp) {
        align-items: inherit;
        gap: 20px;
        width: 100%;
      }
      .imgarea {
        width: 58.32%;
        @include mq(sp) {
          width: 95%;
        }
        img {
          border-radius: 0 40px 40px 0;
        }
      }
      .txtarea {
        position: relative;
        flex: 1;
        @include mq(sp) {
          width: 90%;
          margin: 0 auto;
        }
        .catch {
          position: absolute;
          left: -24px;
          bottom: 0;
          z-index: -1;
          color: #fff;
          font-size: vw(192);
          line-height: .8;
          opacity: .6;
          white-space: nowrap;
          letter-spacing: .05em;
          @include mq(sp) {
            left: 0;
            font-size: min(spvw(88),sprem(100));
          }
        }
        .home_ttl {
          .jp-64 {
            position: relative;
            margin-bottom: vw(76);
            white-space: nowrap;
            @include mq(1600) {
              font-size: clamp(48px,4vw,64px);
            }
            @include mq(sp) {
              margin-bottom: 30px;
              font-size: min(spvw(34),sprem(40));
            }
            &::before {
              content: "";
              position: absolute;
              left: 50%;
              bottom: -.2em;
              transform: translateX(-50%);
              z-index: -1;
              width: calc(100% + 30px);
              height: 30px;
              background-image: url(/wp/wp-content/themes/standard_sass/images/top/promise_line.svg);
              @include mq(sp) {
                width: calc(100% + 16px);
                height: 20px;
                background-size: auto 100%;
              }
            }
          }
          .jp-36 {
            margin-bottom: vw(24);
            @include mq(sp) {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
    .content_list {
      position: relative;
      .bg_grn {
        position: absolute;
        background-color: #eee;
        z-index: -1;
        background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_water_green.png);
        opacity: 1;
        &.bg_grn01 {
          top: vw(-112);
          right: 0;
          width: vw(1200);
          height: 884px;
          border-radius: 60px 0 0 60px;
          @include mq(sp) {
            width: 80vw;
            border-radius: 30px 0 0 30px;
          }
        }
        &.bg_grn02 {
          bottom: vw(-105);
          left: 0;
          width: vw(848);
          height: 1420px;
          border-radius: 0 60px 60px 0;
          @include mq(sp) {
            width: 80vw;
            bottom: spvw(200);
            border-radius: 0 30px 30px 0;
          }
        }
        &.bg_grn03 {
          bottom: calc(vw(-192) - 82px);
          right: 0;
          width: vw(480);
          height: 528px;
          border-radius: 60px 0 0 60px;
          @include mq(sp) {
            display: none;
          }
        }
      }
      .content_item {
        position: relative;
        & > .flex  {
          width: 90%;
          max-width: 1280px;
          margin: 0 auto;
          @include mq(sp) {
            align-items: inherit;
          }
          .imgarea {
            position: relative;
            width: 50%;
            @include mq(sp) {
              width: 100%;
              margin-bottom: 16px;
            }
            .img01 {
              border-radius: 30px;
              @include mq(sp) {
                border-radius: 16px;
              }
            }
          }
          .txtarea {
            position: relative;
            width: 43.75%;
            @include mq(sp) {
              width: 100%;
            }
            .catch {
              position: absolute;
              top: -6px;
              left: -88px;
              z-index: -1;
              color: rgba(255, 255, 255, .25);
              font-size: rem(100);
              @include lh(100, 90);
              white-space: nowrap;
              letter-spacing: .05em;
              @include mq(1500) {
                top: 40px;
                left: -40px;
                font-size: rem(80);
              }
              @include mq(sp) {
                top: 0;
                left: -3%;
                font-size: min(spvw(54), sprem(80));
              }
            }
            .home_ttl {
              margin-bottom: 22px;
              @include mq(sp) {
                margin-bottom: 16px;
              }
              .jp-20 {
                margin-bottom: .8em;
                font-size: rem(20);
                color: $grn-3f;
                letter-spacing: .05em;
                line-height: 1;
                @include mq(sp) {
                  font-size: sprem(16);
                }
                .num {
                  margin-left: 2px;
                  font-size: rem(40);
                  font-weight: 500;
                  letter-spacing: .05em;
                  line-height: 1;
                  @include mq(sp) {
                    font-size: sprem(28);
                  }
                }
              }
              .jp-36 {
                @include lh(36, 60);
                white-space: nowrap;
                @include mq(sp) {
                  white-space: inherit;
                }
              }
              .jp-24 {
                white-space: nowrap;
                @include mq(sp) {
                  font-size: sprem(17);
                  white-space: inherit;
                }
              }
            }
            .txt {
              &:not(:last-of-type) {
                margin-bottom: 1em;
              }
            }
          }
        }
        &:not(.last-of-type) {
          margin-bottom: 82px;
          @include mq(sp) {
            margin-bottom: 40px;
          }
        }
        &.content_item01 {
          @include mq(1700) {
            margin-bottom: 110px;
          }
          @include mq(sp) {
            margin-bottom: 40px;
          }
          .imgarea {
            .img--wrapper {
              position: relative;
              @include mq(sp) {
                width: 76%;
              }
              &::before {
                content: "";
                position: absolute;
                bottom: -8px;
                right: -8px;
                width: 50%;
                height: 50%;
                background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_water_green-hide.png);
                border-radius: 30px;
                @include mq(sp) {
                  bottom: -4px;
                  right: -4px;
                  border-radius: 16px;
                }
              }
              .img01 {
                position: relative;
                border-radius: 30px;
                @include mq(sp) {
                  border-radius: 16px;
                }
              }
              .img02 {
                position: absolute;
                right: calc(vw(-360) + 40px);
                bottom: calc(vw(-240) + 80px);
                width: vw(360);
                border-radius: 10px;
                z-index: -1;
                @include mq(1500) {
                  right: calc(vw(-360) + vw(240));
                  bottom: calc(vw(-240) + 20px);
                }
                @include mq(sp) {
                  right: -40%;
                  bottom: -20%;
                  width: 50%;
                }
              }
            }
          }
        }
        &.content_item02 {
          .deco01 {
            position: absolute;
            top: vw(-112);
            left: vw(48);
            width: vw(340);
            @include mq(1700) {
              width: vw(226);
              z-index: 1;
            }
            @include mq(sp) {
              top: inherit;
              left: inherit;
              right: -4%;
              bottom: -90px;
              width: 110px;
            }
          }
          & > .flex  {
            .txtarea {
              .catch {
                left: -77px;
                color: rgba(153, 178, 146, .25);
                @include mq(1500) {
                  left: -40px;
                }
                @include mq(sp) {
                  left: -3%;
                }
              }
            }
          }
        }
        &.content_item03 {
          .deco01 {
            position: absolute;
            right: vw(48);
            bottom: vw(-36);
            width: vw(294);
            z-index: 1;
            @include mq(sp) {
              right: -4%;
              bottom: -90px;
              width: 110px;
            }
          }
          & > .flex  {
            .txtarea {
              .catch {
                left: -80px;
                @include mq(1500) {
                  left: -40px;
                }
                @include mq(sp) {
                  left: -3%;
                }
              }
              .home_ttl {
                .jp-36 {
                  margin-bottom: 22px;
                  @include mq(sp) {
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }
        }
        &.content_item04 {
          @include mq(1700) {
            margin-bottom: 160px;
          }
          @include mq(sp) {
            margin-bottom: 40px;
          }
          .deco01 {
            position: absolute;
            right: vw(48);
            bottom: vw(-86);
            width: vw(382);
            z-index: 1;
            @include mq(1700) {
              bottom: vw(-116);
            }
            @include mq(1500) {
              right: vw(20);
              bottom: vw(-80);
              width: vw(254);
            }
            @include mq(sp) {
              right: -4%;
              bottom: -62px;
              width: 150px;
            }
          }
          .imgarea {
            @include mq(sp) {
              margin-bottom: 40px;
            }
            .img--wrapper {
              position: relative;
              @include mq(sp) {
                width: 76%;
                margin-left: auto;
              }
              &::before {
                content: "";
                position: absolute;
                bottom: -8px;
                left: -8px;
                width: 80%;
                height: 80%;
                background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_water_green-hide.png);
                border-radius: 30px;
                @include mq(sp) {
                  bottom: -4px;
                  left: -4px;
                  width: 20%;
                  border-radius: 16px;
                }
              }
              .img01 {
                position: relative;
                @include mq(sp) {
                  border-radius: 16px;
                }
              }
              .img02 {
                position: absolute;
                left: calc(vw(-400) + 80px);
                bottom: calc(vw(-320) + vw(240));
                width: vw(400);
                border-radius: 10px;
                z-index: -1;
                @include mq(1700) {
                  left: calc(vw(-400) + 140px);
                  bottom: calc(vw(-320) + 8vw);
                }
                @include mq(1500) {
                  bottom: calc(vw(-320) + 70px);
                }
                @include mq(sp) {
                  left: -40%;
                  bottom: -10%;
                  width: 50%;
                }
              }
            }
          }
          & > .flex  {
            .txtarea {
              .catch {
                left: -80px;
                @include mq(1500) {
                  left: -40px;
                }
                @include mq(sp) {
                  left: -3%;
                }
              }
            }
          }
        }
        &.content_item05 {
          & > .flex  {
            .txtarea {
              .catch {
                color: rgba(153, 178, 146, .25);
                @include mq(sp) {
                  left: -3%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* home_menu
********************************************** */
.home_menu {
  position: relative;
  padding-top: vw(160);
  padding-bottom: 146px;
  @include mq(sp) {
    padding-top: 80px;
    padding-bottom: 120px;
  }
  .bg_gray {
    position: absolute;
    background-color: $gry-d7;
    opacity: .5;
    @include mq(sp) {
      display: none;
    }
    &.bg_gray01 {
      top: 56px;
      right: 0;
      width: vw(520);
      height: vw(584);
      border-radius: 40px 0 0 40px;
    }
    &.bg_gray02 {
      bottom: 0;
      left: 0;
      width: vw(480);
      height: vw(720);
      border-radius: 0 40px 40px 0;
    }
  }
  .bg-arc {
    position: absolute;
    top: vw(-88);
    left: 0;
    width: 100%;
    height: vw(88);
  }
  .house01 {
    position: absolute;
    top: vw(-180);
    right: vw(192);
    width: vw(242);
    @include mq(sp) {
      position: absolute;
      top: -72px;
      right: 0;
      width: 121px;
    }
  }
  .content_box {
    position: relative;
    max-width: 1440px;
    padding: 160px 112px 128px;
    background-color: #fff;
    border-radius: 48px;
    @include mq(1400) {
      padding: 160px 5.8vw 5.8vw;
    }
    @include mq(sp) {
      padding: 90px 20px 36px;
      border-radius: 24px;
    }
    .deco01 {
      position: absolute;
      top: vw(-118);
      left: vw(-56);
      width: vw(320);
      @include mq(sp) {
        display: none;
      }
    }
    .deco02 {
      position: absolute;
      bottom: vw(-82);
      right: vw(-192);
      width: vw(284);
      @include mq(1800) {
        right: vw(-60);
      }
      @include mq(sp) {
        left: -4%;
        right: inherit;
        bottom: -60px;
        width: 114px;
      }
    }
    .home_ttl.home_ttl01 {
      position: absolute;
      top: -44px;
      left: 50%;
      transform: translateX(-50%);
      @include mq(sp) {
        top: -24px;
      }
      .en {
        line-height: .8;
        margin-bottom: 20px;
        white-space: nowrap;
        @include mq(sp) {
          margin-bottom: 10px;
        }
      }
    }
    .content_list {
      @include mq(sp) {
        row-gap: 32px;
      }
      .content_item {
        position: relative;
        width: 31.57%;
        padding-bottom: 32px;
        @include mq(sp) {
          width: 100%;
        }
        .post--link {
          &::before {
            z-index: 1;
          }
        }
        .imgarea {
          position: relative;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 16px;
          transition: all .2s ease-out;
          @include mq(sp) {
            background-position: top;
          }
          &::before {
            content: "";
            display: block;
            padding-top: 154.1%;
            @include mq(sp) {
              padding-top: 130%;
            }
          }
        }
        .txtarea {
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          width: 100%;
          .home_ttl {
            text-align: center;
            margin-bottom: 36px;
            @include mq(sp) {
              margin-bottom: 18px;
            }
            .jp-32 {
              @include lh(32,40);
              white-space: nowrap;
            }
          }
          .btn {
            a {
              width: 75%;
              max-width: 288px;
              min-width: 240px;
              margin: 0 auto;
              @include mq(sp) {
                min-width: inherit;
              }
              &:hover {
                background-color: $yell-f5;
                color: $bro-63;
                &::before {
                  background-color: $bro-63;
                }
              }
            }
          }
        }
        &.wall {
          .imgarea {
            background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_01.jpg);
            @include mq(sp) {
              background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_01_sp.jpg);
            }
          }
        }
        &.roof {
          .imgarea {
            background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_02.jpg);
            @include mq(sp) {
              background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_02_sp.jpg);
            }
          }
        }
        &.other {
          .imgarea {
            background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_03.jpg);
            @include mq(sp) {
              background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_03_sp.jpg);
            }
          }
          .home_ttl {
            .jp-32 {
              margin-bottom: 16px;
            }
          }
        }
        &:hover {
          .imgarea {
            border-radius: 60px;
          }
        }
      }
    }
  }
}

/* home_works
********************************************** */
.home_works {
  position: relative;
  padding-top: 8px;
  padding-bottom: 76px;
  @include mq(sp) {
    padding-bottom: 40px;
  }
  .deco01 {
    position: absolute;
    top: vw(-60);
    left: calc(50% - vw(528));
    transform: translateX(-100%);
    width: vw(262);
    @include mq(1500) {
      top: 60px;
    }
    @include mq(sp) {
      display: none;
    }
  }
  .deco02 {
    position: absolute;
    top: vw(-26);
    right: calc(50% - vw(528));
    transform: translateX(100%);
    width: vw(218);
    @include mq(1500) {
      top: 26px;
    }
    @include mq(sp) {
      top: -94px;
      right: 5%;
      transform: inherit;
      width: 100px;
    }
  }
  .home_ttl {
    margin-bottom: 78px;
    @include mq(sp) {
      margin-bottom: 40px;
    }
    .bubble {
      padding: 0 36px;
      margin-bottom: 1.083em;
      font-size: rem(24);
      @include mq(sp) {
        padding: 0;
        margin-bottom: .54em;
        font-size: sprem(18);
      }
      &::before {
        top: 70%;
      }
      &::after {
        top: 70%;
      }
      span {
        font-size: rem(40);
        @include mq(sp) {
          font-size: sprem(28);
        }
      }
    }
    .jp-40 {
      position: relative;
      .catch {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: rem(160);
        line-height: 1;
        letter-spacing: .1em;
        color: #fff;
        z-index: -1;
        white-space: nowrap;
        @include mq(sp) {
          font-size: min(spvw(84),rem(160));
        }
      }
    }
  }
  .works_content {
    width: 96%;
    max-width: 1696px;
    @include mq(sp) {
      width: 90%;
    }
    .posts-works {
      .post {
        width: calc((100% - 3.772%) / 3);
        @include mq(sp) {
          width: 100%;
        }
        .post--txtarea {
          .post--info {
            flex-direction: inherit;
            @include mq(sp) {
              flex-direction: column;
            }
            .post--ttl {
              width: calc(100% - 124px);
              @include mq(sp) {
                width: 100%;
              }
            }
            .detail {
              margin-left: 0;
              @include mq(sp) {
                margin-left: auto;
              }
            }
          }
        }
      }
    }
    .btn {
      margin-top: 86px;
      @include mq(sp) {
        margin-top: 42px;
      }
      a {
        min-width: 496px;
        padding: 27px 36px;
        margin: 0 auto;
        font-size: rem(20);
        @include lh(20,40);
        @include mq(sp) {
          font-size: sprem(16);
          min-width: 300px;
          padding: 20px 36px;
        }
        &::before {
          right: 24px;
        }
      }
    }
  }
}

/* home_contact
********************************************** */
.home_contact {
  position: relative;
  padding-top: 80px;
  padding-bottom: 30px;
  @include mq(sp) {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  &.home_contact_btm {
    padding-bottom: 60px;
    @include mq(sp) {
      padding-bottom: 80px;
    }
    .bg-arc {
      position: absolute;
      top: vw(-88);
      left: 0;
      width: 100%;
      height: vw(88);
    }
  }
  .content_box {
    position: relative;
    max-width: 1440px;
    padding: 158px 192px 68px;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/cta_bg.jpg);
    border-radius: 60px;
    @include mq(1500) {
      padding: 158px 4vw 68px;
      border-radius: 30px;
    }
    @include mq(sp) {
      width: 96%;
      padding: 68px 20px 30px;
    }
    .deco01 {
      position: absolute;
      top: vw(-142);
      right: vw(-136);
      width: vw(328);
      @include mq(med) {
        right: vw(-70);
      }
      @include mq(sp) {
        top: -70px;
        right: max(-2%, 26px);
        width: 120px;
      }
    }
    .deco02 {
      position: absolute;
      left: vw(-150);
      bottom: vw(-32);
      width: vw(288);
      @include mq(sp) {
        left: -8px;
        bottom: -60px;
        width: 90px;
      }
    }
    .home_ttl {
      position: relative;
      margin-bottom: 16px;
      @include mq(sp) {
        margin-bottom: 36px;
      }
      .jp-40 {
        position: relative;
        line-height: 1;
      }
      .catch {
        position: absolute;
        bottom: -.0125em;
        left: 50%;
        transform: translateX(-50%);
        font-size: rem(160);
        line-height: .8;
        letter-spacing: .1em;
        color: #fff;
        opacity: .2;
        white-space: nowrap;
        @include mq(sp) {
          bottom: -2px;
          font-size: min(spvw(60),sprem(160));
        }
      }
    }
    .contact_top {
      margin-bottom: 36px;
      @include mq(sp) {
        align-items: inherit;
        margin-bottom: 20px;
      }
      .lft {
        width: 50%;
        @include mq(sp) {
          width: 100%;
          margin-bottom: 32px;
        }
        .txtarea {
          column-gap: 18px;
          margin-bottom: 10px;
          @include mq(sp) {
            align-items: center;
            margin-bottom: 0;
            column-gap: 10px;
          }
          .bubble {
            width: 168px;
            height: 124px;
            background-image: url(/wp/wp-content/themes/standard_sass/images/top/contact_bg_balloon01.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            @include mq(sp) {
              width: 110px;
              height: 82px;
            }
            .bubble--txt {
              display: block;
              font-size: rem(20);
              @include lh(20,30);
              letter-spacing: .05em;
              @include mq(sp) {
                font-size: sprem(15);
              }
            }
          }
          .txt {
            font-size: rem(20);
            @include lh(20,30);
            letter-spacing: .1em;
            padding-bottom: 12px;
            @include mq(sp) {
              flex: 1;
              padding-bottom: 0;
              font-size: sprem(15);
            }
          }
        }
        .tel {
          margin-bottom: 10px;
          font-size: rem(40);
          line-height: 1;
          letter-spacing: .03em;
          white-space: nowrap;
          @include mq(sp) {
            font-size: sprem(24);
          }
          img {
            width: 60px;
            margin-right: 8px;
            @include mq(sp) {
              width: 40px;
            }
          }
          .num {
            font-size: rem(64);
            @include mq(sp) {
              font-size: sprem(40);
              white-space: nowrap;
            }
          }
        }
        .open {
          width: 90%;
          border-radius: 8px;
          margin: 0 auto 20px;
          @include mq(sp) {
            width: 100%;
            border-radius: 4px;
            font-size: sprem(12);
          }
        }
        .btn {
          a {
            width: 100%;
            max-width: inherit;
            padding: 44px 36px;
            font-size: rem(24);
            @include mq(sp) {
              padding: 14px 36px;
              font-size: sprem(18);
            }
            &::before {
              content: '';
              position: absolute;
              top: calc(50% - 4px);
              right: 24px;
              display: block;
              @include rect(8,12);
              clip-path: polygon(100% 50%, 0 0, 0 100%);
              background-color: $bro-63;
              transition: var(--transit-default, all .4s ease-out);
            }
          }
          .txt {
            font-size: rem(20);
            @include lh(20,44);
            letter-spacing: .05em;
            @include mq(sp) {
              font-size: sprem(12);
            }
          }
        }
      }
      .rgt {
        width: 45.45%;
        padding-bottom: 12px;
        @include mq(sp) {
          width: 100%;
          padding-bottom: 0;
        }
        .rgt_top {
          position: relative;
          margin-bottom: 14px;
          z-index: 1;
          @include mq(sp) {
            max-width: 300px;
            margin: 0 auto 30px;
          }
          .chara01 {
            position: absolute;
            top: -4px;
            right: -20px;
            @include mq(sp) {
              position: absolute;
              top: inherit;
              bottom: -48px;
              right: 0;
              width: 84px;
            }
          }
          .txtarea {
            position: relative;
            width: 62.08%;
            @include mq(sp) {
              width: 65%;
            }
            &::before {
              content: "";
              position: absolute;
              top: 16px;
              left: calc(50% + 2px);
              transform: translateX(-50%);
              width: 114%;
              max-width: 340px;
              aspect-ratio: 340/184;
              background-image: url(/wp/wp-content/themes/standard_sass/images/top/contact_bg_balloon02.svg);
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              @include mq(sp) {
                top: 10px;
              }
            }
            .txt_img {
              position: relative;
              width: 298px;
              margin: 0 auto;
              z-index: 1;
              @include mq(sp) {
                width: 100%;
              }
            }
            .info--txt {
              position: relative;
              margin-top: -4px;
              @include lh(16,22);
              letter-spacing: .05em;
              z-index: 1;
              @include mq(sp) {
                margin-top: 0;
                font-size: sprem(12);
              }
              .point {
                margin-top: 4px;
                color: $yell-f5;
                font-size: rem(64);
                font-weight: 500;
                line-height: 1;
                letter-spacing: .05em;
                @include mq(sp) {
                  font-size: sprem(40);
                }
                .small {
                  font-size: rem(56);
                  color: $yell-f5;
                  @include mq(sp) {
                    font-size: sprem(36);
                  }
                }
              }
            }
          }
        }
        .rgt_btm {
          padding: 44px 40px 34px;
          background-color: rgba(255, 255, 255, .2);
          border-radius: 8px;
          @include mq(sp) {
            padding: 20px;
          }
          .txt {
            margin-bottom: 20px;
            font-size: rem(24);
            @include lh(24,32);
            letter-spacing: .05em;
            @include mq(sp) {
              margin-bottom: 10px;
              font-size: sprem(16);
            }
          }
          .line_btn {
            a {
              display: flex;
              width: 100%;
              max-width: inherit;
              padding: 40px 36px;
              background-color: #fff;
              border: inherit;
              @include mq(sp) {
                padding: 20px 24px;
              }
              &::before {
                content: inherit;
              }
              svg {
                fill: $grn-3f;
                @include mq(sp) {
                  width: 36px;
                }
              }
              span {
                margin-left: 12px;
                font-size: rem(24);
                color: $grn-3f;
                letter-spacing: .05em;
                transition: var(--transit-default, all .4s ease-out);
                @include mq(sp) {
                  font-size: sprem(18);
                }
              }
              &:hover {
                background-color: $grn-3f;
                span {
                  color: #fff;
                }
                svg {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
    }
    .contact_btm {
      .area {
        margin-bottom: 20px;
        font-size: rem(20);
        @include lh(20,48);
        letter-spacing: .05em;
        @include mq(sp) {
          font-size: sprem(14);
          line-height: 1.8;
        }
        .ttl {
          display: inline-block;
          padding: 0 16px;
          margin-right: 18px;
          font-size: rem(16);
          @include lh(16,48);
          border: 1px solid #fff;
          border-radius: 8px;
          @include mq(sp) {
            display: block;
            margin-right: 0;
            margin-bottom: 16px;
            line-height: 2.2;
          }
        }
      }
      .txt {
        @include lh(16,22);
        letter-spacing: .05em;
      }
    }
  }
}

/* home_staff
********************************************** */
.home_staff {
  padding-bottom: 76px;
  @include mq(sp) {
    padding-bottom: 40px;
  }
  .imgarea {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      max-width: inherit;
      @include mq(sp) {
        height: 320px;
        object-fit: cover;
      }
    }
    .img_top {
      position: absolute;
      top: 0;
      left: 0;
    }
    .catch {
      position: absolute;
      line-height: .7em;
      white-space: nowrap;
      &.catch01 {
        top: vw(105);
        right: vw(-16);
        color: rgba(153, 178, 146, 0.25);
        font-size: vw(184);
        @include mq(sp) {
          top: spvw(30);
          font-size: min(spvw(48),sprem(184));
        }
      }
      &.catch02 {
        bottom: vw(50);
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 0.5);
        font-size: vw(160);
        @include mq(sp) {
          bottom: spvw(30);
          font-size: min(spvw(42),sprem(160));
        }
      }
    }
  }
  .txtarea {
    position: relative;
    margin-top: vw(-48);
    margin-bottom: 96px;
    @include mq(sp) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 48px;
    }
    .deco01 {
      position: absolute;
      left: vw(166);
      bottom: 76px;
      width: vw(304);
      @include mq(1500) {
        left: vw(80);
      }
      @include mq(sp) {
        left: 0;
        width: 90px;
      }
    }
    .deco02 {
      position: absolute;
      right: vw(110);
      bottom: 76px;
      width: vw(333);
      @include mq(1500) {
        right: vw(30);
      }
      @include mq(sp) {
        right: 0;
        width: 90px;
      }
    }
    .txt_box {
      .home_ttl {
        margin-bottom: 32px;
        @include mq(sp) {
          margin-bottom: 16px;
        }
        .jp-36 {
          @include lh(36,60);
        }
      }
      .txt {
        margin-bottom: 4em;
        @include mq(sp) {
          margin-bottom: 112px;
        }
        .media-1700 {
          display: none;
          @include mq(1700) {
            display: block;
          }
        }
      }
      .btn {
        a {
          margin: 0 auto;
        }
      }
    }
  }
  .staff_slider {
    .img {
      width: vw(352);
      padding: 0 vw(16);
      @include mq(sp) {
        padding: 0 spvw(16);
      }
    }
  }
}

/* home_company
********************************************** */
.home_company {
  padding-top: 80px;
  padding-bottom: 48px;
  @include mq(sp) {
    padding-top: 40px;
  }
  .company_inner {
    display: grid;
    grid-template-columns: vw(510) vw(900) vw(510);
    width: 100%;
    @include mq(1850) {
      grid-template-columns: calc((100vw - 700px) / 2) 1fr calc((100vw - 700px) / 2);
    }
    @include mq(sp) {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 30px;
      grid-template-areas:
          "txtarea txtarea"
          "img1 img2";
    }
    .imgarea01 {
      position: relative;
      @include mq(sp) {
        grid-area: img1;
      }
      .deco01 {
        position: absolute;
        top: -40px;
        left: vw(130);
        width: vw(304);
        @include mq(1600) {
          left: 20px;
        }
        @include mq(sp) {
          top: spvw(20);
          left: spvw(20);
          width: spvw(90);
        }
      }
      .img01 {
        position: absolute;
        top: 242px;
        left: 0;
        width: vw(560);
        @include mq(sp) {
          top: 30vw;
          left: 0;
          width: 90%;
        }
        .img01--img {
          width: 100%;
          border-radius: 0 14px 14px 0;
          @include mq(sp) {
            border-radius: 0 8px 8px 0;
          }
        }
        .img02 {
          position: absolute;
          right: vw(-96);
          bottom: vw(-204);
          width: vw(400);
          @include mq(sp) {
            right: -20%;
            bottom: 10%;
            transform: translateY(100%);
            width: 70%;
          }
          &::before {
            content: "";
            position: absolute;
            top: -8px;
            left: -8px;
            width: 90%;
            height: 50%;
            background-color: $gry-f2;
            border-radius: 14px;
            @include mq(sp) {
              top: -4px;
              left: -4px;
              border-radius: 8px;
            }
          }
          .img02--img {
            position: relative;
            width: 100%;
            border-radius: 14px;
            z-index: 1;
            @include mq(sp) {
              border-radius: 8px;
            }
          }
        }
      }
    }
    .imgarea02 {
      position: relative;
      @include mq(sp) {
        grid-area: img2;
        z-index: -1;
      }
      .bg_gray {
        top: 272px;
        right: 0;
        width: vw(600);
        height: vw(640);
        border-radius: 40px 0 0 40px;
        @include mq(sp) {
          position: inherit;
          top: inherit;
          margin-top: 20vw;
          width: 100%;
          height: 60vw;
          border-radius: 20px 0 0 20px;
        }
      }
      .img01 {
        position: absolute;
        top: 0;
        left: vw(24);
        border-radius: 14px;
        @include mq(sp) {
          left: inherit;
          right: 0;
          display: block;
          width: 80%;
          border-radius: 8px 0 0 8px;
        }
      }
      .deco01 {
        position: absolute;
        top: 600px;
        left: vw(102);
        width: vw(312);
        @include mq(sp) {
          top: spvw(240);
          left: spvw(42);
          width: spvw(100);
        }
      }
    }
    .txtarea {
      padding-top: 80px;
      padding-bottom: 156px;
      z-index: 1;
      @include mq(1600) {
        padding-bottom: 0;
      }
      @include mq(sp) {
        grid-area: txtarea;
        width: 90%;
        padding: 0;
        margin: 0 auto;
      }
      .home_ttl {
        position: relative;
        padding-top: 72px;
        margin-bottom: 52px;
        @include mq(sp) {
          padding-top: 20px;
          margin-bottom: 24px;
        }
        .catch {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          font-size: rem(200);
          line-height: .7em;
          letter-spacing: .1em;
          color: #fff;
          white-space: nowrap;
          z-index: -1;
          @include mq(sp) {
            font-size: min(spvw(80), spvw(200));
          }
        }
        .jp-48 {
          @include mq(sp) {
            font-size: sprem(24);
          }
        }
      }
      .txt {
        margin-bottom: 88px;
        @include mq(sp) {
          margin-bottom: 40px;
        }
        .media-1850 {
          display: none;
          @include mq(1850) {
            display: block;
          }
          @include mq(sp) {
            display: none;
          }
        }
        p {
          white-space: nowrap;
          @include mq(1850) {
            text-shadow: 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF;
          }
          @include mq(sp) {
            white-space: inherit;
            text-shadow: inherit;
          }
          &:first-child {
            margin-bottom: 2.5em;
            @include mq(sp) {
              margin-bottom: 1.5em;
            }
          }
        }
      }
      .btn {
        a {
          margin: 0 auto;
        }
      }
    }
  }
}

/* home_info
********************************************** */
.home_info {
  padding-top: 96px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_lightgreen.jpg);
  @include mq(sp) {
    padding-top: 48px;
  }
  .home_ttl.inner {
    position: relative;
    width: 96%;
    max-width: 1696px;
    margin-bottom: 36px;
    z-index: 1;
    @include mq(sp) {
      width: 90%;
      margin-bottom: 10px;
    }
  }
  .info_top {
    width: 96%;
    max-width: 1696px;
    .box {
      position: relative;
      background-color: #fff;
      border-radius: 32px;
      .catch {
        position: absolute;
        top: -126px;
        right: -16px;
        font-size: rem(180);
        line-height: .7em;
        letter-spacing: .05em;
        color: #fff;
        opacity: .25;
        @include mq(1500) {
          top: -96px;
          font-size: rem(140);
        }
        @include mq(sp) {
          top: -68px;
          font-size: sprem(98);
        }
      }
      .home_ttl {
        padding-bottom: 12px;
        .bubble {
          &::before {
            left: -32px;
            width: 20px;
            height: 36px;
          }
          &::after {
            right: -32px;
            width: 20px;
            height: 36px;
          }
        }
      }
      .btn {
        a {
          width: 100%;
          max-width: 320px;
          margin: 0 auto;
        }
      }
      &.news_box {
        width: 41.03%;
        padding: 54px 64px 64px;
        @include mq(1500) {
          padding: 34px 44px 44px;
        }
        @include mq(sp) {
          width: 100%;
          padding: 30px 20px;
        }
        .deco01 {
          position: absolute;
          left: vw(-24);
          bottom: vw(-72);
          width: vw(172);
          @include mq(sp) {
            display: none;
          }
        }
        .home_ttl {
          border-bottom: 1px solid $grn-d9;
        }
        .news_list {
          margin-bottom: 40px;
          @include mq(sp) {
            margin-bottom: 32px;
          }
          .post {
            height: 136px;
            padding: 30px 20px 0;
            @include mq(sp) {
              height: 96px;
              padding: 20px 10px 0;
            }
            .post--date {
              margin-bottom: .75em;
              @include mq(sp) {
                margin-bottom: .5em;
              }
            }
          }
        }
      }
      &.blog_box {
        width: 56.13%;
        padding: 54px 64px 56px;
        @include mq(1500) {
          padding: 34px 44px 34px;
        }
        @include mq(sp) {
          margin-top: 100px;
          width: 100%;
          padding: 30px 20px;
        }
        .deco01 {
          position: absolute;
          top: vw(-82);
          left: vw(52);
          width: vw(164);
          @include mq(1400) {
            left: 12px;
          }
          @include mq(sp) {
            top: spvw(-78);
            left: 10px;
            width: spvw(78);
          }
        }
        .deco02 {
          position: absolute;
          right: vw(-14);
          bottom: vw(-68);
          width: vw(238);
          @include mq(sp) {
            right: 10px;
            bottom: spvw(-70);
            width: spvw(100);
          }
        }
        .posts-blog {
          padding-bottom: 30px;
          margin-bottom: 48px;
          border-bottom: 1px solid $grn-d9;
        }
      }
    }
  }
  .info_btm {
    position: relative;
    padding-top: 176px;
    padding-bottom: 288px;
    @include mq(sp) {
      padding-top: 90px;
      padding-bottom: 0;
    }
    .bg_grn {
      top: 112px;
      right: 0;
      width: vw(1152);
      height: vw(680);
      border-radius: 32px 0 0 32px;
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_water_green-hide.png);
      @include mq(sp) {
        display: none;
      }
    }
    .img01 {
      position: absolute;
      left: 0;
      bottom: 40px;
      border-radius: 0 32px 0 0;
      @include mq(sp) {
        position: inherit;
        left: inherit;
        bottom: inherit;
        width: 80%;
        border-radius: 0 24px 0 0;
      }
    }
    .insta_box {
      position: relative;
      max-width: 1280px;
      z-index: 1;
      .txtarea {
        width: 216px;
        @include mq(sp) {
          width: 100%;
        }
        .insta_img {
          margin-left: 4px;
          margin-bottom: 26px;
          @include mq(sp) {
            margin-bottom: 16px;
          }
        }
        .txt {
          padding: 0 4px;
          margin-bottom: 2.25em;
          @include lh(16,28);
          letter-spacing: .05em;
          @include mq(sp) {
            margin-bottom: 1em;
          }
        }
      }
      .insta_btn {
        width: 100%;
        @include mq(sp) {
          margin-bottom: 40px;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 21px 46px 21px 36px;
          background-color: #fff;
          border: inherit;
          .font-en {
            display: block;
            margin-left: 8px;
            font-size: rem(14);
          }
          &:hover {
            background-color: $yell-f5;
          }
        }
      }
      .imgarea {
        position: relative;
        width: 76.875%;
        height: 488px;
        @include mq(sp) {
          width: 100%;
          height: inherit;
          margin-bottom: 24px;
        }
        .deco01 {
          position: absolute;
          bottom: 0;
          right: vw(-210);
          width: vw(190);
          @include mq(1650) {
            bottom: -216px;
            right: vw(-60);
            width: 124px;
          }
          @include mq(sp) {
            bottom: -276px;
            width: 100px;
          }
        }
        //インスタプラグイン
        #sb_instagram .sbi_photo_wrap {
          overflow: hidden;
        }
      }
    }
  }
}

/* 動き
********************************************** */
.anime-dly1 {
  animation-delay: .5s;
}
.anime-dly2 {
  animation-delay: 1s;
}
.anime-dly3 {
  animation-delay: 1.5s;
}
.anime-dly4 {
  animation-delay: 2s;
}
.anime-dly5 {
  animation-delay: 2.5s;
}
.hero.wayshow,
.hero_sp_top.wayshow {
  .fadein {
    animation-name: FadeIn;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
  .fadein_top {
    animation-name: FadeInTop;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
  .fadein_btm {
    animation-name: FadeInBtm;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
  .fadein_rgt {
    animation-name: FadeInRgt;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
  .fadein_lft {
    animation-name: FadeInLft;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
}
.fadein {
  opacity: 0;
  &.wayshow {
    animation-name: FadeIn;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
}
.fadein_top {
  opacity: 0;
  &.wayshow {
    animation-name: FadeInTop;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
}
.fadein_btm {
  opacity: 0;
  &.wayshow {
    animation-name: FadeInBtm;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
}
.fadein_rgt {
  opacity: 0;
  &.wayshow {
    animation-name: FadeInRgt;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
}
.fadein_lft {
  opacity: 0;
  &.wayshow {
    animation-name: FadeInLft;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
}
.buruburu {
  &.wayshow {
    animation-name: Buruburu;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(.4,.4,0,1);
    animation-fill-mode: forwards;
  }
}

  /* cursor
**************************************** */
#cursor {
  position: fixed;
  z-index: 99999;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  pointer-events: none;
  display: none;
  color: #fff;
  font-family: var(--font-en);
  letter-spacing: .1em;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0;
  -webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
  background-color: #3fa03740;
  @include mq(sp) {
    display: none;
  }
}
#cursor.is-viewmore {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: cursor_more .2s linear forwards;
}