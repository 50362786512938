@use '../abstracts' as *;

/* --- section_pdg --- */
.section_pdg {
  padding-top: 80px;
  padding-bottom: 80px;
  @include mq(sp) {
    padding-top: 40px;
    padding-bottom: 40px;
    &#section-01 {
      padding-top: 0;
      @include mq(sp) {
        padding-top: 24px;
      }
    }
  }
}
.section_pdg-sm {
  padding-top: 40px;
  padding-bottom: 40px;
  @include mq(sp) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

/* --- contactページ　section_pdg --- */
.page-contact:not(.page-thanks) #section-01 {
  padding-bottom: 0;
}