@charset "UTF-8";
:root {
  --ltr-space-default: .1em;
  --line-height-default: 2.2;
  --line-height-hdr: 1.4;
  --line-height-none: 1;
  --font-primary: "Shuei KakuGo Gin M","こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-primary-bold: "Shuei KakuGo Gin B","こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-secondary: "ヒラギノ角ゴ オールド W6 JIS2004", "Hiragino Sans Old W6 JIS2004";
  --font-secondary--light: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
  --font-jp: "Shuei Nijimi Go Gin B","こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-en: "Outfit","Josefin Sans", sans-serif;
  --font-noto-sans: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro","メイリオ", "Meiryo", "游ゴシック", "Yu Gothic", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  --fz-primary: 1.125rem;
  --fz-half: .5rem;
  --clr-main: #3fa037;
  --clr-txt-sub: #171C60;
  --clr-body: #63502f;
  --clr-dark-blue: #101560;
  --clr-link: #63502f;
  --clr-wht: #fff;
  --clr-blk: #000;
  --bg-main: #f2f3f1;
  --bg-sub: #eee;
  --bg-wht: #fff;
  --bg-off_wht: #dcedfc;
  --bg-blk: #000;
  --btn-bg-main: #f5c246;
  --btn-bg-main-hvr: var(--clr-dark-blue);
  --btn-clr-main: #fff;
  --btn-clr--sec: #fff;
  --btn-clr-wht: #333;
  --btn-bg-wht: #fff;
  --btn-clr-wht-hvr: #fff;
  --btn-bg-wht-hvr: var(--clr-dark-blue);
  --brd-main: #1D7FB7;
  --transit-default: all 0.4s ease-out;
}

/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes FadeInTop {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes FadeInBtm {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes FadeInRgt {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes FadeInLft {
  0% {
    transform: translateX(30px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes Buruburu {
  0% {
    transform: translate(0, -1px) rotate(0deg);
  }
  20% {
    transform: translate(0, -2px) rotate(-3deg);
  }
  40% {
    transform: translate(0, -1px) rotate(3deg);
  }
  60% {
    transform: translate(0, -2px) rotate(-3deg);
  }
  80% {
    transform: translate(0, -1px) rotate(3deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
@keyframes cursor_more {
  0% {
    width: 16px;
    height: 16px;
    margin: -8px 0 0 -8px;
  }
  100% {
    width: 120px;
    height: 120px;
    margin: -30px 0 0 -30px;
  }
}
@keyframes hero_txt_slider {
  0% {
    transform: translate(-25%, 0);
  }
  100% {
    transform: translate(-75%, 0);
  }
}
/* ##############################################################################

    style info : リセット及びフォントサイズ

############################################################################## */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
  background: #fff;
  color: #333;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ins {
  background-color: #ff9;
  color: #333;
  text-decoration: none;
}

mark {
  color: #63502f;
  background: linear-gradient(transparent 50%, #FFFF00 50%);
  font-weight: bold;
  font-family: var(--font-primary-bold);
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*-- ClearFix --*/
/* For modern browsers */
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
  zoom: 1;
}

/*隙間消し*/
li img {
  vertical-align: bottom;
}

/* Reset input[type="search"] */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

input[type=search]:focus {
  outline-offset: -2px;
}

input[type=search]::-webkit-search-decoration {
  display: none;
}

/* iOS reset */
input[type=submit] {
  -webkit-appearance: none;
}

@media print, screen and (min-width: 768px) {
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @-moz-document url-prefix() {
    * {
      font-feature-settings: "palt";
    }
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  * {
    font-feature-settings: "palt";
  }
}
/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: 16px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  html {
    overflow-y: auto;
    overflow-x: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: var(--font-primary, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  line-height: var(--line-height-default, 2.2);
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default, 0.1em);
  *font-size: small;
  *font: x-small;
  color: var(--clr-body, #63502f);
  background-color: #f2f3f1;
  -webkit-font-smoothing: antialiased;
  min-width: 1080px;
  height: 100%;
  padding-top: 136px;
  word-break: break-word;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  body:not(.mce-content-body) {
    min-width: 1080px;
  }
}
@media screen and (max-width: 67.5em) {
  body {
    padding-top: 100px;
  }
  body.home {
    padding-top: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  body {
    min-width: inherit;
    -webkit-text-size-adjust: none;
  }
}

.home main {
  overflow: hidden;
}

@media screen and (max-width: 47.9375em) {
  .page main {
    overflow: hidden;
  }
}

ul,
ol {
  list-style: none;
}

small {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  small {
    font-size: 0.9285714286rem;
  }
}

a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link, #333);
  text-decoration: none;
  transition: var(--transit-default, all 0.4s ease-out);
  outline: none;
}

a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:focus {
  text-decoration: none !important;
}

img {
  vertical-align: middle;
}
@media screen and (max-width: 47.9375em) {
  img {
    max-width: 100%;
    height: auto;
  }
}

address,
em,
i {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr, 1.4);
}

svg {
  transition: var(--transit-default, all 0.4s ease-out);
}
svg[preserveAspectRatio=none] {
  height: auto;
}

/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}

.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}

.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: 0.5em;
}

.mce-content-body ul li {
  list-style: none;
}

.mce-content-body ul li::before {
  content: "";
  position: absolute;
  top: 1.2em;
  left: -1.3em;
  width: 0.625rem;
  height: 0.625rem;
  border: 1px solid #d79db8;
  background-color: #d79db8;
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body ul li::before {
    top: 1.4em;
  }
}

.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: #fff;
}

.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}

.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}

.mce-content-body hr {
  border-color: #D3D3D3;
}

.mce-content-body .box {
  padding: 24px 32px;
  background-color: rgba(63, 160, 55, 0.2509803922);
  border-radius: 20px;
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body .box {
    border-radius: 10px;
  }
}

.mce-content-body .box:not(:first-child) {
  margin-top: 2em;
}

.mce-content-body .box:not(:last-child) {
  margin-bottom: 2em;
}

.mce-content-body .box-wh {
  background-color: var(--bg-wht, #fff);
  border: 0;
}

.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mce-content-body .aligncenter {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.mce-content-body img.aligncenter {
  display: block;
}

.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}

.mce-content-body p {
  letter-spacing: 0.1em;
}

.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body strong {
  font-weight: bold;
  font-family: var(--font-primary-bold);
}

.mce-content-body blockquote {
  margin: 1em 0;
  padding: 15px 40px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: #f8f8f8;
}

.mce-content-body blockquote p {
  margin-bottom: 0;
}

.mce-content-body blockquote::before,
.mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}

.mce-content-body blockquote::before {
  background-image: url("../images/common/icon-quote_left.svg");
  top: 13px;
  left: 10px;
}

.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 13px;
  right: 10px;
}

.mce-content-body a {
  text-decoration: underline;
}

.mce-content-body a:hover {
  text-decoration: none;
}

.mce-content-body .footer_text small {
  display: block;
  margin-top: 8px;
  text-align: right;
}

.mce-content-body table {
  border: none;
  height: auto !important;
}

.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}

.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}

.mce-content-body th,
.mce-content-body td {
  padding: 1.25em 1.5em;
  line-height: 1.8;
  vertical-align: top;
}

.mce-content-body td ul li::before {
  top: 1em;
}

.mce-content-body th {
  min-width: 120px;
  text-align: left;
  vertical-align: middle;
}

.mce-content-body sup {
  font-size: 0.75rem;
  color: #555;
}

.bold {
  background: linear-gradient(transparent 50%, rgba(63, 160, 55, 0.2509803922) 50%);
  font-family: var(--font-primary-bold);
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}

.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}

.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}

.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}

.scroll table {
  margin: 0;
}

.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: 0.25;
  font-size: 0.875rem;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body p:not([class]) {
    text-align: left !important;
  }
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body ul li::before {
    width: 0.5rem;
    height: 0.5rem;
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body .box {
    padding: 24px;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor,
.mce-content-body.wp-editor * {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
}

.mce-content-body.wp-editor {
  padding: 24px !important;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  font-family: var(--font-jp);
  line-height: 1.5;
  letter-spacing: 0.05em;
}

.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 1.2em;
}

.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 0.6em;
}

.editor-ttl {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl {
    font-size: 1.7142857143rem;
  }
}

.editor-ttl2 {
  font-size: 1.5rem;
  color: var(--clr-main, #1d7fb7);
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl2 {
    font-size: 1.4285714286rem;
  }
}

.editor-ttl3 {
  position: relative;
  font-size: 1.125rem;
  padding-left: 1em;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl3 {
    font-size: 1.1428571429rem;
  }
}

.editor-ttl3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.3em;
  height: 100%;
  background-color: var(--clr-main, #1d7fb7);
  border-radius: 0.15em;
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: 0.875rem;
  color: #999;
}
@media screen and (max-width: 47.9375em) {
  .txt-attention {
    font-size: 0.9285714286rem;
  }
}
.txt-attention::before {
  content: "※";
}

/* テーブル1 */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
}

.table1 th {
  color: var(--clr-main, #1d7fb7);
  font-size: 1.125rem;
}

.table1 th::before,
.table1 td::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 3px);
}

.table1 th::before {
  left: 0;
  height: 2px;
  background-color: var(--clr-main, #1d7fb7);
}

.table1 td::before {
  right: 0;
  height: 1px;
  background-color: #D3D3D3;
}

/* テーブル2 */
.mce-content-body table:not(.table1),
.table2 {
  border-collapse: separate;
}

.mce-content-body table:not(.table1) th,
.table2 th {
  max-width: 240px;
  color: var(--clr-wht, #fff);
  background-color: #3fa037;
  text-align: center;
  border-right: 1px solid #fff;
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body table:not(.table1) th,
  .table2 th {
    width: inherit;
    font-size: 1rem;
  }
}
.mce-content-body table:not(.table1) th:last-child,
.table2 th:last-child {
  border-right: inherit;
}

.mce-content-body table:not(.table1) tr:not(:last-child) th {
  border-bottom: solid 1px #eaece8;
}

.mce-content-body table:not(.table1) tr td,
.table2 tr td {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-left: none;
  border-bottom: none;
}
.mce-content-body table:not(.table1) tr td:first-child,
.table2 tr td:first-child {
  border-left: 1px solid #d3d3d3;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body table:not(.table1).table-sp-block td {
    border-left: 1px solid #d3d3d3;
  }
}

.mce-content-body table:not(.table1) tr:first-child > *:first-child {
  border-top-left-radius: 16px;
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body table:not(.table1) tr:first-child > *:first-child {
    border-top-left-radius: 8px;
  }
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body table:not(.table1).table-sp-block tr:first-child > *:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.mce-content-body table:not(.table1) tr:first-child > *:last-child {
  border-top-right-radius: 16px;
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body table:not(.table1) tr:first-child > *:last-child {
    border-top-right-radius: 8px;
  }
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body table:not(.table1).table-sp-block tr:first-child > *:last-child {
    border-top-right-radius: inherit;
  }
}

.mce-content-body table:not(.table1) tr:last-child > *:first-child {
  border-bottom-left-radius: 16px;
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body table:not(.table1) tr:last-child > *:first-child {
    border-bottom-left-radius: 8px;
  }
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body table:not(.table1).table-sp-block tr:last-child > *:first-child {
    border-bottom-left-radius: inherit;
  }
}

.mce-content-body table:not(.table1) tr:last-child td {
  border-bottom: 1px solid #d3d3d3;
}
.mce-content-body table:not(.table1) tr:last-child > *:last-child {
  border-bottom-right-radius: 16px;
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body table:not(.table1) tr:last-child > *:last-child {
    border-bottom-right-radius: 8px;
  }
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body table:not(.table1).table-sp-block tr:last-child > *:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

@media screen and (max-width: 47.9375em) {
  /* テーブル */
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
  .table1.table-sp-block td::before {
    display: none;
  }
}
.wp-editor .paragraph {
  padding-left: 0;
  font-size: 1rem;
  color: #63502f;
}
.wp-editor .editor-ttl {
  padding-left: 0;
  color: #63502f;
}
.wp-editor .editor-ttl2 {
  padding-left: 0;
}
.wp-editor .editor-ttl3 {
  color: #63502f;
}
.wp-editor .editor-ttl3:has(.paragraph)::before, .wp-editor .editor-ttl3:has(.editor-ttl)::before, .wp-editor .editor-ttl3:has(.editor-ttl2)::before, .wp-editor .editor-ttl3:has(.editor-ttl3)::before {
  content: inherit;
}

/* txt
**************************************** */
.txt-lg {
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-lg {
    font-size: 1.0714285714rem;
  }
}

.txt-sm {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-sm {
    font-size: 0.9285714286rem;
  }
}

.txt-wh,
.txt-wh * {
  color: var(--clr-wht, #fff);
}

.txt-main,
.txt-main * {
  color: var(--clr-main, #1d7fb7);
}

.txt-sub,
.txt-sub * {
  color: var(--clr-sub, #eee);
}

/* --- font-size --- */
.fz-8 {
  font-size: 0.5rem;
}

.fz-9 {
  font-size: 0.5625rem;
}

.fz-10 {
  font-size: 0.625rem;
}

.fz-11 {
  font-size: 0.6875rem;
}

.fz-12 {
  font-size: 0.75rem;
}

.fz-13 {
  font-size: 0.8125rem;
}

.fz-14 {
  font-size: 0.875rem;
}

.fz-15 {
  font-size: 0.9375rem;
}

.fz-16 {
  font-size: 1rem;
}

.fz-17 {
  font-size: 1.0625rem;
}

.fz-18 {
  font-size: 1.125rem;
}

.fz-19 {
  font-size: 1.1875rem;
}

.fz-20 {
  font-size: 1.25rem;
}

.fz-21 {
  font-size: 1.3125rem;
}

.fz-22 {
  font-size: 1.375rem;
}

.fz-23 {
  font-size: 1.4375rem;
}

.fz-24 {
  font-size: 1.5rem;
}

.fz-25 {
  font-size: 1.5625rem;
}

.fz-26 {
  font-size: 1.625rem;
}

.fz-27 {
  font-size: 1.6875rem;
}

.fz-28 {
  font-size: 1.75rem;
}

.fz-29 {
  font-size: 1.8125rem;
}

.fz-30 {
  font-size: 1.875rem;
}

.fz-31 {
  font-size: 1.9375rem;
}

.fz-32 {
  font-size: 2rem;
}

.fz-33 {
  font-size: 2.0625rem;
}

.fz-34 {
  font-size: 2.125rem;
}

.fz-35 {
  font-size: 2.1875rem;
}

.fz-36 {
  font-size: 2.25rem;
}

.fz-37 {
  font-size: 2.3125rem;
}

.fz-38 {
  font-size: 2.375rem;
}

.fz-39 {
  font-size: 2.4375rem;
}

.fz-40 {
  font-size: 2.5rem;
}

.fz-41 {
  font-size: 2.5625rem;
}

.fz-42 {
  font-size: 2.625rem;
}

.fz-43 {
  font-size: 2.6875rem;
}

.fz-44 {
  font-size: 2.75rem;
}

.fz-45 {
  font-size: 2.8125rem;
}

.fz-46 {
  font-size: 2.875rem;
}

.fz-47 {
  font-size: 2.9375rem;
}

.fz-48 {
  font-size: 3rem;
}

.fz-49 {
  font-size: 3.0625rem;
}

.fz-50 {
  font-size: 3.125rem;
}

.fz-51 {
  font-size: 3.1875rem;
}

.fz-52 {
  font-size: 3.25rem;
}

.fz-53 {
  font-size: 3.3125rem;
}

.fz-54 {
  font-size: 3.375rem;
}

.fz-55 {
  font-size: 3.4375rem;
}

.fz-56 {
  font-size: 3.5rem;
}

.fz-57 {
  font-size: 3.5625rem;
}

.fz-58 {
  font-size: 3.625rem;
}

.fz-59 {
  font-size: 3.6875rem;
}

.fz-60 {
  font-size: 3.75rem;
}

.fz-61 {
  font-size: 3.8125rem;
}

.fz-62 {
  font-size: 3.875rem;
}

.fz-63 {
  font-size: 3.9375rem;
}

.fz-64 {
  font-size: 4rem;
}

.fz-65 {
  font-size: 4.0625rem;
}

.fz-66 {
  font-size: 4.125rem;
}

.fz-67 {
  font-size: 4.1875rem;
}

.fz-68 {
  font-size: 4.25rem;
}

.fz-69 {
  font-size: 4.3125rem;
}

.fz-70 {
  font-size: 4.375rem;
}

.fz-71 {
  font-size: 4.4375rem;
}

.fz-72 {
  font-size: 4.5rem;
}

.fz-73 {
  font-size: 4.5625rem;
}

.fz-74 {
  font-size: 4.625rem;
}

.fz-75 {
  font-size: 4.6875rem;
}

.fz-76 {
  font-size: 4.75rem;
}

.fz-77 {
  font-size: 4.8125rem;
}

.fz-78 {
  font-size: 4.875rem;
}

.fz-79 {
  font-size: 4.9375rem;
}

.fz-80 {
  font-size: 5rem;
}

.fz-81 {
  font-size: 5.0625rem;
}

.fz-82 {
  font-size: 5.125rem;
}

.fz-83 {
  font-size: 5.1875rem;
}

.fz-84 {
  font-size: 5.25rem;
}

.fz-85 {
  font-size: 5.3125rem;
}

.fz-86 {
  font-size: 5.375rem;
}

.fz-87 {
  font-size: 5.4375rem;
}

.fz-88 {
  font-size: 5.5rem;
}

.fz-89 {
  font-size: 5.5625rem;
}

.fz-90 {
  font-size: 5.625rem;
}

.fz-91 {
  font-size: 5.6875rem;
}

.fz-92 {
  font-size: 5.75rem;
}

.fz-93 {
  font-size: 5.8125rem;
}

.fz-94 {
  font-size: 5.875rem;
}

.fz-95 {
  font-size: 5.9375rem;
}

.fz-96 {
  font-size: 6rem;
}

.fz-97 {
  font-size: 6.0625rem;
}

.fz-98 {
  font-size: 6.125rem;
}

.fz-99 {
  font-size: 6.1875rem;
}

.fz-100 {
  font-size: 6.25rem;
}

.fz-101 {
  font-size: 6.3125rem;
}

.fz-102 {
  font-size: 6.375rem;
}

.fz-103 {
  font-size: 6.4375rem;
}

.fz-104 {
  font-size: 6.5rem;
}

.fz-105 {
  font-size: 6.5625rem;
}

.fz-106 {
  font-size: 6.625rem;
}

.fz-107 {
  font-size: 6.6875rem;
}

.fz-108 {
  font-size: 6.75rem;
}

.fz-109 {
  font-size: 6.8125rem;
}

.fz-110 {
  font-size: 6.875rem;
}

.fz-111 {
  font-size: 6.9375rem;
}

.fz-112 {
  font-size: 7rem;
}

.fz-113 {
  font-size: 7.0625rem;
}

.fz-114 {
  font-size: 7.125rem;
}

.fz-115 {
  font-size: 7.1875rem;
}

.fz-116 {
  font-size: 7.25rem;
}

.fz-117 {
  font-size: 7.3125rem;
}

.fz-118 {
  font-size: 7.375rem;
}

.fz-119 {
  font-size: 7.4375rem;
}

.fz-120 {
  font-size: 7.5rem;
}

.fz-121 {
  font-size: 7.5625rem;
}

.fz-122 {
  font-size: 7.625rem;
}

.fz-123 {
  font-size: 7.6875rem;
}

.fz-124 {
  font-size: 7.75rem;
}

.fz-125 {
  font-size: 7.8125rem;
}

.fz-126 {
  font-size: 7.875rem;
}

.fz-127 {
  font-size: 7.9375rem;
}

.fz-128 {
  font-size: 8rem;
}

.fz-129 {
  font-size: 8.0625rem;
}

.fz-130 {
  font-size: 8.125rem;
}

.fz-131 {
  font-size: 8.1875rem;
}

.fz-132 {
  font-size: 8.25rem;
}

.fz-133 {
  font-size: 8.3125rem;
}

.fz-134 {
  font-size: 8.375rem;
}

.fz-135 {
  font-size: 8.4375rem;
}

.fz-136 {
  font-size: 8.5rem;
}

.fz-137 {
  font-size: 8.5625rem;
}

.fz-138 {
  font-size: 8.625rem;
}

.fz-139 {
  font-size: 8.6875rem;
}

/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
}

.font-sta {
  font-family: var(--font-primary, "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004");
}

.font-sta-bold {
  font-family: var(--font-primary-bold, "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004");
}

.font-en {
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 400;
}

/* --- clip-txt --- */
.clip-txt1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clip-txt2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.clip-txt3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.clip-txt4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}

.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@media screen and (max-width: 47.9375em) {
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
}
.block {
  display: block;
}

.txt-edge {
  color: #fff;
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 300;
  text-shadow: 1px 1px 0 #63502f, -1px -1px 0 #63502f, -1px 1px 0 #63502f, 1px -1px 0 #63502f, 0px 1px 0 #63502f, 0 -1px 0 #63502f, -1px 0 0 #63502f, 1px 0 0 #63502f;
}

.txt-bold {
  position: relative;
}
.txt-bold::before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 100%;
  height: 32px;
  background-color: #3fa037;
  border-radius: 8px;
  opacity: 0.25;
  z-index: -1;
}
@media screen and (max-width: 47.9375em) {
  .txt-bold::before {
    bottom: -6px;
    height: 22px;
    border-radius: 4px;
  }
}

.txt .txt-bold {
  position: relative;
  font-family: var(--font-primary-bold);
}
.txt .txt-bold::before {
  content: "";
  position: absolute;
  bottom: -6px;
  height: 1em;
  border-radius: 4px;
}

/* 縦書き
**************************************** */
.txt-vl {
  writing-mode: vertical-rl;
  font-feature-settings: initial;
}
.txt-vl .txt-bold {
  position: relative;
  line-height: 1;
  display: inline-block;
}
.txt-vl .txt-bold::before {
  content: "";
  position: absolute;
  top: 0;
  left: -0.14em;
  width: 0.64em;
  height: 100%;
  background-color: #3fa037;
  border-radius: 8px;
  opacity: 0.25;
  z-index: -1;
}

.txt-vl * {
  font-feature-settings: initial;
}

.txt-vl .num {
  text-combine-upright: all;
}

.sideways {
  text-orientation: sideways;
}

/* bg
********************************************** */
.bg-main {
  background-color: var(--bg-main, #1D7FB7);
}

.bg-sub {
  background-color: #eee;
}

.bg-wh {
  background-color: var(--bg-wht, #fff);
}

/* --- pc or sp --- */
.pc-none,
.pc-none-inline,
.pc-none-table,
.pc-none-flex {
  display: none;
}

.sp-none {
  display: block;
}

.sp-none-inline {
  display: inline;
}

.sp-none-table {
  display: table;
}

.sp-none-flex {
  display: flex;
}

@media screen and (max-width: 47.9375em) {
  /* --- pc or sp --- */
  .sp-none,
  .sp-none-inline,
  .sp-none-table,
  .sp-none-flex {
    display: none;
  }
  .pc-none {
    display: block;
  }
  .pc-none-inline {
    display: inline;
  }
  .pc-none-table {
    display: table;
  }
  .pc-none-flex {
    display: flex;
  }
}
/* img
********************************************** */
.img-ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-trim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trim-widescreen {
  aspect-ratio: 3/2;
}

.trim-square {
  aspect-ratio: 1/1;
}

.trim-portrait {
  aspect-ratio: 2/3;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

/* --- margin 　古い・削除--- */
.mgn-btm8 {
  margin-bottom: 8px;
}

.mgn-btm16 {
  margin-bottom: 16px;
}

.mgn-btm24 {
  margin-bottom: 24px;
}

.mgn-btm32 {
  margin-bottom: 32px;
}

.mgn-btm40 {
  margin-bottom: 40px;
}

.mgn-btm48 {
  margin-bottom: 48px;
}

.mgn-btm56 {
  margin-bottom: 56px;
}

.mgn-btm64 {
  margin-bottom: 64px;
}

.mgn-btm72 {
  margin-bottom: 72px;
}

.mgn-btm80 {
  margin-bottom: 80px;
}

.mgn-0 {
  margin: 0 rem;
}

.mgn-top0 {
  margin-top: 0 rem;
}

.mgn-rgt0 {
  margin-right: 0 rem;
}

.mgn-btm0 {
  margin-bottom: 0 rem;
}

.mgn-lft0 {
  margin-left: 0 rem;
}

.mgn-1 {
  margin: 0.0625 rem;
}

.mgn-top1 {
  margin-top: 0.0625 rem;
}

.mgn-rgt1 {
  margin-right: 0.0625 rem;
}

.mgn-btm1 {
  margin-bottom: 0.0625 rem;
}

.mgn-lft1 {
  margin-left: 0.0625 rem;
}

.mgn-2 {
  margin: 0.125 rem;
}

.mgn-top2 {
  margin-top: 0.125 rem;
}

.mgn-rgt2 {
  margin-right: 0.125 rem;
}

.mgn-btm2 {
  margin-bottom: 0.125 rem;
}

.mgn-lft2 {
  margin-left: 0.125 rem;
}

.mgn-3 {
  margin: 0.1875 rem;
}

.mgn-top3 {
  margin-top: 0.1875 rem;
}

.mgn-rgt3 {
  margin-right: 0.1875 rem;
}

.mgn-btm3 {
  margin-bottom: 0.1875 rem;
}

.mgn-lft3 {
  margin-left: 0.1875 rem;
}

.mgn-4 {
  margin: 0.25 rem;
}

.mgn-top4 {
  margin-top: 0.25 rem;
}

.mgn-rgt4 {
  margin-right: 0.25 rem;
}

.mgn-btm4 {
  margin-bottom: 0.25 rem;
}

.mgn-lft4 {
  margin-left: 0.25 rem;
}

.mgn-5 {
  margin: 0.3125 rem;
}

.mgn-top5 {
  margin-top: 0.3125 rem;
}

.mgn-rgt5 {
  margin-right: 0.3125 rem;
}

.mgn-btm5 {
  margin-bottom: 0.3125 rem;
}

.mgn-lft5 {
  margin-left: 0.3125 rem;
}

.mgn-6 {
  margin: 0.375 rem;
}

.mgn-top6 {
  margin-top: 0.375 rem;
}

.mgn-rgt6 {
  margin-right: 0.375 rem;
}

.mgn-btm6 {
  margin-bottom: 0.375 rem;
}

.mgn-lft6 {
  margin-left: 0.375 rem;
}

.mgn-7 {
  margin: 0.4375 rem;
}

.mgn-top7 {
  margin-top: 0.4375 rem;
}

.mgn-rgt7 {
  margin-right: 0.4375 rem;
}

.mgn-btm7 {
  margin-bottom: 0.4375 rem;
}

.mgn-lft7 {
  margin-left: 0.4375 rem;
}

.mgn-8 {
  margin: 0.5 rem;
}

.mgn-top8 {
  margin-top: 0.5 rem;
}

.mgn-rgt8 {
  margin-right: 0.5 rem;
}

.mgn-btm8 {
  margin-bottom: 0.5 rem;
}

.mgn-lft8 {
  margin-left: 0.5 rem;
}

.mgn-9 {
  margin: 0.5625 rem;
}

.mgn-top9 {
  margin-top: 0.5625 rem;
}

.mgn-rgt9 {
  margin-right: 0.5625 rem;
}

.mgn-btm9 {
  margin-bottom: 0.5625 rem;
}

.mgn-lft9 {
  margin-left: 0.5625 rem;
}

.mgn-10 {
  margin: 0.625 rem;
}

.mgn-top10 {
  margin-top: 0.625 rem;
}

.mgn-rgt10 {
  margin-right: 0.625 rem;
}

.mgn-btm10 {
  margin-bottom: 0.625 rem;
}

.mgn-lft10 {
  margin-left: 0.625 rem;
}

.mgn-11 {
  margin: 0.6875 rem;
}

.mgn-top11 {
  margin-top: 0.6875 rem;
}

.mgn-rgt11 {
  margin-right: 0.6875 rem;
}

.mgn-btm11 {
  margin-bottom: 0.6875 rem;
}

.mgn-lft11 {
  margin-left: 0.6875 rem;
}

.mgn-12 {
  margin: 0.75 rem;
}

.mgn-top12 {
  margin-top: 0.75 rem;
}

.mgn-rgt12 {
  margin-right: 0.75 rem;
}

.mgn-btm12 {
  margin-bottom: 0.75 rem;
}

.mgn-lft12 {
  margin-left: 0.75 rem;
}

.mgn-13 {
  margin: 0.8125 rem;
}

.mgn-top13 {
  margin-top: 0.8125 rem;
}

.mgn-rgt13 {
  margin-right: 0.8125 rem;
}

.mgn-btm13 {
  margin-bottom: 0.8125 rem;
}

.mgn-lft13 {
  margin-left: 0.8125 rem;
}

.mgn-14 {
  margin: 0.875 rem;
}

.mgn-top14 {
  margin-top: 0.875 rem;
}

.mgn-rgt14 {
  margin-right: 0.875 rem;
}

.mgn-btm14 {
  margin-bottom: 0.875 rem;
}

.mgn-lft14 {
  margin-left: 0.875 rem;
}

.mgn-15 {
  margin: 0.9375 rem;
}

.mgn-top15 {
  margin-top: 0.9375 rem;
}

.mgn-rgt15 {
  margin-right: 0.9375 rem;
}

.mgn-btm15 {
  margin-bottom: 0.9375 rem;
}

.mgn-lft15 {
  margin-left: 0.9375 rem;
}

.mgn-16 {
  margin: 1 rem;
}

.mgn-top16 {
  margin-top: 1 rem;
}

.mgn-rgt16 {
  margin-right: 1 rem;
}

.mgn-btm16 {
  margin-bottom: 1 rem;
}

.mgn-lft16 {
  margin-left: 1 rem;
}

.mgn-17 {
  margin: 1.0625 rem;
}

.mgn-top17 {
  margin-top: 1.0625 rem;
}

.mgn-rgt17 {
  margin-right: 1.0625 rem;
}

.mgn-btm17 {
  margin-bottom: 1.0625 rem;
}

.mgn-lft17 {
  margin-left: 1.0625 rem;
}

.mgn-18 {
  margin: 1.125 rem;
}

.mgn-top18 {
  margin-top: 1.125 rem;
}

.mgn-rgt18 {
  margin-right: 1.125 rem;
}

.mgn-btm18 {
  margin-bottom: 1.125 rem;
}

.mgn-lft18 {
  margin-left: 1.125 rem;
}

.mgn-19 {
  margin: 1.1875 rem;
}

.mgn-top19 {
  margin-top: 1.1875 rem;
}

.mgn-rgt19 {
  margin-right: 1.1875 rem;
}

.mgn-btm19 {
  margin-bottom: 1.1875 rem;
}

.mgn-lft19 {
  margin-left: 1.1875 rem;
}

.mgn-20 {
  margin: 1.25 rem;
}

.mgn-top20 {
  margin-top: 1.25 rem;
}

.mgn-rgt20 {
  margin-right: 1.25 rem;
}

.mgn-btm20 {
  margin-bottom: 1.25 rem;
}

.mgn-lft20 {
  margin-left: 1.25 rem;
}

.mgn-21 {
  margin: 1.3125 rem;
}

.mgn-top21 {
  margin-top: 1.3125 rem;
}

.mgn-rgt21 {
  margin-right: 1.3125 rem;
}

.mgn-btm21 {
  margin-bottom: 1.3125 rem;
}

.mgn-lft21 {
  margin-left: 1.3125 rem;
}

.mgn-22 {
  margin: 1.375 rem;
}

.mgn-top22 {
  margin-top: 1.375 rem;
}

.mgn-rgt22 {
  margin-right: 1.375 rem;
}

.mgn-btm22 {
  margin-bottom: 1.375 rem;
}

.mgn-lft22 {
  margin-left: 1.375 rem;
}

.mgn-23 {
  margin: 1.4375 rem;
}

.mgn-top23 {
  margin-top: 1.4375 rem;
}

.mgn-rgt23 {
  margin-right: 1.4375 rem;
}

.mgn-btm23 {
  margin-bottom: 1.4375 rem;
}

.mgn-lft23 {
  margin-left: 1.4375 rem;
}

.mgn-24 {
  margin: 1.5 rem;
}

.mgn-top24 {
  margin-top: 1.5 rem;
}

.mgn-rgt24 {
  margin-right: 1.5 rem;
}

.mgn-btm24 {
  margin-bottom: 1.5 rem;
}

.mgn-lft24 {
  margin-left: 1.5 rem;
}

.mgn-25 {
  margin: 1.5625 rem;
}

.mgn-top25 {
  margin-top: 1.5625 rem;
}

.mgn-rgt25 {
  margin-right: 1.5625 rem;
}

.mgn-btm25 {
  margin-bottom: 1.5625 rem;
}

.mgn-lft25 {
  margin-left: 1.5625 rem;
}

.mgn-26 {
  margin: 1.625 rem;
}

.mgn-top26 {
  margin-top: 1.625 rem;
}

.mgn-rgt26 {
  margin-right: 1.625 rem;
}

.mgn-btm26 {
  margin-bottom: 1.625 rem;
}

.mgn-lft26 {
  margin-left: 1.625 rem;
}

.mgn-27 {
  margin: 1.6875 rem;
}

.mgn-top27 {
  margin-top: 1.6875 rem;
}

.mgn-rgt27 {
  margin-right: 1.6875 rem;
}

.mgn-btm27 {
  margin-bottom: 1.6875 rem;
}

.mgn-lft27 {
  margin-left: 1.6875 rem;
}

.mgn-28 {
  margin: 1.75 rem;
}

.mgn-top28 {
  margin-top: 1.75 rem;
}

.mgn-rgt28 {
  margin-right: 1.75 rem;
}

.mgn-btm28 {
  margin-bottom: 1.75 rem;
}

.mgn-lft28 {
  margin-left: 1.75 rem;
}

.mgn-29 {
  margin: 1.8125 rem;
}

.mgn-top29 {
  margin-top: 1.8125 rem;
}

.mgn-rgt29 {
  margin-right: 1.8125 rem;
}

.mgn-btm29 {
  margin-bottom: 1.8125 rem;
}

.mgn-lft29 {
  margin-left: 1.8125 rem;
}

.mgn-30 {
  margin: 1.875 rem;
}

.mgn-top30 {
  margin-top: 1.875 rem;
}

.mgn-rgt30 {
  margin-right: 1.875 rem;
}

.mgn-btm30 {
  margin-bottom: 1.875 rem;
}

.mgn-lft30 {
  margin-left: 1.875 rem;
}

.mgn-31 {
  margin: 1.9375 rem;
}

.mgn-top31 {
  margin-top: 1.9375 rem;
}

.mgn-rgt31 {
  margin-right: 1.9375 rem;
}

.mgn-btm31 {
  margin-bottom: 1.9375 rem;
}

.mgn-lft31 {
  margin-left: 1.9375 rem;
}

.mgn-32 {
  margin: 2 rem;
}

.mgn-top32 {
  margin-top: 2 rem;
}

.mgn-rgt32 {
  margin-right: 2 rem;
}

.mgn-btm32 {
  margin-bottom: 2 rem;
}

.mgn-lft32 {
  margin-left: 2 rem;
}

.mgn-33 {
  margin: 2.0625 rem;
}

.mgn-top33 {
  margin-top: 2.0625 rem;
}

.mgn-rgt33 {
  margin-right: 2.0625 rem;
}

.mgn-btm33 {
  margin-bottom: 2.0625 rem;
}

.mgn-lft33 {
  margin-left: 2.0625 rem;
}

.mgn-34 {
  margin: 2.125 rem;
}

.mgn-top34 {
  margin-top: 2.125 rem;
}

.mgn-rgt34 {
  margin-right: 2.125 rem;
}

.mgn-btm34 {
  margin-bottom: 2.125 rem;
}

.mgn-lft34 {
  margin-left: 2.125 rem;
}

.mgn-35 {
  margin: 2.1875 rem;
}

.mgn-top35 {
  margin-top: 2.1875 rem;
}

.mgn-rgt35 {
  margin-right: 2.1875 rem;
}

.mgn-btm35 {
  margin-bottom: 2.1875 rem;
}

.mgn-lft35 {
  margin-left: 2.1875 rem;
}

.mgn-36 {
  margin: 2.25 rem;
}

.mgn-top36 {
  margin-top: 2.25 rem;
}

.mgn-rgt36 {
  margin-right: 2.25 rem;
}

.mgn-btm36 {
  margin-bottom: 2.25 rem;
}

.mgn-lft36 {
  margin-left: 2.25 rem;
}

.mgn-37 {
  margin: 2.3125 rem;
}

.mgn-top37 {
  margin-top: 2.3125 rem;
}

.mgn-rgt37 {
  margin-right: 2.3125 rem;
}

.mgn-btm37 {
  margin-bottom: 2.3125 rem;
}

.mgn-lft37 {
  margin-left: 2.3125 rem;
}

.mgn-38 {
  margin: 2.375 rem;
}

.mgn-top38 {
  margin-top: 2.375 rem;
}

.mgn-rgt38 {
  margin-right: 2.375 rem;
}

.mgn-btm38 {
  margin-bottom: 2.375 rem;
}

.mgn-lft38 {
  margin-left: 2.375 rem;
}

.mgn-39 {
  margin: 2.4375 rem;
}

.mgn-top39 {
  margin-top: 2.4375 rem;
}

.mgn-rgt39 {
  margin-right: 2.4375 rem;
}

.mgn-btm39 {
  margin-bottom: 2.4375 rem;
}

.mgn-lft39 {
  margin-left: 2.4375 rem;
}

.mgn-40 {
  margin: 2.5 rem;
}

.mgn-top40 {
  margin-top: 2.5 rem;
}

.mgn-rgt40 {
  margin-right: 2.5 rem;
}

.mgn-btm40 {
  margin-bottom: 2.5 rem;
}

.mgn-lft40 {
  margin-left: 2.5 rem;
}

.mgn-41 {
  margin: 2.5625 rem;
}

.mgn-top41 {
  margin-top: 2.5625 rem;
}

.mgn-rgt41 {
  margin-right: 2.5625 rem;
}

.mgn-btm41 {
  margin-bottom: 2.5625 rem;
}

.mgn-lft41 {
  margin-left: 2.5625 rem;
}

.mgn-42 {
  margin: 2.625 rem;
}

.mgn-top42 {
  margin-top: 2.625 rem;
}

.mgn-rgt42 {
  margin-right: 2.625 rem;
}

.mgn-btm42 {
  margin-bottom: 2.625 rem;
}

.mgn-lft42 {
  margin-left: 2.625 rem;
}

.mgn-43 {
  margin: 2.6875 rem;
}

.mgn-top43 {
  margin-top: 2.6875 rem;
}

.mgn-rgt43 {
  margin-right: 2.6875 rem;
}

.mgn-btm43 {
  margin-bottom: 2.6875 rem;
}

.mgn-lft43 {
  margin-left: 2.6875 rem;
}

.mgn-44 {
  margin: 2.75 rem;
}

.mgn-top44 {
  margin-top: 2.75 rem;
}

.mgn-rgt44 {
  margin-right: 2.75 rem;
}

.mgn-btm44 {
  margin-bottom: 2.75 rem;
}

.mgn-lft44 {
  margin-left: 2.75 rem;
}

.mgn-45 {
  margin: 2.8125 rem;
}

.mgn-top45 {
  margin-top: 2.8125 rem;
}

.mgn-rgt45 {
  margin-right: 2.8125 rem;
}

.mgn-btm45 {
  margin-bottom: 2.8125 rem;
}

.mgn-lft45 {
  margin-left: 2.8125 rem;
}

.mgn-46 {
  margin: 2.875 rem;
}

.mgn-top46 {
  margin-top: 2.875 rem;
}

.mgn-rgt46 {
  margin-right: 2.875 rem;
}

.mgn-btm46 {
  margin-bottom: 2.875 rem;
}

.mgn-lft46 {
  margin-left: 2.875 rem;
}

.mgn-47 {
  margin: 2.9375 rem;
}

.mgn-top47 {
  margin-top: 2.9375 rem;
}

.mgn-rgt47 {
  margin-right: 2.9375 rem;
}

.mgn-btm47 {
  margin-bottom: 2.9375 rem;
}

.mgn-lft47 {
  margin-left: 2.9375 rem;
}

.mgn-48 {
  margin: 3 rem;
}

.mgn-top48 {
  margin-top: 3 rem;
}

.mgn-rgt48 {
  margin-right: 3 rem;
}

.mgn-btm48 {
  margin-bottom: 3 rem;
}

.mgn-lft48 {
  margin-left: 3 rem;
}

.mgn-49 {
  margin: 3.0625 rem;
}

.mgn-top49 {
  margin-top: 3.0625 rem;
}

.mgn-rgt49 {
  margin-right: 3.0625 rem;
}

.mgn-btm49 {
  margin-bottom: 3.0625 rem;
}

.mgn-lft49 {
  margin-left: 3.0625 rem;
}

.mgn-50 {
  margin: 3.125 rem;
}

.mgn-top50 {
  margin-top: 3.125 rem;
}

.mgn-rgt50 {
  margin-right: 3.125 rem;
}

.mgn-btm50 {
  margin-bottom: 3.125 rem;
}

.mgn-lft50 {
  margin-left: 3.125 rem;
}

.mgn-51 {
  margin: 3.1875 rem;
}

.mgn-top51 {
  margin-top: 3.1875 rem;
}

.mgn-rgt51 {
  margin-right: 3.1875 rem;
}

.mgn-btm51 {
  margin-bottom: 3.1875 rem;
}

.mgn-lft51 {
  margin-left: 3.1875 rem;
}

.mgn-52 {
  margin: 3.25 rem;
}

.mgn-top52 {
  margin-top: 3.25 rem;
}

.mgn-rgt52 {
  margin-right: 3.25 rem;
}

.mgn-btm52 {
  margin-bottom: 3.25 rem;
}

.mgn-lft52 {
  margin-left: 3.25 rem;
}

.mgn-53 {
  margin: 3.3125 rem;
}

.mgn-top53 {
  margin-top: 3.3125 rem;
}

.mgn-rgt53 {
  margin-right: 3.3125 rem;
}

.mgn-btm53 {
  margin-bottom: 3.3125 rem;
}

.mgn-lft53 {
  margin-left: 3.3125 rem;
}

.mgn-54 {
  margin: 3.375 rem;
}

.mgn-top54 {
  margin-top: 3.375 rem;
}

.mgn-rgt54 {
  margin-right: 3.375 rem;
}

.mgn-btm54 {
  margin-bottom: 3.375 rem;
}

.mgn-lft54 {
  margin-left: 3.375 rem;
}

.mgn-55 {
  margin: 3.4375 rem;
}

.mgn-top55 {
  margin-top: 3.4375 rem;
}

.mgn-rgt55 {
  margin-right: 3.4375 rem;
}

.mgn-btm55 {
  margin-bottom: 3.4375 rem;
}

.mgn-lft55 {
  margin-left: 3.4375 rem;
}

.mgn-56 {
  margin: 3.5 rem;
}

.mgn-top56 {
  margin-top: 3.5 rem;
}

.mgn-rgt56 {
  margin-right: 3.5 rem;
}

.mgn-btm56 {
  margin-bottom: 3.5 rem;
}

.mgn-lft56 {
  margin-left: 3.5 rem;
}

.mgn-57 {
  margin: 3.5625 rem;
}

.mgn-top57 {
  margin-top: 3.5625 rem;
}

.mgn-rgt57 {
  margin-right: 3.5625 rem;
}

.mgn-btm57 {
  margin-bottom: 3.5625 rem;
}

.mgn-lft57 {
  margin-left: 3.5625 rem;
}

.mgn-58 {
  margin: 3.625 rem;
}

.mgn-top58 {
  margin-top: 3.625 rem;
}

.mgn-rgt58 {
  margin-right: 3.625 rem;
}

.mgn-btm58 {
  margin-bottom: 3.625 rem;
}

.mgn-lft58 {
  margin-left: 3.625 rem;
}

.mgn-59 {
  margin: 3.6875 rem;
}

.mgn-top59 {
  margin-top: 3.6875 rem;
}

.mgn-rgt59 {
  margin-right: 3.6875 rem;
}

.mgn-btm59 {
  margin-bottom: 3.6875 rem;
}

.mgn-lft59 {
  margin-left: 3.6875 rem;
}

.mgn-60 {
  margin: 3.75 rem;
}

.mgn-top60 {
  margin-top: 3.75 rem;
}

.mgn-rgt60 {
  margin-right: 3.75 rem;
}

.mgn-btm60 {
  margin-bottom: 3.75 rem;
}

.mgn-lft60 {
  margin-left: 3.75 rem;
}

.mgn-61 {
  margin: 3.8125 rem;
}

.mgn-top61 {
  margin-top: 3.8125 rem;
}

.mgn-rgt61 {
  margin-right: 3.8125 rem;
}

.mgn-btm61 {
  margin-bottom: 3.8125 rem;
}

.mgn-lft61 {
  margin-left: 3.8125 rem;
}

.mgn-62 {
  margin: 3.875 rem;
}

.mgn-top62 {
  margin-top: 3.875 rem;
}

.mgn-rgt62 {
  margin-right: 3.875 rem;
}

.mgn-btm62 {
  margin-bottom: 3.875 rem;
}

.mgn-lft62 {
  margin-left: 3.875 rem;
}

.mgn-63 {
  margin: 3.9375 rem;
}

.mgn-top63 {
  margin-top: 3.9375 rem;
}

.mgn-rgt63 {
  margin-right: 3.9375 rem;
}

.mgn-btm63 {
  margin-bottom: 3.9375 rem;
}

.mgn-lft63 {
  margin-left: 3.9375 rem;
}

.mgn-64 {
  margin: 4 rem;
}

.mgn-top64 {
  margin-top: 4 rem;
}

.mgn-rgt64 {
  margin-right: 4 rem;
}

.mgn-btm64 {
  margin-bottom: 4 rem;
}

.mgn-lft64 {
  margin-left: 4 rem;
}

.mgn-65 {
  margin: 4.0625 rem;
}

.mgn-top65 {
  margin-top: 4.0625 rem;
}

.mgn-rgt65 {
  margin-right: 4.0625 rem;
}

.mgn-btm65 {
  margin-bottom: 4.0625 rem;
}

.mgn-lft65 {
  margin-left: 4.0625 rem;
}

.mgn-66 {
  margin: 4.125 rem;
}

.mgn-top66 {
  margin-top: 4.125 rem;
}

.mgn-rgt66 {
  margin-right: 4.125 rem;
}

.mgn-btm66 {
  margin-bottom: 4.125 rem;
}

.mgn-lft66 {
  margin-left: 4.125 rem;
}

.mgn-67 {
  margin: 4.1875 rem;
}

.mgn-top67 {
  margin-top: 4.1875 rem;
}

.mgn-rgt67 {
  margin-right: 4.1875 rem;
}

.mgn-btm67 {
  margin-bottom: 4.1875 rem;
}

.mgn-lft67 {
  margin-left: 4.1875 rem;
}

.mgn-68 {
  margin: 4.25 rem;
}

.mgn-top68 {
  margin-top: 4.25 rem;
}

.mgn-rgt68 {
  margin-right: 4.25 rem;
}

.mgn-btm68 {
  margin-bottom: 4.25 rem;
}

.mgn-lft68 {
  margin-left: 4.25 rem;
}

.mgn-69 {
  margin: 4.3125 rem;
}

.mgn-top69 {
  margin-top: 4.3125 rem;
}

.mgn-rgt69 {
  margin-right: 4.3125 rem;
}

.mgn-btm69 {
  margin-bottom: 4.3125 rem;
}

.mgn-lft69 {
  margin-left: 4.3125 rem;
}

.mgn-70 {
  margin: 4.375 rem;
}

.mgn-top70 {
  margin-top: 4.375 rem;
}

.mgn-rgt70 {
  margin-right: 4.375 rem;
}

.mgn-btm70 {
  margin-bottom: 4.375 rem;
}

.mgn-lft70 {
  margin-left: 4.375 rem;
}

.mgn-71 {
  margin: 4.4375 rem;
}

.mgn-top71 {
  margin-top: 4.4375 rem;
}

.mgn-rgt71 {
  margin-right: 4.4375 rem;
}

.mgn-btm71 {
  margin-bottom: 4.4375 rem;
}

.mgn-lft71 {
  margin-left: 4.4375 rem;
}

.mgn-72 {
  margin: 4.5 rem;
}

.mgn-top72 {
  margin-top: 4.5 rem;
}

.mgn-rgt72 {
  margin-right: 4.5 rem;
}

.mgn-btm72 {
  margin-bottom: 4.5 rem;
}

.mgn-lft72 {
  margin-left: 4.5 rem;
}

.mgn-73 {
  margin: 4.5625 rem;
}

.mgn-top73 {
  margin-top: 4.5625 rem;
}

.mgn-rgt73 {
  margin-right: 4.5625 rem;
}

.mgn-btm73 {
  margin-bottom: 4.5625 rem;
}

.mgn-lft73 {
  margin-left: 4.5625 rem;
}

.mgn-74 {
  margin: 4.625 rem;
}

.mgn-top74 {
  margin-top: 4.625 rem;
}

.mgn-rgt74 {
  margin-right: 4.625 rem;
}

.mgn-btm74 {
  margin-bottom: 4.625 rem;
}

.mgn-lft74 {
  margin-left: 4.625 rem;
}

.mgn-75 {
  margin: 4.6875 rem;
}

.mgn-top75 {
  margin-top: 4.6875 rem;
}

.mgn-rgt75 {
  margin-right: 4.6875 rem;
}

.mgn-btm75 {
  margin-bottom: 4.6875 rem;
}

.mgn-lft75 {
  margin-left: 4.6875 rem;
}

.mgn-76 {
  margin: 4.75 rem;
}

.mgn-top76 {
  margin-top: 4.75 rem;
}

.mgn-rgt76 {
  margin-right: 4.75 rem;
}

.mgn-btm76 {
  margin-bottom: 4.75 rem;
}

.mgn-lft76 {
  margin-left: 4.75 rem;
}

.mgn-77 {
  margin: 4.8125 rem;
}

.mgn-top77 {
  margin-top: 4.8125 rem;
}

.mgn-rgt77 {
  margin-right: 4.8125 rem;
}

.mgn-btm77 {
  margin-bottom: 4.8125 rem;
}

.mgn-lft77 {
  margin-left: 4.8125 rem;
}

.mgn-78 {
  margin: 4.875 rem;
}

.mgn-top78 {
  margin-top: 4.875 rem;
}

.mgn-rgt78 {
  margin-right: 4.875 rem;
}

.mgn-btm78 {
  margin-bottom: 4.875 rem;
}

.mgn-lft78 {
  margin-left: 4.875 rem;
}

.mgn-79 {
  margin: 4.9375 rem;
}

.mgn-top79 {
  margin-top: 4.9375 rem;
}

.mgn-rgt79 {
  margin-right: 4.9375 rem;
}

.mgn-btm79 {
  margin-bottom: 4.9375 rem;
}

.mgn-lft79 {
  margin-left: 4.9375 rem;
}

.mgn-80 {
  margin: 5 rem;
}

.mgn-top80 {
  margin-top: 5 rem;
}

.mgn-rgt80 {
  margin-right: 5 rem;
}

.mgn-btm80 {
  margin-bottom: 5 rem;
}

.mgn-lft80 {
  margin-left: 5 rem;
}

.mgn-81 {
  margin: 5.0625 rem;
}

.mgn-top81 {
  margin-top: 5.0625 rem;
}

.mgn-rgt81 {
  margin-right: 5.0625 rem;
}

.mgn-btm81 {
  margin-bottom: 5.0625 rem;
}

.mgn-lft81 {
  margin-left: 5.0625 rem;
}

.mgn-82 {
  margin: 5.125 rem;
}

.mgn-top82 {
  margin-top: 5.125 rem;
}

.mgn-rgt82 {
  margin-right: 5.125 rem;
}

.mgn-btm82 {
  margin-bottom: 5.125 rem;
}

.mgn-lft82 {
  margin-left: 5.125 rem;
}

.mgn-83 {
  margin: 5.1875 rem;
}

.mgn-top83 {
  margin-top: 5.1875 rem;
}

.mgn-rgt83 {
  margin-right: 5.1875 rem;
}

.mgn-btm83 {
  margin-bottom: 5.1875 rem;
}

.mgn-lft83 {
  margin-left: 5.1875 rem;
}

.mgn-84 {
  margin: 5.25 rem;
}

.mgn-top84 {
  margin-top: 5.25 rem;
}

.mgn-rgt84 {
  margin-right: 5.25 rem;
}

.mgn-btm84 {
  margin-bottom: 5.25 rem;
}

.mgn-lft84 {
  margin-left: 5.25 rem;
}

.mgn-85 {
  margin: 5.3125 rem;
}

.mgn-top85 {
  margin-top: 5.3125 rem;
}

.mgn-rgt85 {
  margin-right: 5.3125 rem;
}

.mgn-btm85 {
  margin-bottom: 5.3125 rem;
}

.mgn-lft85 {
  margin-left: 5.3125 rem;
}

.mgn-86 {
  margin: 5.375 rem;
}

.mgn-top86 {
  margin-top: 5.375 rem;
}

.mgn-rgt86 {
  margin-right: 5.375 rem;
}

.mgn-btm86 {
  margin-bottom: 5.375 rem;
}

.mgn-lft86 {
  margin-left: 5.375 rem;
}

.mgn-87 {
  margin: 5.4375 rem;
}

.mgn-top87 {
  margin-top: 5.4375 rem;
}

.mgn-rgt87 {
  margin-right: 5.4375 rem;
}

.mgn-btm87 {
  margin-bottom: 5.4375 rem;
}

.mgn-lft87 {
  margin-left: 5.4375 rem;
}

.mgn-88 {
  margin: 5.5 rem;
}

.mgn-top88 {
  margin-top: 5.5 rem;
}

.mgn-rgt88 {
  margin-right: 5.5 rem;
}

.mgn-btm88 {
  margin-bottom: 5.5 rem;
}

.mgn-lft88 {
  margin-left: 5.5 rem;
}

.mgn-89 {
  margin: 5.5625 rem;
}

.mgn-top89 {
  margin-top: 5.5625 rem;
}

.mgn-rgt89 {
  margin-right: 5.5625 rem;
}

.mgn-btm89 {
  margin-bottom: 5.5625 rem;
}

.mgn-lft89 {
  margin-left: 5.5625 rem;
}

.mgn-90 {
  margin: 5.625 rem;
}

.mgn-top90 {
  margin-top: 5.625 rem;
}

.mgn-rgt90 {
  margin-right: 5.625 rem;
}

.mgn-btm90 {
  margin-bottom: 5.625 rem;
}

.mgn-lft90 {
  margin-left: 5.625 rem;
}

.mgn-91 {
  margin: 5.6875 rem;
}

.mgn-top91 {
  margin-top: 5.6875 rem;
}

.mgn-rgt91 {
  margin-right: 5.6875 rem;
}

.mgn-btm91 {
  margin-bottom: 5.6875 rem;
}

.mgn-lft91 {
  margin-left: 5.6875 rem;
}

.mgn-92 {
  margin: 5.75 rem;
}

.mgn-top92 {
  margin-top: 5.75 rem;
}

.mgn-rgt92 {
  margin-right: 5.75 rem;
}

.mgn-btm92 {
  margin-bottom: 5.75 rem;
}

.mgn-lft92 {
  margin-left: 5.75 rem;
}

.mgn-93 {
  margin: 5.8125 rem;
}

.mgn-top93 {
  margin-top: 5.8125 rem;
}

.mgn-rgt93 {
  margin-right: 5.8125 rem;
}

.mgn-btm93 {
  margin-bottom: 5.8125 rem;
}

.mgn-lft93 {
  margin-left: 5.8125 rem;
}

.mgn-94 {
  margin: 5.875 rem;
}

.mgn-top94 {
  margin-top: 5.875 rem;
}

.mgn-rgt94 {
  margin-right: 5.875 rem;
}

.mgn-btm94 {
  margin-bottom: 5.875 rem;
}

.mgn-lft94 {
  margin-left: 5.875 rem;
}

.mgn-95 {
  margin: 5.9375 rem;
}

.mgn-top95 {
  margin-top: 5.9375 rem;
}

.mgn-rgt95 {
  margin-right: 5.9375 rem;
}

.mgn-btm95 {
  margin-bottom: 5.9375 rem;
}

.mgn-lft95 {
  margin-left: 5.9375 rem;
}

.mgn-96 {
  margin: 6 rem;
}

.mgn-top96 {
  margin-top: 6 rem;
}

.mgn-rgt96 {
  margin-right: 6 rem;
}

.mgn-btm96 {
  margin-bottom: 6 rem;
}

.mgn-lft96 {
  margin-left: 6 rem;
}

.mgn-97 {
  margin: 6.0625 rem;
}

.mgn-top97 {
  margin-top: 6.0625 rem;
}

.mgn-rgt97 {
  margin-right: 6.0625 rem;
}

.mgn-btm97 {
  margin-bottom: 6.0625 rem;
}

.mgn-lft97 {
  margin-left: 6.0625 rem;
}

.mgn-98 {
  margin: 6.125 rem;
}

.mgn-top98 {
  margin-top: 6.125 rem;
}

.mgn-rgt98 {
  margin-right: 6.125 rem;
}

.mgn-btm98 {
  margin-bottom: 6.125 rem;
}

.mgn-lft98 {
  margin-left: 6.125 rem;
}

.mgn-99 {
  margin: 6.1875 rem;
}

.mgn-top99 {
  margin-top: 6.1875 rem;
}

.mgn-rgt99 {
  margin-right: 6.1875 rem;
}

.mgn-btm99 {
  margin-bottom: 6.1875 rem;
}

.mgn-lft99 {
  margin-left: 6.1875 rem;
}

.mgn-100 {
  margin: 6.25 rem;
}

.mgn-top100 {
  margin-top: 6.25 rem;
}

.mgn-rgt100 {
  margin-right: 6.25 rem;
}

.mgn-btm100 {
  margin-bottom: 6.25 rem;
}

.mgn-lft100 {
  margin-left: 6.25 rem;
}

.mgn-101 {
  margin: 6.3125 rem;
}

.mgn-top101 {
  margin-top: 6.3125 rem;
}

.mgn-rgt101 {
  margin-right: 6.3125 rem;
}

.mgn-btm101 {
  margin-bottom: 6.3125 rem;
}

.mgn-lft101 {
  margin-left: 6.3125 rem;
}

.mgn-102 {
  margin: 6.375 rem;
}

.mgn-top102 {
  margin-top: 6.375 rem;
}

.mgn-rgt102 {
  margin-right: 6.375 rem;
}

.mgn-btm102 {
  margin-bottom: 6.375 rem;
}

.mgn-lft102 {
  margin-left: 6.375 rem;
}

.mgn-103 {
  margin: 6.4375 rem;
}

.mgn-top103 {
  margin-top: 6.4375 rem;
}

.mgn-rgt103 {
  margin-right: 6.4375 rem;
}

.mgn-btm103 {
  margin-bottom: 6.4375 rem;
}

.mgn-lft103 {
  margin-left: 6.4375 rem;
}

.mgn-104 {
  margin: 6.5 rem;
}

.mgn-top104 {
  margin-top: 6.5 rem;
}

.mgn-rgt104 {
  margin-right: 6.5 rem;
}

.mgn-btm104 {
  margin-bottom: 6.5 rem;
}

.mgn-lft104 {
  margin-left: 6.5 rem;
}

.mgn-105 {
  margin: 6.5625 rem;
}

.mgn-top105 {
  margin-top: 6.5625 rem;
}

.mgn-rgt105 {
  margin-right: 6.5625 rem;
}

.mgn-btm105 {
  margin-bottom: 6.5625 rem;
}

.mgn-lft105 {
  margin-left: 6.5625 rem;
}

.mgn-106 {
  margin: 6.625 rem;
}

.mgn-top106 {
  margin-top: 6.625 rem;
}

.mgn-rgt106 {
  margin-right: 6.625 rem;
}

.mgn-btm106 {
  margin-bottom: 6.625 rem;
}

.mgn-lft106 {
  margin-left: 6.625 rem;
}

.mgn-107 {
  margin: 6.6875 rem;
}

.mgn-top107 {
  margin-top: 6.6875 rem;
}

.mgn-rgt107 {
  margin-right: 6.6875 rem;
}

.mgn-btm107 {
  margin-bottom: 6.6875 rem;
}

.mgn-lft107 {
  margin-left: 6.6875 rem;
}

.mgn-108 {
  margin: 6.75 rem;
}

.mgn-top108 {
  margin-top: 6.75 rem;
}

.mgn-rgt108 {
  margin-right: 6.75 rem;
}

.mgn-btm108 {
  margin-bottom: 6.75 rem;
}

.mgn-lft108 {
  margin-left: 6.75 rem;
}

.mgn-109 {
  margin: 6.8125 rem;
}

.mgn-top109 {
  margin-top: 6.8125 rem;
}

.mgn-rgt109 {
  margin-right: 6.8125 rem;
}

.mgn-btm109 {
  margin-bottom: 6.8125 rem;
}

.mgn-lft109 {
  margin-left: 6.8125 rem;
}

.mgn-110 {
  margin: 6.875 rem;
}

.mgn-top110 {
  margin-top: 6.875 rem;
}

.mgn-rgt110 {
  margin-right: 6.875 rem;
}

.mgn-btm110 {
  margin-bottom: 6.875 rem;
}

.mgn-lft110 {
  margin-left: 6.875 rem;
}

.mgn-111 {
  margin: 6.9375 rem;
}

.mgn-top111 {
  margin-top: 6.9375 rem;
}

.mgn-rgt111 {
  margin-right: 6.9375 rem;
}

.mgn-btm111 {
  margin-bottom: 6.9375 rem;
}

.mgn-lft111 {
  margin-left: 6.9375 rem;
}

.mgn-112 {
  margin: 7 rem;
}

.mgn-top112 {
  margin-top: 7 rem;
}

.mgn-rgt112 {
  margin-right: 7 rem;
}

.mgn-btm112 {
  margin-bottom: 7 rem;
}

.mgn-lft112 {
  margin-left: 7 rem;
}

.mgn-113 {
  margin: 7.0625 rem;
}

.mgn-top113 {
  margin-top: 7.0625 rem;
}

.mgn-rgt113 {
  margin-right: 7.0625 rem;
}

.mgn-btm113 {
  margin-bottom: 7.0625 rem;
}

.mgn-lft113 {
  margin-left: 7.0625 rem;
}

.mgn-114 {
  margin: 7.125 rem;
}

.mgn-top114 {
  margin-top: 7.125 rem;
}

.mgn-rgt114 {
  margin-right: 7.125 rem;
}

.mgn-btm114 {
  margin-bottom: 7.125 rem;
}

.mgn-lft114 {
  margin-left: 7.125 rem;
}

.mgn-115 {
  margin: 7.1875 rem;
}

.mgn-top115 {
  margin-top: 7.1875 rem;
}

.mgn-rgt115 {
  margin-right: 7.1875 rem;
}

.mgn-btm115 {
  margin-bottom: 7.1875 rem;
}

.mgn-lft115 {
  margin-left: 7.1875 rem;
}

.mgn-116 {
  margin: 7.25 rem;
}

.mgn-top116 {
  margin-top: 7.25 rem;
}

.mgn-rgt116 {
  margin-right: 7.25 rem;
}

.mgn-btm116 {
  margin-bottom: 7.25 rem;
}

.mgn-lft116 {
  margin-left: 7.25 rem;
}

.mgn-117 {
  margin: 7.3125 rem;
}

.mgn-top117 {
  margin-top: 7.3125 rem;
}

.mgn-rgt117 {
  margin-right: 7.3125 rem;
}

.mgn-btm117 {
  margin-bottom: 7.3125 rem;
}

.mgn-lft117 {
  margin-left: 7.3125 rem;
}

.mgn-118 {
  margin: 7.375 rem;
}

.mgn-top118 {
  margin-top: 7.375 rem;
}

.mgn-rgt118 {
  margin-right: 7.375 rem;
}

.mgn-btm118 {
  margin-bottom: 7.375 rem;
}

.mgn-lft118 {
  margin-left: 7.375 rem;
}

.mgn-119 {
  margin: 7.4375 rem;
}

.mgn-top119 {
  margin-top: 7.4375 rem;
}

.mgn-rgt119 {
  margin-right: 7.4375 rem;
}

.mgn-btm119 {
  margin-bottom: 7.4375 rem;
}

.mgn-lft119 {
  margin-left: 7.4375 rem;
}

.mgn-120 {
  margin: 7.5 rem;
}

.mgn-top120 {
  margin-top: 7.5 rem;
}

.mgn-rgt120 {
  margin-right: 7.5 rem;
}

.mgn-btm120 {
  margin-bottom: 7.5 rem;
}

.mgn-lft120 {
  margin-left: 7.5 rem;
}

.mgn-121 {
  margin: 7.5625 rem;
}

.mgn-top121 {
  margin-top: 7.5625 rem;
}

.mgn-rgt121 {
  margin-right: 7.5625 rem;
}

.mgn-btm121 {
  margin-bottom: 7.5625 rem;
}

.mgn-lft121 {
  margin-left: 7.5625 rem;
}

.mgn-122 {
  margin: 7.625 rem;
}

.mgn-top122 {
  margin-top: 7.625 rem;
}

.mgn-rgt122 {
  margin-right: 7.625 rem;
}

.mgn-btm122 {
  margin-bottom: 7.625 rem;
}

.mgn-lft122 {
  margin-left: 7.625 rem;
}

.mgn-123 {
  margin: 7.6875 rem;
}

.mgn-top123 {
  margin-top: 7.6875 rem;
}

.mgn-rgt123 {
  margin-right: 7.6875 rem;
}

.mgn-btm123 {
  margin-bottom: 7.6875 rem;
}

.mgn-lft123 {
  margin-left: 7.6875 rem;
}

.mgn-124 {
  margin: 7.75 rem;
}

.mgn-top124 {
  margin-top: 7.75 rem;
}

.mgn-rgt124 {
  margin-right: 7.75 rem;
}

.mgn-btm124 {
  margin-bottom: 7.75 rem;
}

.mgn-lft124 {
  margin-left: 7.75 rem;
}

.mgn-125 {
  margin: 7.8125 rem;
}

.mgn-top125 {
  margin-top: 7.8125 rem;
}

.mgn-rgt125 {
  margin-right: 7.8125 rem;
}

.mgn-btm125 {
  margin-bottom: 7.8125 rem;
}

.mgn-lft125 {
  margin-left: 7.8125 rem;
}

.mgn-126 {
  margin: 7.875 rem;
}

.mgn-top126 {
  margin-top: 7.875 rem;
}

.mgn-rgt126 {
  margin-right: 7.875 rem;
}

.mgn-btm126 {
  margin-bottom: 7.875 rem;
}

.mgn-lft126 {
  margin-left: 7.875 rem;
}

.mgn-127 {
  margin: 7.9375 rem;
}

.mgn-top127 {
  margin-top: 7.9375 rem;
}

.mgn-rgt127 {
  margin-right: 7.9375 rem;
}

.mgn-btm127 {
  margin-bottom: 7.9375 rem;
}

.mgn-lft127 {
  margin-left: 7.9375 rem;
}

.mgn-128 {
  margin: 8 rem;
}

.mgn-top128 {
  margin-top: 8 rem;
}

.mgn-rgt128 {
  margin-right: 8 rem;
}

.mgn-btm128 {
  margin-bottom: 8 rem;
}

.mgn-lft128 {
  margin-left: 8 rem;
}

.mgn-129 {
  margin: 8.0625 rem;
}

.mgn-top129 {
  margin-top: 8.0625 rem;
}

.mgn-rgt129 {
  margin-right: 8.0625 rem;
}

.mgn-btm129 {
  margin-bottom: 8.0625 rem;
}

.mgn-lft129 {
  margin-left: 8.0625 rem;
}

.mgn-130 {
  margin: 8.125 rem;
}

.mgn-top130 {
  margin-top: 8.125 rem;
}

.mgn-rgt130 {
  margin-right: 8.125 rem;
}

.mgn-btm130 {
  margin-bottom: 8.125 rem;
}

.mgn-lft130 {
  margin-left: 8.125 rem;
}

.mgn-131 {
  margin: 8.1875 rem;
}

.mgn-top131 {
  margin-top: 8.1875 rem;
}

.mgn-rgt131 {
  margin-right: 8.1875 rem;
}

.mgn-btm131 {
  margin-bottom: 8.1875 rem;
}

.mgn-lft131 {
  margin-left: 8.1875 rem;
}

.mgn-132 {
  margin: 8.25 rem;
}

.mgn-top132 {
  margin-top: 8.25 rem;
}

.mgn-rgt132 {
  margin-right: 8.25 rem;
}

.mgn-btm132 {
  margin-bottom: 8.25 rem;
}

.mgn-lft132 {
  margin-left: 8.25 rem;
}

.mgn-133 {
  margin: 8.3125 rem;
}

.mgn-top133 {
  margin-top: 8.3125 rem;
}

.mgn-rgt133 {
  margin-right: 8.3125 rem;
}

.mgn-btm133 {
  margin-bottom: 8.3125 rem;
}

.mgn-lft133 {
  margin-left: 8.3125 rem;
}

.mgn-134 {
  margin: 8.375 rem;
}

.mgn-top134 {
  margin-top: 8.375 rem;
}

.mgn-rgt134 {
  margin-right: 8.375 rem;
}

.mgn-btm134 {
  margin-bottom: 8.375 rem;
}

.mgn-lft134 {
  margin-left: 8.375 rem;
}

.mgn-135 {
  margin: 8.4375 rem;
}

.mgn-top135 {
  margin-top: 8.4375 rem;
}

.mgn-rgt135 {
  margin-right: 8.4375 rem;
}

.mgn-btm135 {
  margin-bottom: 8.4375 rem;
}

.mgn-lft135 {
  margin-left: 8.4375 rem;
}

.mgn-136 {
  margin: 8.5 rem;
}

.mgn-top136 {
  margin-top: 8.5 rem;
}

.mgn-rgt136 {
  margin-right: 8.5 rem;
}

.mgn-btm136 {
  margin-bottom: 8.5 rem;
}

.mgn-lft136 {
  margin-left: 8.5 rem;
}

.mgn-137 {
  margin: 8.5625 rem;
}

.mgn-top137 {
  margin-top: 8.5625 rem;
}

.mgn-rgt137 {
  margin-right: 8.5625 rem;
}

.mgn-btm137 {
  margin-bottom: 8.5625 rem;
}

.mgn-lft137 {
  margin-left: 8.5625 rem;
}

.mgn-138 {
  margin: 8.625 rem;
}

.mgn-top138 {
  margin-top: 8.625 rem;
}

.mgn-rgt138 {
  margin-right: 8.625 rem;
}

.mgn-btm138 {
  margin-bottom: 8.625 rem;
}

.mgn-lft138 {
  margin-left: 8.625 rem;
}

.mgn-139 {
  margin: 8.6875 rem;
}

.mgn-top139 {
  margin-top: 8.6875 rem;
}

.mgn-rgt139 {
  margin-right: 8.6875 rem;
}

.mgn-btm139 {
  margin-bottom: 8.6875 rem;
}

.mgn-lft139 {
  margin-left: 8.6875 rem;
}

.mgn-140 {
  margin: 8.75 rem;
}

.mgn-top140 {
  margin-top: 8.75 rem;
}

.mgn-rgt140 {
  margin-right: 8.75 rem;
}

.mgn-btm140 {
  margin-bottom: 8.75 rem;
}

.mgn-lft140 {
  margin-left: 8.75 rem;
}

.mgn-141 {
  margin: 8.8125 rem;
}

.mgn-top141 {
  margin-top: 8.8125 rem;
}

.mgn-rgt141 {
  margin-right: 8.8125 rem;
}

.mgn-btm141 {
  margin-bottom: 8.8125 rem;
}

.mgn-lft141 {
  margin-left: 8.8125 rem;
}

.mgn-142 {
  margin: 8.875 rem;
}

.mgn-top142 {
  margin-top: 8.875 rem;
}

.mgn-rgt142 {
  margin-right: 8.875 rem;
}

.mgn-btm142 {
  margin-bottom: 8.875 rem;
}

.mgn-lft142 {
  margin-left: 8.875 rem;
}

.mgn-143 {
  margin: 8.9375 rem;
}

.mgn-top143 {
  margin-top: 8.9375 rem;
}

.mgn-rgt143 {
  margin-right: 8.9375 rem;
}

.mgn-btm143 {
  margin-bottom: 8.9375 rem;
}

.mgn-lft143 {
  margin-left: 8.9375 rem;
}

.mgn-144 {
  margin: 9 rem;
}

.mgn-top144 {
  margin-top: 9 rem;
}

.mgn-rgt144 {
  margin-right: 9 rem;
}

.mgn-btm144 {
  margin-bottom: 9 rem;
}

.mgn-lft144 {
  margin-left: 9 rem;
}

.mgn-145 {
  margin: 9.0625 rem;
}

.mgn-top145 {
  margin-top: 9.0625 rem;
}

.mgn-rgt145 {
  margin-right: 9.0625 rem;
}

.mgn-btm145 {
  margin-bottom: 9.0625 rem;
}

.mgn-lft145 {
  margin-left: 9.0625 rem;
}

.mgn-146 {
  margin: 9.125 rem;
}

.mgn-top146 {
  margin-top: 9.125 rem;
}

.mgn-rgt146 {
  margin-right: 9.125 rem;
}

.mgn-btm146 {
  margin-bottom: 9.125 rem;
}

.mgn-lft146 {
  margin-left: 9.125 rem;
}

.mgn-147 {
  margin: 9.1875 rem;
}

.mgn-top147 {
  margin-top: 9.1875 rem;
}

.mgn-rgt147 {
  margin-right: 9.1875 rem;
}

.mgn-btm147 {
  margin-bottom: 9.1875 rem;
}

.mgn-lft147 {
  margin-left: 9.1875 rem;
}

.mgn-148 {
  margin: 9.25 rem;
}

.mgn-top148 {
  margin-top: 9.25 rem;
}

.mgn-rgt148 {
  margin-right: 9.25 rem;
}

.mgn-btm148 {
  margin-bottom: 9.25 rem;
}

.mgn-lft148 {
  margin-left: 9.25 rem;
}

.mgn-149 {
  margin: 9.3125 rem;
}

.mgn-top149 {
  margin-top: 9.3125 rem;
}

.mgn-rgt149 {
  margin-right: 9.3125 rem;
}

.mgn-btm149 {
  margin-bottom: 9.3125 rem;
}

.mgn-lft149 {
  margin-left: 9.3125 rem;
}

.mgn-150 {
  margin: 9.375 rem;
}

.mgn-top150 {
  margin-top: 9.375 rem;
}

.mgn-rgt150 {
  margin-right: 9.375 rem;
}

.mgn-btm150 {
  margin-bottom: 9.375 rem;
}

.mgn-lft150 {
  margin-left: 9.375 rem;
}

@media screen and (max-width: 47.9375em) {
  /* --- margin --- 古い・削除*/
  .mgn-btm16 {
    margin-bottom: 8px;
  }
  .mgn-btm24 {
    margin-bottom: 16px;
  }
  .mgn-btm32 {
    margin-bottom: 16px;
  }
  .mgn-btm40 {
    margin-bottom: 24px;
  }
  .mgn-btm48 {
    margin-bottom: 24px;
  }
  .mgn-btm56 {
    margin-bottom: 32px;
  }
  .mgn-btm64 {
    margin-bottom: 32px;
  }
  .mgn-btm72 {
    margin-bottom: 40px;
  }
  .mgn-btm80 {
    margin-bottom: 40px;
  }
}
.mgn-0_em {
  margin: 0em;
}

.mgn-top0_em {
  margin-top: 0em;
}

.mgn-rgt0_em {
  margin-right: 0em;
}

.mgn-btm0_em {
  margin-bottom: 0em;
}

.mgn-lft0_em {
  margin-left: 0em;
}

.mgn-1_em {
  margin: 1em;
}

.mgn-top1_em {
  margin-top: 1em;
}

.mgn-rgt1_em {
  margin-right: 1em;
}

.mgn-btm1_em {
  margin-bottom: 1em;
}

.mgn-lft1_em {
  margin-left: 1em;
}

.mgn-2_em {
  margin: 2em;
}

.mgn-top2_em {
  margin-top: 2em;
}

.mgn-rgt2_em {
  margin-right: 2em;
}

.mgn-btm2_em {
  margin-bottom: 2em;
}

.mgn-lft2_em {
  margin-left: 2em;
}

.mgn-3_em {
  margin: 3em;
}

.mgn-top3_em {
  margin-top: 3em;
}

.mgn-rgt3_em {
  margin-right: 3em;
}

.mgn-btm3_em {
  margin-bottom: 3em;
}

.mgn-lft3_em {
  margin-left: 3em;
}

.mgn-4_em {
  margin: 4em;
}

.mgn-top4_em {
  margin-top: 4em;
}

.mgn-rgt4_em {
  margin-right: 4em;
}

.mgn-btm4_em {
  margin-bottom: 4em;
}

.mgn-lft4_em {
  margin-left: 4em;
}

.mgn-5_em {
  margin: 5em;
}

.mgn-top5_em {
  margin-top: 5em;
}

.mgn-rgt5_em {
  margin-right: 5em;
}

.mgn-btm5_em {
  margin-bottom: 5em;
}

.mgn-lft5_em {
  margin-left: 5em;
}

.mgn-6_em {
  margin: 6em;
}

.mgn-top6_em {
  margin-top: 6em;
}

.mgn-rgt6_em {
  margin-right: 6em;
}

.mgn-btm6_em {
  margin-bottom: 6em;
}

.mgn-lft6_em {
  margin-left: 6em;
}

.mgn-7_em {
  margin: 7em;
}

.mgn-top7_em {
  margin-top: 7em;
}

.mgn-rgt7_em {
  margin-right: 7em;
}

.mgn-btm7_em {
  margin-bottom: 7em;
}

.mgn-lft7_em {
  margin-left: 7em;
}

.mgn-8_em {
  margin: 8em;
}

.mgn-top8_em {
  margin-top: 8em;
}

.mgn-rgt8_em {
  margin-right: 8em;
}

.mgn-btm8_em {
  margin-bottom: 8em;
}

.mgn-lft8_em {
  margin-left: 8em;
}

.mgn-9_em {
  margin: 9em;
}

.mgn-top9_em {
  margin-top: 9em;
}

.mgn-rgt9_em {
  margin-right: 9em;
}

.mgn-btm9_em {
  margin-bottom: 9em;
}

.mgn-lft9_em {
  margin-left: 9em;
}

.mgn-10_em {
  margin: 10em;
}

.mgn-top10_em {
  margin-top: 10em;
}

.mgn-rgt10_em {
  margin-right: 10em;
}

.mgn-btm10_em {
  margin-bottom: 10em;
}

.mgn-lft10_em {
  margin-left: 10em;
}

.mgn-11_em {
  margin: 11em;
}

.mgn-top11_em {
  margin-top: 11em;
}

.mgn-rgt11_em {
  margin-right: 11em;
}

.mgn-btm11_em {
  margin-bottom: 11em;
}

.mgn-lft11_em {
  margin-left: 11em;
}

.mgn-12_em {
  margin: 12em;
}

.mgn-top12_em {
  margin-top: 12em;
}

.mgn-rgt12_em {
  margin-right: 12em;
}

.mgn-btm12_em {
  margin-bottom: 12em;
}

.mgn-lft12_em {
  margin-left: 12em;
}

.mgn-13_em {
  margin: 13em;
}

.mgn-top13_em {
  margin-top: 13em;
}

.mgn-rgt13_em {
  margin-right: 13em;
}

.mgn-btm13_em {
  margin-bottom: 13em;
}

.mgn-lft13_em {
  margin-left: 13em;
}

.mgn-14_em {
  margin: 14em;
}

.mgn-top14_em {
  margin-top: 14em;
}

.mgn-rgt14_em {
  margin-right: 14em;
}

.mgn-btm14_em {
  margin-bottom: 14em;
}

.mgn-lft14_em {
  margin-left: 14em;
}

.mgn-15_em {
  margin: 15em;
}

.mgn-top15_em {
  margin-top: 15em;
}

.mgn-rgt15_em {
  margin-right: 15em;
}

.mgn-btm15_em {
  margin-bottom: 15em;
}

.mgn-lft15_em {
  margin-left: 15em;
}

.pdg-0 {
  padding: 0px;
}

.pdg-top0 {
  padding-top: 0px;
}

.pdg-rgt0 {
  padding-right: 0px;
}

.pdg-btm0 {
  padding-bottom: 0px;
}

.pdg-lft0 {
  padding-left: 0px;
}

.pdg-1 {
  padding: 1px;
}

.pdg-top1 {
  padding-top: 1px;
}

.pdg-rgt1 {
  padding-right: 1px;
}

.pdg-btm1 {
  padding-bottom: 1px;
}

.pdg-lft1 {
  padding-left: 1px;
}

.pdg-2 {
  padding: 2px;
}

.pdg-top2 {
  padding-top: 2px;
}

.pdg-rgt2 {
  padding-right: 2px;
}

.pdg-btm2 {
  padding-bottom: 2px;
}

.pdg-lft2 {
  padding-left: 2px;
}

.pdg-3 {
  padding: 3px;
}

.pdg-top3 {
  padding-top: 3px;
}

.pdg-rgt3 {
  padding-right: 3px;
}

.pdg-btm3 {
  padding-bottom: 3px;
}

.pdg-lft3 {
  padding-left: 3px;
}

.pdg-4 {
  padding: 4px;
}

.pdg-top4 {
  padding-top: 4px;
}

.pdg-rgt4 {
  padding-right: 4px;
}

.pdg-btm4 {
  padding-bottom: 4px;
}

.pdg-lft4 {
  padding-left: 4px;
}

.pdg-5 {
  padding: 5px;
}

.pdg-top5 {
  padding-top: 5px;
}

.pdg-rgt5 {
  padding-right: 5px;
}

.pdg-btm5 {
  padding-bottom: 5px;
}

.pdg-lft5 {
  padding-left: 5px;
}

.pdg-6 {
  padding: 6px;
}

.pdg-top6 {
  padding-top: 6px;
}

.pdg-rgt6 {
  padding-right: 6px;
}

.pdg-btm6 {
  padding-bottom: 6px;
}

.pdg-lft6 {
  padding-left: 6px;
}

.pdg-7 {
  padding: 7px;
}

.pdg-top7 {
  padding-top: 7px;
}

.pdg-rgt7 {
  padding-right: 7px;
}

.pdg-btm7 {
  padding-bottom: 7px;
}

.pdg-lft7 {
  padding-left: 7px;
}

.pdg-8 {
  padding: 8px;
}

.pdg-top8 {
  padding-top: 8px;
}

.pdg-rgt8 {
  padding-right: 8px;
}

.pdg-btm8 {
  padding-bottom: 8px;
}

.pdg-lft8 {
  padding-left: 8px;
}

.pdg-9 {
  padding: 9px;
}

.pdg-top9 {
  padding-top: 9px;
}

.pdg-rgt9 {
  padding-right: 9px;
}

.pdg-btm9 {
  padding-bottom: 9px;
}

.pdg-lft9 {
  padding-left: 9px;
}

.pdg-10 {
  padding: 10px;
}

.pdg-top10 {
  padding-top: 10px;
}

.pdg-rgt10 {
  padding-right: 10px;
}

.pdg-btm10 {
  padding-bottom: 10px;
}

.pdg-lft10 {
  padding-left: 10px;
}

.pdg-11 {
  padding: 11px;
}

.pdg-top11 {
  padding-top: 11px;
}

.pdg-rgt11 {
  padding-right: 11px;
}

.pdg-btm11 {
  padding-bottom: 11px;
}

.pdg-lft11 {
  padding-left: 11px;
}

.pdg-12 {
  padding: 12px;
}

.pdg-top12 {
  padding-top: 12px;
}

.pdg-rgt12 {
  padding-right: 12px;
}

.pdg-btm12 {
  padding-bottom: 12px;
}

.pdg-lft12 {
  padding-left: 12px;
}

.pdg-13 {
  padding: 13px;
}

.pdg-top13 {
  padding-top: 13px;
}

.pdg-rgt13 {
  padding-right: 13px;
}

.pdg-btm13 {
  padding-bottom: 13px;
}

.pdg-lft13 {
  padding-left: 13px;
}

.pdg-14 {
  padding: 14px;
}

.pdg-top14 {
  padding-top: 14px;
}

.pdg-rgt14 {
  padding-right: 14px;
}

.pdg-btm14 {
  padding-bottom: 14px;
}

.pdg-lft14 {
  padding-left: 14px;
}

.pdg-15 {
  padding: 15px;
}

.pdg-top15 {
  padding-top: 15px;
}

.pdg-rgt15 {
  padding-right: 15px;
}

.pdg-btm15 {
  padding-bottom: 15px;
}

.pdg-lft15 {
  padding-left: 15px;
}

.pdg-16 {
  padding: 16px;
}

.pdg-top16 {
  padding-top: 16px;
}

.pdg-rgt16 {
  padding-right: 16px;
}

.pdg-btm16 {
  padding-bottom: 16px;
}

.pdg-lft16 {
  padding-left: 16px;
}

.pdg-17 {
  padding: 17px;
}

.pdg-top17 {
  padding-top: 17px;
}

.pdg-rgt17 {
  padding-right: 17px;
}

.pdg-btm17 {
  padding-bottom: 17px;
}

.pdg-lft17 {
  padding-left: 17px;
}

.pdg-18 {
  padding: 18px;
}

.pdg-top18 {
  padding-top: 18px;
}

.pdg-rgt18 {
  padding-right: 18px;
}

.pdg-btm18 {
  padding-bottom: 18px;
}

.pdg-lft18 {
  padding-left: 18px;
}

.pdg-19 {
  padding: 19px;
}

.pdg-top19 {
  padding-top: 19px;
}

.pdg-rgt19 {
  padding-right: 19px;
}

.pdg-btm19 {
  padding-bottom: 19px;
}

.pdg-lft19 {
  padding-left: 19px;
}

.pdg-20 {
  padding: 20px;
}

.pdg-top20 {
  padding-top: 20px;
}

.pdg-rgt20 {
  padding-right: 20px;
}

.pdg-btm20 {
  padding-bottom: 20px;
}

.pdg-lft20 {
  padding-left: 20px;
}

.pdg-21 {
  padding: 21px;
}

.pdg-top21 {
  padding-top: 21px;
}

.pdg-rgt21 {
  padding-right: 21px;
}

.pdg-btm21 {
  padding-bottom: 21px;
}

.pdg-lft21 {
  padding-left: 21px;
}

.pdg-22 {
  padding: 22px;
}

.pdg-top22 {
  padding-top: 22px;
}

.pdg-rgt22 {
  padding-right: 22px;
}

.pdg-btm22 {
  padding-bottom: 22px;
}

.pdg-lft22 {
  padding-left: 22px;
}

.pdg-23 {
  padding: 23px;
}

.pdg-top23 {
  padding-top: 23px;
}

.pdg-rgt23 {
  padding-right: 23px;
}

.pdg-btm23 {
  padding-bottom: 23px;
}

.pdg-lft23 {
  padding-left: 23px;
}

.pdg-24 {
  padding: 24px;
}

.pdg-top24 {
  padding-top: 24px;
}

.pdg-rgt24 {
  padding-right: 24px;
}

.pdg-btm24 {
  padding-bottom: 24px;
}

.pdg-lft24 {
  padding-left: 24px;
}

.pdg-25 {
  padding: 25px;
}

.pdg-top25 {
  padding-top: 25px;
}

.pdg-rgt25 {
  padding-right: 25px;
}

.pdg-btm25 {
  padding-bottom: 25px;
}

.pdg-lft25 {
  padding-left: 25px;
}

.pdg-26 {
  padding: 26px;
}

.pdg-top26 {
  padding-top: 26px;
}

.pdg-rgt26 {
  padding-right: 26px;
}

.pdg-btm26 {
  padding-bottom: 26px;
}

.pdg-lft26 {
  padding-left: 26px;
}

.pdg-27 {
  padding: 27px;
}

.pdg-top27 {
  padding-top: 27px;
}

.pdg-rgt27 {
  padding-right: 27px;
}

.pdg-btm27 {
  padding-bottom: 27px;
}

.pdg-lft27 {
  padding-left: 27px;
}

.pdg-28 {
  padding: 28px;
}

.pdg-top28 {
  padding-top: 28px;
}

.pdg-rgt28 {
  padding-right: 28px;
}

.pdg-btm28 {
  padding-bottom: 28px;
}

.pdg-lft28 {
  padding-left: 28px;
}

.pdg-29 {
  padding: 29px;
}

.pdg-top29 {
  padding-top: 29px;
}

.pdg-rgt29 {
  padding-right: 29px;
}

.pdg-btm29 {
  padding-bottom: 29px;
}

.pdg-lft29 {
  padding-left: 29px;
}

.pdg-30 {
  padding: 30px;
}

.pdg-top30 {
  padding-top: 30px;
}

.pdg-rgt30 {
  padding-right: 30px;
}

.pdg-btm30 {
  padding-bottom: 30px;
}

.pdg-lft30 {
  padding-left: 30px;
}

.pdg-31 {
  padding: 31px;
}

.pdg-top31 {
  padding-top: 31px;
}

.pdg-rgt31 {
  padding-right: 31px;
}

.pdg-btm31 {
  padding-bottom: 31px;
}

.pdg-lft31 {
  padding-left: 31px;
}

.pdg-32 {
  padding: 32px;
}

.pdg-top32 {
  padding-top: 32px;
}

.pdg-rgt32 {
  padding-right: 32px;
}

.pdg-btm32 {
  padding-bottom: 32px;
}

.pdg-lft32 {
  padding-left: 32px;
}

.pdg-33 {
  padding: 33px;
}

.pdg-top33 {
  padding-top: 33px;
}

.pdg-rgt33 {
  padding-right: 33px;
}

.pdg-btm33 {
  padding-bottom: 33px;
}

.pdg-lft33 {
  padding-left: 33px;
}

.pdg-34 {
  padding: 34px;
}

.pdg-top34 {
  padding-top: 34px;
}

.pdg-rgt34 {
  padding-right: 34px;
}

.pdg-btm34 {
  padding-bottom: 34px;
}

.pdg-lft34 {
  padding-left: 34px;
}

.pdg-35 {
  padding: 35px;
}

.pdg-top35 {
  padding-top: 35px;
}

.pdg-rgt35 {
  padding-right: 35px;
}

.pdg-btm35 {
  padding-bottom: 35px;
}

.pdg-lft35 {
  padding-left: 35px;
}

.pdg-36 {
  padding: 36px;
}

.pdg-top36 {
  padding-top: 36px;
}

.pdg-rgt36 {
  padding-right: 36px;
}

.pdg-btm36 {
  padding-bottom: 36px;
}

.pdg-lft36 {
  padding-left: 36px;
}

.pdg-37 {
  padding: 37px;
}

.pdg-top37 {
  padding-top: 37px;
}

.pdg-rgt37 {
  padding-right: 37px;
}

.pdg-btm37 {
  padding-bottom: 37px;
}

.pdg-lft37 {
  padding-left: 37px;
}

.pdg-38 {
  padding: 38px;
}

.pdg-top38 {
  padding-top: 38px;
}

.pdg-rgt38 {
  padding-right: 38px;
}

.pdg-btm38 {
  padding-bottom: 38px;
}

.pdg-lft38 {
  padding-left: 38px;
}

.pdg-39 {
  padding: 39px;
}

.pdg-top39 {
  padding-top: 39px;
}

.pdg-rgt39 {
  padding-right: 39px;
}

.pdg-btm39 {
  padding-bottom: 39px;
}

.pdg-lft39 {
  padding-left: 39px;
}

.pdg-40 {
  padding: 40px;
}

.pdg-top40 {
  padding-top: 40px;
}

.pdg-rgt40 {
  padding-right: 40px;
}

.pdg-btm40 {
  padding-bottom: 40px;
}

.pdg-lft40 {
  padding-left: 40px;
}

.pdg-41 {
  padding: 41px;
}

.pdg-top41 {
  padding-top: 41px;
}

.pdg-rgt41 {
  padding-right: 41px;
}

.pdg-btm41 {
  padding-bottom: 41px;
}

.pdg-lft41 {
  padding-left: 41px;
}

.pdg-42 {
  padding: 42px;
}

.pdg-top42 {
  padding-top: 42px;
}

.pdg-rgt42 {
  padding-right: 42px;
}

.pdg-btm42 {
  padding-bottom: 42px;
}

.pdg-lft42 {
  padding-left: 42px;
}

.pdg-43 {
  padding: 43px;
}

.pdg-top43 {
  padding-top: 43px;
}

.pdg-rgt43 {
  padding-right: 43px;
}

.pdg-btm43 {
  padding-bottom: 43px;
}

.pdg-lft43 {
  padding-left: 43px;
}

.pdg-44 {
  padding: 44px;
}

.pdg-top44 {
  padding-top: 44px;
}

.pdg-rgt44 {
  padding-right: 44px;
}

.pdg-btm44 {
  padding-bottom: 44px;
}

.pdg-lft44 {
  padding-left: 44px;
}

.pdg-45 {
  padding: 45px;
}

.pdg-top45 {
  padding-top: 45px;
}

.pdg-rgt45 {
  padding-right: 45px;
}

.pdg-btm45 {
  padding-bottom: 45px;
}

.pdg-lft45 {
  padding-left: 45px;
}

.pdg-46 {
  padding: 46px;
}

.pdg-top46 {
  padding-top: 46px;
}

.pdg-rgt46 {
  padding-right: 46px;
}

.pdg-btm46 {
  padding-bottom: 46px;
}

.pdg-lft46 {
  padding-left: 46px;
}

.pdg-47 {
  padding: 47px;
}

.pdg-top47 {
  padding-top: 47px;
}

.pdg-rgt47 {
  padding-right: 47px;
}

.pdg-btm47 {
  padding-bottom: 47px;
}

.pdg-lft47 {
  padding-left: 47px;
}

.pdg-48 {
  padding: 48px;
}

.pdg-top48 {
  padding-top: 48px;
}

.pdg-rgt48 {
  padding-right: 48px;
}

.pdg-btm48 {
  padding-bottom: 48px;
}

.pdg-lft48 {
  padding-left: 48px;
}

.pdg-49 {
  padding: 49px;
}

.pdg-top49 {
  padding-top: 49px;
}

.pdg-rgt49 {
  padding-right: 49px;
}

.pdg-btm49 {
  padding-bottom: 49px;
}

.pdg-lft49 {
  padding-left: 49px;
}

.pdg-50 {
  padding: 50px;
}

.pdg-top50 {
  padding-top: 50px;
}

.pdg-rgt50 {
  padding-right: 50px;
}

.pdg-btm50 {
  padding-bottom: 50px;
}

.pdg-lft50 {
  padding-left: 50px;
}

.pdg-51 {
  padding: 51px;
}

.pdg-top51 {
  padding-top: 51px;
}

.pdg-rgt51 {
  padding-right: 51px;
}

.pdg-btm51 {
  padding-bottom: 51px;
}

.pdg-lft51 {
  padding-left: 51px;
}

.pdg-52 {
  padding: 52px;
}

.pdg-top52 {
  padding-top: 52px;
}

.pdg-rgt52 {
  padding-right: 52px;
}

.pdg-btm52 {
  padding-bottom: 52px;
}

.pdg-lft52 {
  padding-left: 52px;
}

.pdg-53 {
  padding: 53px;
}

.pdg-top53 {
  padding-top: 53px;
}

.pdg-rgt53 {
  padding-right: 53px;
}

.pdg-btm53 {
  padding-bottom: 53px;
}

.pdg-lft53 {
  padding-left: 53px;
}

.pdg-54 {
  padding: 54px;
}

.pdg-top54 {
  padding-top: 54px;
}

.pdg-rgt54 {
  padding-right: 54px;
}

.pdg-btm54 {
  padding-bottom: 54px;
}

.pdg-lft54 {
  padding-left: 54px;
}

.pdg-55 {
  padding: 55px;
}

.pdg-top55 {
  padding-top: 55px;
}

.pdg-rgt55 {
  padding-right: 55px;
}

.pdg-btm55 {
  padding-bottom: 55px;
}

.pdg-lft55 {
  padding-left: 55px;
}

.pdg-56 {
  padding: 56px;
}

.pdg-top56 {
  padding-top: 56px;
}

.pdg-rgt56 {
  padding-right: 56px;
}

.pdg-btm56 {
  padding-bottom: 56px;
}

.pdg-lft56 {
  padding-left: 56px;
}

.pdg-57 {
  padding: 57px;
}

.pdg-top57 {
  padding-top: 57px;
}

.pdg-rgt57 {
  padding-right: 57px;
}

.pdg-btm57 {
  padding-bottom: 57px;
}

.pdg-lft57 {
  padding-left: 57px;
}

.pdg-58 {
  padding: 58px;
}

.pdg-top58 {
  padding-top: 58px;
}

.pdg-rgt58 {
  padding-right: 58px;
}

.pdg-btm58 {
  padding-bottom: 58px;
}

.pdg-lft58 {
  padding-left: 58px;
}

.pdg-59 {
  padding: 59px;
}

.pdg-top59 {
  padding-top: 59px;
}

.pdg-rgt59 {
  padding-right: 59px;
}

.pdg-btm59 {
  padding-bottom: 59px;
}

.pdg-lft59 {
  padding-left: 59px;
}

.pdg-60 {
  padding: 60px;
}

.pdg-top60 {
  padding-top: 60px;
}

.pdg-rgt60 {
  padding-right: 60px;
}

.pdg-btm60 {
  padding-bottom: 60px;
}

.pdg-lft60 {
  padding-left: 60px;
}

.pdg-61 {
  padding: 61px;
}

.pdg-top61 {
  padding-top: 61px;
}

.pdg-rgt61 {
  padding-right: 61px;
}

.pdg-btm61 {
  padding-bottom: 61px;
}

.pdg-lft61 {
  padding-left: 61px;
}

.pdg-62 {
  padding: 62px;
}

.pdg-top62 {
  padding-top: 62px;
}

.pdg-rgt62 {
  padding-right: 62px;
}

.pdg-btm62 {
  padding-bottom: 62px;
}

.pdg-lft62 {
  padding-left: 62px;
}

.pdg-63 {
  padding: 63px;
}

.pdg-top63 {
  padding-top: 63px;
}

.pdg-rgt63 {
  padding-right: 63px;
}

.pdg-btm63 {
  padding-bottom: 63px;
}

.pdg-lft63 {
  padding-left: 63px;
}

.pdg-64 {
  padding: 64px;
}

.pdg-top64 {
  padding-top: 64px;
}

.pdg-rgt64 {
  padding-right: 64px;
}

.pdg-btm64 {
  padding-bottom: 64px;
}

.pdg-lft64 {
  padding-left: 64px;
}

.pdg-65 {
  padding: 65px;
}

.pdg-top65 {
  padding-top: 65px;
}

.pdg-rgt65 {
  padding-right: 65px;
}

.pdg-btm65 {
  padding-bottom: 65px;
}

.pdg-lft65 {
  padding-left: 65px;
}

.pdg-66 {
  padding: 66px;
}

.pdg-top66 {
  padding-top: 66px;
}

.pdg-rgt66 {
  padding-right: 66px;
}

.pdg-btm66 {
  padding-bottom: 66px;
}

.pdg-lft66 {
  padding-left: 66px;
}

.pdg-67 {
  padding: 67px;
}

.pdg-top67 {
  padding-top: 67px;
}

.pdg-rgt67 {
  padding-right: 67px;
}

.pdg-btm67 {
  padding-bottom: 67px;
}

.pdg-lft67 {
  padding-left: 67px;
}

.pdg-68 {
  padding: 68px;
}

.pdg-top68 {
  padding-top: 68px;
}

.pdg-rgt68 {
  padding-right: 68px;
}

.pdg-btm68 {
  padding-bottom: 68px;
}

.pdg-lft68 {
  padding-left: 68px;
}

.pdg-69 {
  padding: 69px;
}

.pdg-top69 {
  padding-top: 69px;
}

.pdg-rgt69 {
  padding-right: 69px;
}

.pdg-btm69 {
  padding-bottom: 69px;
}

.pdg-lft69 {
  padding-left: 69px;
}

.pdg-70 {
  padding: 70px;
}

.pdg-top70 {
  padding-top: 70px;
}

.pdg-rgt70 {
  padding-right: 70px;
}

.pdg-btm70 {
  padding-bottom: 70px;
}

.pdg-lft70 {
  padding-left: 70px;
}

.pdg-71 {
  padding: 71px;
}

.pdg-top71 {
  padding-top: 71px;
}

.pdg-rgt71 {
  padding-right: 71px;
}

.pdg-btm71 {
  padding-bottom: 71px;
}

.pdg-lft71 {
  padding-left: 71px;
}

.pdg-72 {
  padding: 72px;
}

.pdg-top72 {
  padding-top: 72px;
}

.pdg-rgt72 {
  padding-right: 72px;
}

.pdg-btm72 {
  padding-bottom: 72px;
}

.pdg-lft72 {
  padding-left: 72px;
}

.pdg-73 {
  padding: 73px;
}

.pdg-top73 {
  padding-top: 73px;
}

.pdg-rgt73 {
  padding-right: 73px;
}

.pdg-btm73 {
  padding-bottom: 73px;
}

.pdg-lft73 {
  padding-left: 73px;
}

.pdg-74 {
  padding: 74px;
}

.pdg-top74 {
  padding-top: 74px;
}

.pdg-rgt74 {
  padding-right: 74px;
}

.pdg-btm74 {
  padding-bottom: 74px;
}

.pdg-lft74 {
  padding-left: 74px;
}

.pdg-75 {
  padding: 75px;
}

.pdg-top75 {
  padding-top: 75px;
}

.pdg-rgt75 {
  padding-right: 75px;
}

.pdg-btm75 {
  padding-bottom: 75px;
}

.pdg-lft75 {
  padding-left: 75px;
}

.pdg-76 {
  padding: 76px;
}

.pdg-top76 {
  padding-top: 76px;
}

.pdg-rgt76 {
  padding-right: 76px;
}

.pdg-btm76 {
  padding-bottom: 76px;
}

.pdg-lft76 {
  padding-left: 76px;
}

.pdg-77 {
  padding: 77px;
}

.pdg-top77 {
  padding-top: 77px;
}

.pdg-rgt77 {
  padding-right: 77px;
}

.pdg-btm77 {
  padding-bottom: 77px;
}

.pdg-lft77 {
  padding-left: 77px;
}

.pdg-78 {
  padding: 78px;
}

.pdg-top78 {
  padding-top: 78px;
}

.pdg-rgt78 {
  padding-right: 78px;
}

.pdg-btm78 {
  padding-bottom: 78px;
}

.pdg-lft78 {
  padding-left: 78px;
}

.pdg-79 {
  padding: 79px;
}

.pdg-top79 {
  padding-top: 79px;
}

.pdg-rgt79 {
  padding-right: 79px;
}

.pdg-btm79 {
  padding-bottom: 79px;
}

.pdg-lft79 {
  padding-left: 79px;
}

.pdg-80 {
  padding: 80px;
}

.pdg-top80 {
  padding-top: 80px;
}

.pdg-rgt80 {
  padding-right: 80px;
}

.pdg-btm80 {
  padding-bottom: 80px;
}

.pdg-lft80 {
  padding-left: 80px;
}

.pdg-81 {
  padding: 81px;
}

.pdg-top81 {
  padding-top: 81px;
}

.pdg-rgt81 {
  padding-right: 81px;
}

.pdg-btm81 {
  padding-bottom: 81px;
}

.pdg-lft81 {
  padding-left: 81px;
}

.pdg-82 {
  padding: 82px;
}

.pdg-top82 {
  padding-top: 82px;
}

.pdg-rgt82 {
  padding-right: 82px;
}

.pdg-btm82 {
  padding-bottom: 82px;
}

.pdg-lft82 {
  padding-left: 82px;
}

.pdg-83 {
  padding: 83px;
}

.pdg-top83 {
  padding-top: 83px;
}

.pdg-rgt83 {
  padding-right: 83px;
}

.pdg-btm83 {
  padding-bottom: 83px;
}

.pdg-lft83 {
  padding-left: 83px;
}

.pdg-84 {
  padding: 84px;
}

.pdg-top84 {
  padding-top: 84px;
}

.pdg-rgt84 {
  padding-right: 84px;
}

.pdg-btm84 {
  padding-bottom: 84px;
}

.pdg-lft84 {
  padding-left: 84px;
}

.pdg-85 {
  padding: 85px;
}

.pdg-top85 {
  padding-top: 85px;
}

.pdg-rgt85 {
  padding-right: 85px;
}

.pdg-btm85 {
  padding-bottom: 85px;
}

.pdg-lft85 {
  padding-left: 85px;
}

.pdg-86 {
  padding: 86px;
}

.pdg-top86 {
  padding-top: 86px;
}

.pdg-rgt86 {
  padding-right: 86px;
}

.pdg-btm86 {
  padding-bottom: 86px;
}

.pdg-lft86 {
  padding-left: 86px;
}

.pdg-87 {
  padding: 87px;
}

.pdg-top87 {
  padding-top: 87px;
}

.pdg-rgt87 {
  padding-right: 87px;
}

.pdg-btm87 {
  padding-bottom: 87px;
}

.pdg-lft87 {
  padding-left: 87px;
}

.pdg-88 {
  padding: 88px;
}

.pdg-top88 {
  padding-top: 88px;
}

.pdg-rgt88 {
  padding-right: 88px;
}

.pdg-btm88 {
  padding-bottom: 88px;
}

.pdg-lft88 {
  padding-left: 88px;
}

.pdg-89 {
  padding: 89px;
}

.pdg-top89 {
  padding-top: 89px;
}

.pdg-rgt89 {
  padding-right: 89px;
}

.pdg-btm89 {
  padding-bottom: 89px;
}

.pdg-lft89 {
  padding-left: 89px;
}

.pdg-90 {
  padding: 90px;
}

.pdg-top90 {
  padding-top: 90px;
}

.pdg-rgt90 {
  padding-right: 90px;
}

.pdg-btm90 {
  padding-bottom: 90px;
}

.pdg-lft90 {
  padding-left: 90px;
}

.pdg-91 {
  padding: 91px;
}

.pdg-top91 {
  padding-top: 91px;
}

.pdg-rgt91 {
  padding-right: 91px;
}

.pdg-btm91 {
  padding-bottom: 91px;
}

.pdg-lft91 {
  padding-left: 91px;
}

.pdg-92 {
  padding: 92px;
}

.pdg-top92 {
  padding-top: 92px;
}

.pdg-rgt92 {
  padding-right: 92px;
}

.pdg-btm92 {
  padding-bottom: 92px;
}

.pdg-lft92 {
  padding-left: 92px;
}

.pdg-93 {
  padding: 93px;
}

.pdg-top93 {
  padding-top: 93px;
}

.pdg-rgt93 {
  padding-right: 93px;
}

.pdg-btm93 {
  padding-bottom: 93px;
}

.pdg-lft93 {
  padding-left: 93px;
}

.pdg-94 {
  padding: 94px;
}

.pdg-top94 {
  padding-top: 94px;
}

.pdg-rgt94 {
  padding-right: 94px;
}

.pdg-btm94 {
  padding-bottom: 94px;
}

.pdg-lft94 {
  padding-left: 94px;
}

.pdg-95 {
  padding: 95px;
}

.pdg-top95 {
  padding-top: 95px;
}

.pdg-rgt95 {
  padding-right: 95px;
}

.pdg-btm95 {
  padding-bottom: 95px;
}

.pdg-lft95 {
  padding-left: 95px;
}

.pdg-96 {
  padding: 96px;
}

.pdg-top96 {
  padding-top: 96px;
}

.pdg-rgt96 {
  padding-right: 96px;
}

.pdg-btm96 {
  padding-bottom: 96px;
}

.pdg-lft96 {
  padding-left: 96px;
}

.pdg-97 {
  padding: 97px;
}

.pdg-top97 {
  padding-top: 97px;
}

.pdg-rgt97 {
  padding-right: 97px;
}

.pdg-btm97 {
  padding-bottom: 97px;
}

.pdg-lft97 {
  padding-left: 97px;
}

.pdg-98 {
  padding: 98px;
}

.pdg-top98 {
  padding-top: 98px;
}

.pdg-rgt98 {
  padding-right: 98px;
}

.pdg-btm98 {
  padding-bottom: 98px;
}

.pdg-lft98 {
  padding-left: 98px;
}

.pdg-99 {
  padding: 99px;
}

.pdg-top99 {
  padding-top: 99px;
}

.pdg-rgt99 {
  padding-right: 99px;
}

.pdg-btm99 {
  padding-bottom: 99px;
}

.pdg-lft99 {
  padding-left: 99px;
}

.pdg-100 {
  padding: 100px;
}

.pdg-top100 {
  padding-top: 100px;
}

.pdg-rgt100 {
  padding-right: 100px;
}

.pdg-btm100 {
  padding-bottom: 100px;
}

.pdg-lft100 {
  padding-left: 100px;
}

.pdg-101 {
  padding: 101px;
}

.pdg-top101 {
  padding-top: 101px;
}

.pdg-rgt101 {
  padding-right: 101px;
}

.pdg-btm101 {
  padding-bottom: 101px;
}

.pdg-lft101 {
  padding-left: 101px;
}

.pdg-102 {
  padding: 102px;
}

.pdg-top102 {
  padding-top: 102px;
}

.pdg-rgt102 {
  padding-right: 102px;
}

.pdg-btm102 {
  padding-bottom: 102px;
}

.pdg-lft102 {
  padding-left: 102px;
}

.pdg-103 {
  padding: 103px;
}

.pdg-top103 {
  padding-top: 103px;
}

.pdg-rgt103 {
  padding-right: 103px;
}

.pdg-btm103 {
  padding-bottom: 103px;
}

.pdg-lft103 {
  padding-left: 103px;
}

.pdg-104 {
  padding: 104px;
}

.pdg-top104 {
  padding-top: 104px;
}

.pdg-rgt104 {
  padding-right: 104px;
}

.pdg-btm104 {
  padding-bottom: 104px;
}

.pdg-lft104 {
  padding-left: 104px;
}

.pdg-105 {
  padding: 105px;
}

.pdg-top105 {
  padding-top: 105px;
}

.pdg-rgt105 {
  padding-right: 105px;
}

.pdg-btm105 {
  padding-bottom: 105px;
}

.pdg-lft105 {
  padding-left: 105px;
}

.pdg-106 {
  padding: 106px;
}

.pdg-top106 {
  padding-top: 106px;
}

.pdg-rgt106 {
  padding-right: 106px;
}

.pdg-btm106 {
  padding-bottom: 106px;
}

.pdg-lft106 {
  padding-left: 106px;
}

.pdg-107 {
  padding: 107px;
}

.pdg-top107 {
  padding-top: 107px;
}

.pdg-rgt107 {
  padding-right: 107px;
}

.pdg-btm107 {
  padding-bottom: 107px;
}

.pdg-lft107 {
  padding-left: 107px;
}

.pdg-108 {
  padding: 108px;
}

.pdg-top108 {
  padding-top: 108px;
}

.pdg-rgt108 {
  padding-right: 108px;
}

.pdg-btm108 {
  padding-bottom: 108px;
}

.pdg-lft108 {
  padding-left: 108px;
}

.pdg-109 {
  padding: 109px;
}

.pdg-top109 {
  padding-top: 109px;
}

.pdg-rgt109 {
  padding-right: 109px;
}

.pdg-btm109 {
  padding-bottom: 109px;
}

.pdg-lft109 {
  padding-left: 109px;
}

.pdg-110 {
  padding: 110px;
}

.pdg-top110 {
  padding-top: 110px;
}

.pdg-rgt110 {
  padding-right: 110px;
}

.pdg-btm110 {
  padding-bottom: 110px;
}

.pdg-lft110 {
  padding-left: 110px;
}

.pdg-111 {
  padding: 111px;
}

.pdg-top111 {
  padding-top: 111px;
}

.pdg-rgt111 {
  padding-right: 111px;
}

.pdg-btm111 {
  padding-bottom: 111px;
}

.pdg-lft111 {
  padding-left: 111px;
}

.pdg-112 {
  padding: 112px;
}

.pdg-top112 {
  padding-top: 112px;
}

.pdg-rgt112 {
  padding-right: 112px;
}

.pdg-btm112 {
  padding-bottom: 112px;
}

.pdg-lft112 {
  padding-left: 112px;
}

.pdg-113 {
  padding: 113px;
}

.pdg-top113 {
  padding-top: 113px;
}

.pdg-rgt113 {
  padding-right: 113px;
}

.pdg-btm113 {
  padding-bottom: 113px;
}

.pdg-lft113 {
  padding-left: 113px;
}

.pdg-114 {
  padding: 114px;
}

.pdg-top114 {
  padding-top: 114px;
}

.pdg-rgt114 {
  padding-right: 114px;
}

.pdg-btm114 {
  padding-bottom: 114px;
}

.pdg-lft114 {
  padding-left: 114px;
}

.pdg-115 {
  padding: 115px;
}

.pdg-top115 {
  padding-top: 115px;
}

.pdg-rgt115 {
  padding-right: 115px;
}

.pdg-btm115 {
  padding-bottom: 115px;
}

.pdg-lft115 {
  padding-left: 115px;
}

.pdg-116 {
  padding: 116px;
}

.pdg-top116 {
  padding-top: 116px;
}

.pdg-rgt116 {
  padding-right: 116px;
}

.pdg-btm116 {
  padding-bottom: 116px;
}

.pdg-lft116 {
  padding-left: 116px;
}

.pdg-117 {
  padding: 117px;
}

.pdg-top117 {
  padding-top: 117px;
}

.pdg-rgt117 {
  padding-right: 117px;
}

.pdg-btm117 {
  padding-bottom: 117px;
}

.pdg-lft117 {
  padding-left: 117px;
}

.pdg-118 {
  padding: 118px;
}

.pdg-top118 {
  padding-top: 118px;
}

.pdg-rgt118 {
  padding-right: 118px;
}

.pdg-btm118 {
  padding-bottom: 118px;
}

.pdg-lft118 {
  padding-left: 118px;
}

.pdg-119 {
  padding: 119px;
}

.pdg-top119 {
  padding-top: 119px;
}

.pdg-rgt119 {
  padding-right: 119px;
}

.pdg-btm119 {
  padding-bottom: 119px;
}

.pdg-lft119 {
  padding-left: 119px;
}

.pdg-120 {
  padding: 120px;
}

.pdg-top120 {
  padding-top: 120px;
}

.pdg-rgt120 {
  padding-right: 120px;
}

.pdg-btm120 {
  padding-bottom: 120px;
}

.pdg-lft120 {
  padding-left: 120px;
}

.pdg-121 {
  padding: 121px;
}

.pdg-top121 {
  padding-top: 121px;
}

.pdg-rgt121 {
  padding-right: 121px;
}

.pdg-btm121 {
  padding-bottom: 121px;
}

.pdg-lft121 {
  padding-left: 121px;
}

.pdg-122 {
  padding: 122px;
}

.pdg-top122 {
  padding-top: 122px;
}

.pdg-rgt122 {
  padding-right: 122px;
}

.pdg-btm122 {
  padding-bottom: 122px;
}

.pdg-lft122 {
  padding-left: 122px;
}

.pdg-123 {
  padding: 123px;
}

.pdg-top123 {
  padding-top: 123px;
}

.pdg-rgt123 {
  padding-right: 123px;
}

.pdg-btm123 {
  padding-bottom: 123px;
}

.pdg-lft123 {
  padding-left: 123px;
}

.pdg-124 {
  padding: 124px;
}

.pdg-top124 {
  padding-top: 124px;
}

.pdg-rgt124 {
  padding-right: 124px;
}

.pdg-btm124 {
  padding-bottom: 124px;
}

.pdg-lft124 {
  padding-left: 124px;
}

.pdg-125 {
  padding: 125px;
}

.pdg-top125 {
  padding-top: 125px;
}

.pdg-rgt125 {
  padding-right: 125px;
}

.pdg-btm125 {
  padding-bottom: 125px;
}

.pdg-lft125 {
  padding-left: 125px;
}

.pdg-126 {
  padding: 126px;
}

.pdg-top126 {
  padding-top: 126px;
}

.pdg-rgt126 {
  padding-right: 126px;
}

.pdg-btm126 {
  padding-bottom: 126px;
}

.pdg-lft126 {
  padding-left: 126px;
}

.pdg-127 {
  padding: 127px;
}

.pdg-top127 {
  padding-top: 127px;
}

.pdg-rgt127 {
  padding-right: 127px;
}

.pdg-btm127 {
  padding-bottom: 127px;
}

.pdg-lft127 {
  padding-left: 127px;
}

.pdg-128 {
  padding: 128px;
}

.pdg-top128 {
  padding-top: 128px;
}

.pdg-rgt128 {
  padding-right: 128px;
}

.pdg-btm128 {
  padding-bottom: 128px;
}

.pdg-lft128 {
  padding-left: 128px;
}

.pdg-129 {
  padding: 129px;
}

.pdg-top129 {
  padding-top: 129px;
}

.pdg-rgt129 {
  padding-right: 129px;
}

.pdg-btm129 {
  padding-bottom: 129px;
}

.pdg-lft129 {
  padding-left: 129px;
}

.pdg-130 {
  padding: 130px;
}

.pdg-top130 {
  padding-top: 130px;
}

.pdg-rgt130 {
  padding-right: 130px;
}

.pdg-btm130 {
  padding-bottom: 130px;
}

.pdg-lft130 {
  padding-left: 130px;
}

.pdg-131 {
  padding: 131px;
}

.pdg-top131 {
  padding-top: 131px;
}

.pdg-rgt131 {
  padding-right: 131px;
}

.pdg-btm131 {
  padding-bottom: 131px;
}

.pdg-lft131 {
  padding-left: 131px;
}

.pdg-132 {
  padding: 132px;
}

.pdg-top132 {
  padding-top: 132px;
}

.pdg-rgt132 {
  padding-right: 132px;
}

.pdg-btm132 {
  padding-bottom: 132px;
}

.pdg-lft132 {
  padding-left: 132px;
}

.pdg-133 {
  padding: 133px;
}

.pdg-top133 {
  padding-top: 133px;
}

.pdg-rgt133 {
  padding-right: 133px;
}

.pdg-btm133 {
  padding-bottom: 133px;
}

.pdg-lft133 {
  padding-left: 133px;
}

.pdg-134 {
  padding: 134px;
}

.pdg-top134 {
  padding-top: 134px;
}

.pdg-rgt134 {
  padding-right: 134px;
}

.pdg-btm134 {
  padding-bottom: 134px;
}

.pdg-lft134 {
  padding-left: 134px;
}

.pdg-135 {
  padding: 135px;
}

.pdg-top135 {
  padding-top: 135px;
}

.pdg-rgt135 {
  padding-right: 135px;
}

.pdg-btm135 {
  padding-bottom: 135px;
}

.pdg-lft135 {
  padding-left: 135px;
}

.pdg-136 {
  padding: 136px;
}

.pdg-top136 {
  padding-top: 136px;
}

.pdg-rgt136 {
  padding-right: 136px;
}

.pdg-btm136 {
  padding-bottom: 136px;
}

.pdg-lft136 {
  padding-left: 136px;
}

.pdg-137 {
  padding: 137px;
}

.pdg-top137 {
  padding-top: 137px;
}

.pdg-rgt137 {
  padding-right: 137px;
}

.pdg-btm137 {
  padding-bottom: 137px;
}

.pdg-lft137 {
  padding-left: 137px;
}

.pdg-138 {
  padding: 138px;
}

.pdg-top138 {
  padding-top: 138px;
}

.pdg-rgt138 {
  padding-right: 138px;
}

.pdg-btm138 {
  padding-bottom: 138px;
}

.pdg-lft138 {
  padding-left: 138px;
}

.pdg-139 {
  padding: 139px;
}

.pdg-top139 {
  padding-top: 139px;
}

.pdg-rgt139 {
  padding-right: 139px;
}

.pdg-btm139 {
  padding-bottom: 139px;
}

.pdg-lft139 {
  padding-left: 139px;
}

.pdg-140 {
  padding: 140px;
}

.pdg-top140 {
  padding-top: 140px;
}

.pdg-rgt140 {
  padding-right: 140px;
}

.pdg-btm140 {
  padding-bottom: 140px;
}

.pdg-lft140 {
  padding-left: 140px;
}

.pdg-141 {
  padding: 141px;
}

.pdg-top141 {
  padding-top: 141px;
}

.pdg-rgt141 {
  padding-right: 141px;
}

.pdg-btm141 {
  padding-bottom: 141px;
}

.pdg-lft141 {
  padding-left: 141px;
}

.pdg-142 {
  padding: 142px;
}

.pdg-top142 {
  padding-top: 142px;
}

.pdg-rgt142 {
  padding-right: 142px;
}

.pdg-btm142 {
  padding-bottom: 142px;
}

.pdg-lft142 {
  padding-left: 142px;
}

.pdg-143 {
  padding: 143px;
}

.pdg-top143 {
  padding-top: 143px;
}

.pdg-rgt143 {
  padding-right: 143px;
}

.pdg-btm143 {
  padding-bottom: 143px;
}

.pdg-lft143 {
  padding-left: 143px;
}

.pdg-144 {
  padding: 144px;
}

.pdg-top144 {
  padding-top: 144px;
}

.pdg-rgt144 {
  padding-right: 144px;
}

.pdg-btm144 {
  padding-bottom: 144px;
}

.pdg-lft144 {
  padding-left: 144px;
}

.pdg-145 {
  padding: 145px;
}

.pdg-top145 {
  padding-top: 145px;
}

.pdg-rgt145 {
  padding-right: 145px;
}

.pdg-btm145 {
  padding-bottom: 145px;
}

.pdg-lft145 {
  padding-left: 145px;
}

.pdg-146 {
  padding: 146px;
}

.pdg-top146 {
  padding-top: 146px;
}

.pdg-rgt146 {
  padding-right: 146px;
}

.pdg-btm146 {
  padding-bottom: 146px;
}

.pdg-lft146 {
  padding-left: 146px;
}

.pdg-147 {
  padding: 147px;
}

.pdg-top147 {
  padding-top: 147px;
}

.pdg-rgt147 {
  padding-right: 147px;
}

.pdg-btm147 {
  padding-bottom: 147px;
}

.pdg-lft147 {
  padding-left: 147px;
}

.pdg-148 {
  padding: 148px;
}

.pdg-top148 {
  padding-top: 148px;
}

.pdg-rgt148 {
  padding-right: 148px;
}

.pdg-btm148 {
  padding-bottom: 148px;
}

.pdg-lft148 {
  padding-left: 148px;
}

.pdg-149 {
  padding: 149px;
}

.pdg-top149 {
  padding-top: 149px;
}

.pdg-rgt149 {
  padding-right: 149px;
}

.pdg-btm149 {
  padding-bottom: 149px;
}

.pdg-lft149 {
  padding-left: 149px;
}

.pdg-150 {
  padding: 150px;
}

.pdg-top150 {
  padding-top: 150px;
}

.pdg-rgt150 {
  padding-right: 150px;
}

.pdg-btm150 {
  padding-bottom: 150px;
}

.pdg-lft150 {
  padding-left: 150px;
}

/* --- position --- */
.pos_rel {
  position: relative;
}

.pos_ab {
  position: absolute;
}

/* --- float --- */
.flt-rgt {
  float: right;
}

.flt-lft {
  float: left;
}

/* breadcrumbs
**************************************** */
.breadcrumbs {
  padding: 16px 0;
}

.breadcrumbs a {
  color: var(--clr-main, #1d7fb7);
  text-decoration: underline;
}

.breadcrumbs a:hover {
  text-decoration: none;
}

.breadcrumbs .breadcrumb_last {
  display: table-cell;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}

.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: 26px 36px;
  border-radius: 8px;
  width: fit-content;
  min-width: min(288px, 100%);
  line-height: 1.625;
  letter-spacing: 0.05em;
  font-family: var(--font-jp);
  text-align: center;
  background-color: var(--btn-bg-main, #f5c246);
  border: 1px solid var(--btn-bg-main, #f5c246);
}
.btn a[href$=".pdf"] {
  padding: 27px 52px;
}
@media screen and (max-width: 47.9375em) {
  .btn a {
    min-width: 100%;
    padding: 20px 36px;
  }
}
.btn a:where(:not([target=_blank]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before {
  content: "";
  position: absolute;
  top: calc(50% - 4px);
  right: 16px;
  display: block;
  width: 0.5rem;
  height: 0.75rem;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  background-color: #63502f;
  transition: var(--transit-default, all 0.4s ease-out);
}
.btn a:hover {
  background-color: #fff;
}

.flex > .btn a {
  min-width: 100%;
}

.btn.txt-ctr a {
  margin-left: auto;
  margin-right: auto;
}

.btn a svg {
  fill: var(--btn-clr-main, #fff);
}
.btn a:hover svg {
  fill: #63502f;
}

.btn.btn-next_page a {
  min-width: 100%;
  text-align: left;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #333);
  background-color: var(--btn-bg-wht, #fff);
  border: inherit;
}
.btn-wh a:hover {
  color: #fff;
  background-color: #f5c246;
}
.btn-wh a:hover:not([target=_blank]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])::before {
  background-color: #fff;
}

.btn-wh svg {
  fill: var(--btn-clr-wht, #333);
}

.btn-wh a:hover svg {
  fill: var(--btn-clr-wht-hvr, #fff);
}

@media screen and (max-width: 47.9375em) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}
/* ##############################################################################

  FORMY

############################################################################## */
#formy_form table {
  width: 100%;
}

#formy_form th,
#formy_form td {
  padding: 1rem;
  vertical-align: middle;
  border-bottom: solid 1px #eee;
}
@media screen and (max-width: 47.9375em) {
  #formy_form th,
  #formy_form td {
    display: block;
    width: auto !important;
    padding-left: 0;
    padding-right: 0;
  }
}

#formy_form th {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}
@media print, screen and (min-width: 48em) {
  #formy_form th {
    width: 34%;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form th {
    width: 100%;
    border-bottom: none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
  }
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form table textarea {
  width: 100%;
  border-radius: 0.1875rem;
  vertical-align: bottom;
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 0.3125rem 0.9375rem;
  border: 1px solid #ccc;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-size: 1rem;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  #formy_form table input[type=text],
  #formy_form table input[type=email],
  #formy_form table input[type=tel],
  #formy_form table input[type=date],
  #formy_form table input[type=password],
  #formy_form select,
  #formy_form textarea {
    font-size: 16px;
  }
}

#formy_form textarea {
  height: 6.25rem;
}

#formy_form select {
  height: 2.5rem;
}

#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#formy_form input:hover {
  opacity: 0.7;
}

#formy_form textarea:hover {
  opacity: 0.7;
}

#formy_form input:focus {
  outline: none;
}

#formy_form .parsley-validated {
  background-color: #eee;
}

#formy_form .parsley-error {
  background-color: #fee;
}

#formy_form .parsley-success {
  background-color: #fff;
}

.help_text {
  font-size: 0.875rem;
  color: #999;
}

.hidden_help {
  display: none;
}

.formy_privacy div {
  overflow-y: scroll;
  height: 8.75rem;
  border: solid 1px #ccc;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.requiredIcon {
  background-color: #f55;
  color: #fff;
  margin: 0 0 0 1em;
  font-size: 0.75rem;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  float: right;
}

#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 2rem;
  text-align: center;
}

#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 0.1875rem;
  padding: 1rem 2rem;
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-primary, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  transition: var(--transit-default, all 0.4s ease-out);
}
#formy_btn input:not(:first-child) {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn input {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}

#formy_form ul li input[type=radio],
#formy_form ul li input[type=checkbox] {
  display: none !important;
}

#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}

#formy_form ul li label:hover {
  opacity: 0.7;
}

#formy_form ul li label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  border: 2px solid #788b93;
  left: 1rem;
  top: 0.75rem;
}

#formy_form ul li input[type=radio] + label::before {
  border-radius: 0.625rem;
}

#formy_form ul li input[type=radio]:checked + label,
#formy_form ul li input[type=checkbox]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}

#formy_form ul li input[type=radio]:checked + label::before,
#formy_form ul li input[type=checkbox]:checked + label::before {
  border-color: #e75f5f;
}

#formy_form ul li input[type=radio]:checked + label::after,
#formy_form ul li input[type=checkbox]:checked + label::after {
  content: "";
  width: 0.625rem;
  height: 1.125rem;
  top: 0.25rem;
  left: 1.25rem;
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}

.formy_confirm {
  background-color: #4dbaff;
}

.formy_submit_disabled {
  background-color: #ccc;
}

#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}

#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 0.5rem 1.6875rem;
  min-width: auto;
  font-size: 0.9375rem;
  border: 1px solid #aaa;
  border-radius: 2rem;
}

.formy_send {
  background-color: #ff6600;
}

#total_required {
  padding: 1rem;
  color: #f55555;
  text-align: center;
}

@media print, screen and (min-width: 48em) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form td {
    padding-top: 0;
  }
  .autoConfirmBack {
    margin-bottom: 0.5714285714rem;
  }
}
/* --- section_pdg --- */
.section_pdg {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .section_pdg#section-01 {
    padding-top: 0;
  }
}
@media screen and (max-width: 47.9375em) and (max-width: 47.9375em) {
  .section_pdg#section-01 {
    padding-top: 24px;
  }
}

.section_pdg-sm {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-sm {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

/* --- contactページ　section_pdg --- */
.page-contact:not(.page-thanks) #section-01 {
  padding-bottom: 0;
}

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .inner {
    width: 90%;
  }
}

.inner-sm {
  max-width: 960px;
}

.inner-lg {
  max-width: 1540px;
}

.inner-xl {
  max-width: calc(100% - 112px);
}
@media screen and (max-width: 67.5em) {
  .inner-xl {
    max-width: 90%;
  }
}
@media screen and (max-width: 47.9375em) {
  .inner-xl {
    max-width: 86%;
  }
}

/* --- anchor --- */
.anchor {
  position: absolute;
  top: -80px; /* 固定ヘッダーの高さ */
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .anchor .anchor {
    top: -64px; /* 固定ヘッダーの高さ */
  }
}

/* contact
********************************************** */
.contact .cta_tel {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.contact .cta_tel a {
  padding: 24px 40px;
  color: #63502f;
  background-color: #f5c246;
  border-radius: 8px;
}
@media screen and (max-width: 47.9375em) {
  .contact .cta_tel a {
    display: block;
    padding: 16px;
  }
}
.contact .cta_tel a .tel {
  display: flex;
}
@media screen and (max-width: 47.9375em) {
  .contact .cta_tel a .tel {
    margin-bottom: 8px;
  }
}
.contact .cta_tel a .tel img {
  display: block;
  width: 30px;
  margin-right: 8px;
}

/* flex
**************************************** */
/* --- ブロック要素 --- */
.flex {
  display: flex;
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex-j-ctr {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-ctr {
  align-items: center;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}

.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-end {
  align-content: flex-end;
}

.flex-c-ctr {
  align-content: center;
}

.flex-c-baseline {
  align-content: baseline;
}

.flex-c-stretch {
  align-content: stretch;
}

@media screen and (max-width: 47.9375em) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}
.flex-col2,
.flex-col3,
.flex-col4,
.flex-col5 {
  flex-wrap: wrap;
}

.flex-col1 > * {
  width: 100%;
}

.flex-col2 > * {
  width: 50%;
}

.flex-col3 > * {
  width: 33.3333333333%;
}

.flex-col4 > * {
  width: 25%;
}

.flex-col5 > * {
  width: 20%;
}

.flex-col2.gap {
  gap: 4%;
}

.flex-col3.gap {
  gap: 3%;
}

.flex-col4.gap {
  gap: 2%;
}

.flex-col5.gap {
  gap: 1%;
}

.flex-col2.gap > * {
  width: 48%;
  margin-bottom: 4%;
}

.flex-col3.gap > * {
  width: 31.33%;
  margin-bottom: 3%;
}

.flex-col4.gap > * {
  width: 23.5%;
  margin-bottom: 2%;
}

.flex-col5.gap > * {
  width: 19.2%;
  margin-bottom: 1%;
}

@media screen and (min-width: 48em) {
  .flex-col2.gap-wide {
    gap: 6%;
  }
  .flex-col3.gap-wide {
    gap: 5%;
  }
  .flex-col4.gap-wide {
    gap: 4%;
  }
  .flex-col5.gap-wide {
    gap: 3%;
  }
  .flex-col2.gap-wide > * {
    width: 47%;
    margin-bottom: 6%;
  }
  .flex-col3.gap-wide > * {
    width: 30%;
    margin-bottom: 5%;
  }
  .flex-col4.gap-wide > * {
    width: 22%;
    margin-bottom: 4%;
  }
  .flex-col5.gap-wide > * {
    width: 17.6%;
    margin-bottom: 3%;
  }
  .flex-col2.column-gap > *,
  .flex-col3.column-gap > *,
  .flex-col4.column-gap > *,
  .flex-col5.column-gap > * {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .flex-col2.flex-sp-block > *,
  .flex-col3.flex-sp-block > *,
  .flex-col4.flex-sp-block > *,
  .flex-col5.flex-sp-block > * {
    width: 100%;
  }
  .flex-col2.gap.flex-sp-block > *,
  .flex-col3.gap.flex-sp-block > *,
  .flex-col4.gap.flex-sp-block > *,
  .flex-col5.gap.flex-sp-block > * {
    margin-bottom: 24px;
  }
  .flex-col2.gap.flex-sp-block > *:last-child,
  .flex-col3.gap.flex-sp-block > *:last-child,
  .flex-col4.gap.flex-sp-block > *:last-child,
  .flex-col5.gap.flex-sp-block > *:last-child {
    margin-bottom: 0;
  }
  .flex-sp-col3 > * {
    width: 33.3333333333%;
  }
  .flex-sp-col2 > * {
    width: 50%;
  }
  .flex-col4.gap.flex-sp-col3 > * {
    width: 32%;
  }
  .flex-col4.gap.flex-sp-col2 > * {
    width: 49%;
  }
  .flex-col5.gap.flex-sp-col3 > * {
    width: 32%;
  }
  .flex-col5.gap.flex-sp-col2 > * {
    width: 49%;
  }
  .flex-col3.gap.flex-sp-col2 > * {
    width: 48.5%;
  }
}
/* ##############################################################################

    FOOTER

############################################################################## */
/* footer
*************************************************** */
.footer .page_top-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  z-index: 999;
  transition: var(--transit-default, all 0.4s ease-out);
}
.footer .page_top-btn.active {
  transform: translateX(0);
}
.footer .page_top-btn a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 47.9375em) {
  .footer .page_top-btn img {
    width: 220px;
  }
}
.footer .footer--wrapper {
  position: relative;
  overflow: hidden;
}
.footer .footer--wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  min-width: 1920px;
  width: 100%;
  height: 100%;
  clip-path: ellipse(50% 100% at bottom);
  background-color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper::before {
    min-width: 1400px;
  }
}
.footer .footer--wrapper .deco01 {
  position: absolute;
  right: 11.25vw;
  bottom: 6.1458333333vw;
  width: 14.375vw;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .deco01 {
    left: 5.7971014493vw;
    right: inherit;
    width: 18.3574879227vw;
  }
}
.footer .footer--wrapper .footer_top {
  max-width: 1060px;
  padding-top: 4.4791666667vw;
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top {
    padding-top: 60px;
  }
}
.footer .footer--wrapper .footer_top .footer--logo {
  margin-bottom: 14px;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .footer--logo {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .footer--logo img {
    width: 160px;
  }
}
.footer .footer--wrapper .footer_top .footer--logo .logo--txt {
  margin-top: 1em;
  font-size: 1.25rem;
  line-height: 2;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .footer--logo .logo--txt {
    margin-top: 0.5em;
    font-size: 1rem;
  }
}
.footer .footer--wrapper .footer_top .sns_area {
  margin-bottom: 32px;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .sns_area {
    margin-bottom: 20px;
  }
}
.footer .footer--wrapper .footer_top .sns_area .sns--list li:not(:last-child) {
  margin-right: 1.625rem;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .sns_area .sns--list li:not(:last-child) {
    margin-right: 14px;
  }
}
.footer .footer--wrapper .footer_top .sns_area .sns--list a {
  display: grid;
  place-items: center;
  width: 1.875rem;
  height: 1.875rem;
}
.footer .footer--wrapper .footer_top .sns_area .sns--list a:hover {
  opacity: 0.7;
}
.footer .footer--wrapper .footer_top .sns_area .sns--list svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #63502f;
}
.footer .footer--wrapper .footer_top .info {
  position: relative;
  width: fit-content;
  margin: 0 auto 2.3958333333vw;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .info {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    column-gap: 16px;
    margin-bottom: 28px;
  }
}
.footer .footer--wrapper .footer_top .info .bubble {
  position: absolute;
  top: -32px;
  left: -74px;
  width: 152px;
  height: 98px;
  padding-bottom: 12px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/footer_bg_balloon.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .info .bubble {
    position: inherit;
    top: inherit;
    left: inherit;
    width: 100px;
    height: 64px;
    padding-bottom: 10px;
  }
}
.footer .footer--wrapper .footer_top .info .bubble .txt {
  font-size: 1.125rem;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .info .bubble .txt {
    font-size: 0.9285714286rem;
  }
}
.footer .footer--wrapper .footer_top .info .access {
  margin-bottom: 20px;
  line-height: 1.875;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .info .access {
    margin-bottom: 10px;
    font-size: 0.9285714286rem;
    text-align: left;
  }
}
.footer .footer--wrapper .footer_top .info .tel {
  color: #3fa037;
  font-size: 2.5rem;
  letter-spacing: 0.03em;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .info .tel {
    width: 100%;
    font-size: 1.7142857143rem;
  }
}
.footer .footer--wrapper .footer_top .info .tel img {
  width: 60px;
  margin-right: 8px;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .info .tel img {
    width: 40px;
  }
}
.footer .footer--wrapper .footer_top .info .tel .num {
  font-size: 4rem;
  letter-spacing: 0.03em;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_top .info .tel .num {
    font-size: 2.8571428571rem;
  }
}
.footer .footer--wrapper .footer_ctr {
  max-width: 1060px;
  padding-right: 3.6458333333vw;
  margin-bottom: 4.0625vw;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_ctr {
    padding-right: 0;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_ctr .fnav {
    width: 90%;
    margin: 0 auto;
  }
}
.footer .footer--wrapper .footer_ctr .fnav .fnav--menu {
  padding: 20px 0 20px 36px;
  border-left: 2px dotted #3fa037;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_ctr .fnav .fnav--menu {
    padding: 0;
    border-left: inherit;
  }
}
.footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item a:hover {
  color: #3fa037;
}
.footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item > a {
  position: relative;
  display: block;
  padding-left: 24px;
  font-family: var(--font-jp);
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item > a {
    padding-left: 20px;
  }
}
.footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item > a::before {
  content: "";
  position: absolute;
  top: 0.8em;
  left: 0;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #3fa037;
  border-radius: 50%;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item > a::before {
    top: 0.75em;
    width: 0.5714285714rem;
    height: 0.5714285714rem;
  }
}
.footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item .sub-menu {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2.8125vw;
  max-width: 460px;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item .sub-menu {
    column-gap: 20px;
  }
}
.footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item .sub-menu .menu-item {
  width: calc((100% - 2.8125vw) / 2);
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item .sub-menu .menu-item {
    width: auto;
  }
}
.footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item .sub-menu .menu-item a {
  position: relative;
  display: block;
  padding-left: 40px;
  font-size: 0.875rem;
  font-family: var(--font-jp);
  line-height: 2.8571428571;
  letter-spacing: 0.05em;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item .sub-menu .menu-item a {
    padding-left: 20px;
  }
}
.footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item .sub-menu .menu-item a::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 1.75rem;
  height: 0.125rem;
  background-color: #d9dfd3;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer--wrapper .footer_ctr .fnav .fnav--menu > .menu-item .sub-menu .menu-item a::before {
    width: 1rem;
    height: 0.1428571429rem;
  }
}
.footer .footer_btm {
  padding-top: 20px;
  padding-bottom: 30px;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer_btm {
    padding-top: 10px;
    padding-bottom: 16px;
  }
}
.footer .footer_btm .copyright {
  margin-bottom: 0.833em;
  font-size: 0.75rem;
  line-height: 1.8333333333;
}
@media screen and (max-width: 47.9375em) {
  .footer .footer_btm .copyright {
    margin-bottom: 0.4em;
  }
}
.footer .footer_btm .pbl {
  line-height: 1;
}
.footer .footer_btm .pbl a img {
  width: 120px;
  opacity: 0.5;
  transition: var(--transit-default, all 0.4s ease-out);
}
.footer .footer_btm .pbl a:hover img {
  opacity: 1;
}

/* ##############################################################################

    HEADER

############################################################################## */
/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 136px;
  z-index: 9999;
  transition: var(--transit-default, all 0.4s ease-out);
}
.header > .inner {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-left: 54px;
  padding-right: 32px;
}
@media screen and (max-width: 112.5em) {
  .header > .inner {
    padding-left: 10px;
    padding-right: 0;
  }
}
@media screen and (max-width: 93.75em) {
  .header > .inner {
    padding-right: 16px;
  }
}
@media screen and (max-width: 47.9375em) {
  .header > .inner {
    padding: 0;
  }
}
@media screen and (max-width: 67.5em) {
  .header {
    height: 60px;
  }
}
.header.header-sm {
  background-color: #fff;
}

.page-contact .header {
  position: absolute;
}

@media screen and (max-width: 67.5em) {
  .header--logo {
    position: absolute;
    top: 10px;
    z-index: 999;
  }
}
.header--logo a {
  display: flex;
  flex-direction: column;
}
.header--logo a img {
  width: 132px;
  margin-right: 24px;
  margin-bottom: 8px;
}
@media screen and (max-width: 112.5em) {
  .header--logo a img {
    margin-right: 12px;
  }
}
.header--logo a .logo--txt {
  line-height: 1.4285714286;
  letter-spacing: 0.05em;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  .header--logo a .logo--txt {
    margin-left: 12px;
  }
}

@media screen and (max-width: 67.5em) {
  .header-sm .header--logo .logo--txt {
    opacity: 0;
    pointer-events: none;
  }
}

.footer--logo a {
  display: block;
}

.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 67.5em) {
  .header--logo img {
    width: auto;
    max-height: 40px;
  }
}
/* gnav
********************************************** */
.gnav a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.gnav--link {
  padding: 0 8px;
  width: 128px;
  border-radius: 0 0 8px 8px;
}
@media screen and (max-width: 106.25em) {
  .gnav--link {
    width: 110px;
  }
}
@media screen and (max-width: 93.75em) {
  .gnav--link {
    width: 9.33vw;
  }
}
@media screen and (max-width: 84.375em) {
  .gnav--link {
    width: 110px;
  }
}
@media screen and (max-width: 80em) {
  .gnav--link {
    width: 128px;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--link {
    width: 100%;
  }
}
.gnav--link .img {
  position: relative;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-bottom: 8px;
  background-color: #eaece8;
}
@media screen and (max-width: 80em) {
  .gnav--link .img {
    width: 4.375rem;
    height: 4.375rem;
  }
}
.gnav--link .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: var(--transit-default, all 0.4s ease-out);
}
.gnav--link .ttl {
  color: var(--clr-body, #63502f);
  font-size: 0.875rem;
  line-height: 1.4;
  height: 39.2px;
}
@media screen and (max-width: 80em) {
  .gnav--link .ttl {
    font-size: 0.8125rem;
    height: auto;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--link .ttl {
    font-size: 1rem;
  }
}
.gnav--link:hover .img .menu--img {
  opacity: 0;
}

.head_sub_nav--wrap {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 100;
  transition: var(--transit-default, all 0.4s ease-out);
  opacity: 0;
  pointer-events: none;
}

.menu-item-has-children {
  position: relative;
}

.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.head_sub_nav a {
  display: block;
  padding: 16px 24px;
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
}

.cta_tel a > span {
  display: block;
}

.cta_tel .tel {
  font-size: 1.5rem;
}

.cta_tel .num {
  font-size: 1.875rem;
}

.gnav--tel {
  margin-left: 4.2708333333vw;
}
@media screen and (max-width: 118.75em) {
  .gnav--tel {
    margin-left: 16px;
  }
}
@media screen and (max-width: 93.75em) {
  .gnav--tel {
    margin-left: 0;
  }
}
@media screen and (max-width: 80em) {
  .gnav--tel {
    position: absolute;
    top: 136px;
    right: 220px;
    height: 100px !important;
    padding: 0 20px;
    background: #fff;
    border-radius: 0 0 0 20px;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--tel {
    position: inherit;
    top: inherit;
    right: inherit;
    height: auto !important;
    padding: 24px 0;
    background-color: inherit;
  }
}

.gnav--contact {
  margin-left: 1.4583333333vw;
}
@media screen and (max-width: 106.25em) {
  .gnav--contact {
    margin-left: 16px;
  }
}
@media screen and (max-width: 93.75em) {
  .gnav--contact {
    position: absolute;
    top: 136px;
    right: 0;
    margin-left: 0;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--contact {
    position: relative;
    top: inherit;
    left: inherit;
  }
}

.gnav--tel a {
  display: flex !important;
  flex-direction: column;
}

.gnav--contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 256px;
  height: 160px;
  border-radius: 0 0 24px 24px;
  background-color: #3fa037;
  border: 1px solid #3fa037;
}
.gnav--contact a::before {
  content: "";
  position: absolute;
  top: calc(50% - 16px);
  right: 12px;
  display: block;
  width: 0.5rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 50%, 0 0, 0 100%);
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  background-color: #fff;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 67.5em) {
  .gnav--contact a::before {
    top: calc(50% - 4px);
  }
}
@media screen and (max-width: 106.25em) {
  .gnav--contact a {
    width: 220px;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--contact a {
    width: 100%;
    height: inherit;
    border-radius: 20px;
  }
}
.gnav--contact a svg {
  margin-bottom: 12px;
  fill: #fff;
}
@media screen and (max-width: 93.75em) {
  .gnav--contact a svg {
    margin-bottom: 8px;
  }
}
.gnav--contact a .contant--txt {
  margin-bottom: 6px;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: 0.05em;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 106.25em) {
  .gnav--contact a .contant--txt {
    font-size: 1rem;
  }
}
.gnav--contact a .contant--subtxt {
  padding: 8px 34px;
  color: #3fa037;
  line-height: 1;
  letter-spacing: 0.05em;
  background-color: #fff;
  border-radius: 8px;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 106.25em) {
  .gnav--contact a .contant--subtxt {
    padding: 6px 30px;
    font-size: 0.875rem;
  }
}
.gnav--contact a:hover {
  background-color: #fff;
}
.gnav--contact a:hover::before {
  background-color: #3fa037;
}
.gnav--contact a:hover svg {
  fill: #3fa037;
}
.gnav--contact a:hover .contant--txt {
  color: #3fa037;
}
.gnav--contact a:hover .contant--subtxt {
  color: #fff;
  background-color: #3fa037;
}

.gnav--cta .tel {
  display: flex;
  margin-bottom: 12px;
  color: #3fa037;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0.03em;
}
@media screen and (max-width: 106.25em) {
  .gnav--cta .tel {
    margin-bottom: 8px;
    font-size: 1.25rem;
  }
}
.gnav--cta .tel img {
  width: 28px;
  margin-right: 6px;
}

.gnav--cta .num {
  font-size: 2rem;
}
@media screen and (max-width: 106.25em) {
  .gnav--cta .num {
    font-size: 1.625rem;
  }
}

.gnav--cta .hours {
  padding: 12px 22px;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.05em;
  background-color: #fff;
  border-radius: 8px;
}
@media screen and (max-width: 106.25em) {
  .gnav--cta .hours {
    padding: 8px 26px;
    font-size: 0.8125rem;
  }
}
@media screen and (max-width: 80em) {
  .gnav--cta .hours {
    background-color: #eaece8;
  }
}

.header-sm .gnav--cta .hours {
  background-color: #eaece8;
}

@media print, screen and (min-width: 67.5625em) {
  .gnav,
  .gnav ul,
  .gnav li,
  .gnav a {
    height: 100%;
  }
  .gnav {
    display: flex;
  }
  .gnav_btn,
  .tel_btn,
  .gnav_contact-sp {
    display: none;
  }
  .gnav--link {
    text-align: center;
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover,
  .gnav--menu > li.current-menu-item > .gnav--link {
    background-color: rgba(63, 160, 55, 0.2509803922);
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover .img,
  .gnav--menu > li.current-menu-item > .gnav--link .img {
    background-color: #fff;
  }
  .gnav--menu_sp {
    display: none;
  }
  .head_sub_nav a {
    background-color: #fff;
  }
  .gnav_sub_toggle {
    display: none;
  }
  .gnav--contact a {
    position: relative;
    height: 160px;
  }
  /* hover */
}
@media screen and (min-width: 67.5625em) and (max-width: 93.75em) {
  .gnav--contact a {
    height: 140px;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a:hover {
    opacity: 0.7;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav,
  .gnav_overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav {
    width: 100%;
    margin: 0;
    padding: 110px 7% 24px;
    overflow: scroll;
    background-color: #f7f7f7;
    z-index: 300;
    -webkit-overflow-scrolling: touch;
  }
  .gnav_overlay {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .gnav.active,
  .gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav--menu {
    display: none;
  }
  .gnav--menu_sp,
  .gnav--cta {
    display: block;
  }
  .gnav--menu_sp li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu_sp li {
    border-bottom: 2px dotted #3fa037;
  }
  .gnav--menu_sp a {
    display: block;
    padding: 18.5px 16px;
  }
  .gnav--menu_sp li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 1px solid var(--clr-main, #1d7fb7);
    border-right: 1px solid var(--clr-main, #1d7fb7);
    transform: rotate(45deg);
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main, #1d7fb7);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a {
    padding-left: 32px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -20px;
    width: 64px;
    height: 64px;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-main, #1d7fb7);
  }
  .gnav_sub_toggle::before,
  .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 11px;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
  .head_sub_nav {
    position: relative;
  }
  .tel_btn,
  .gnav_btn,
  .gnav_contact-sp {
    position: fixed;
    top: 0;
    width: 70px;
    height: 70px;
    z-index: 9999;
  }
  .tel_btn {
    right: 64px;
  }
  .tel_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
  }
  .tel_btn svg {
    fill: var(--clr-main, #1d7fb7);
  }
  .gnav_btn {
    right: 82px;
    transition: var(--transit-default, all 0.4s ease-out);
    background-color: #3fa037;
    border-radius: 0 0 8px 8px;
  }
  .gnav_btn--lines {
    position: relative;
    width: 32px;
    height: 28px;
  }
  .gnav_btn--lines span {
    position: absolute;
    transition: var(--transit-default, all 0.4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span:not(:nth-of-type(3)) {
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #fff;
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    bottom: 14px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    left: 50%;
    bottom: -2px;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    font-size: 0.7857142857rem;
    letter-spacing: 0;
    line-height: 1;
    white-space: nowrap;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(12px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    transform: translateY(2px) rotate(45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    opacity: 0;
  }
  .gnav_contact-sp {
    right: 10px;
  }
  .gnav_contact-sp a {
    width: 100%;
    height: 100%;
    border-radius: 0 0 8px 8px;
    background-color: #3fa037;
  }
  .gnav_contact-sp a svg {
    width: 36px;
    margin-bottom: 4px;
    fill: #fff;
  }
  .gnav_contact-sp a span {
    display: block;
    color: #fff;
    font-size: 0.8571428571rem;
    line-height: 1;
    letter-spacing: 0;
  }
  /* ---CTA --- */
  .gnav--tel {
    margin-left: 0;
    text-align: center;
  }
  .gnav--contact {
    margin-left: 0;
  }
  .gnav--contact a {
    padding: 16px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  .gnav--menu_sp a {
    padding: 19px 16px;
  }
}
.home {
  line-height: 2.5;
}
@media screen and (max-width: 47.9375em) {
  .home {
    line-height: 2.2;
  }
}
.home .bg_gray {
  position: absolute;
  background-color: #d7dad3;
  opacity: 0.5;
}
.home .bg_grn {
  position: absolute;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_water_green.png);
  opacity: 0.5;
}
.home .preparation {
  margin-top: 40px;
}
@media screen and (max-width: 47.9375em) {
  .home .preparation {
    margin-top: 20px;
  }
}

/* hero
********************************************** */
.hero {
  position: relative;
  height: 48.3333333333vw;
}
@media screen and (max-width: 120em) {
  .hero {
    height: 928px;
  }
}
@media screen and (max-width: 93.75em) {
  .hero {
    height: clamp(680px, 57.3vw, 760px);
  }
}
@media screen and (max-width: 87.5em) {
  .hero {
    height: clamp(680px, 57.3vw, 720px);
  }
}
@media screen and (max-width: 47.9375em) {
  .hero {
    height: inherit;
  }
}
.hero .bg_gray {
  position: absolute;
  background-color: #d7dad3;
  opacity: 0.5;
  z-index: -1;
}
.hero .bg_gray.bg_gray01 {
  bottom: 0;
  left: 0;
  width: 40.4166666667vw;
  height: 31.25vw;
  border-radius: 0 50px 50px 0;
}
@media screen and (max-width: 93.75em) {
  .hero .bg_gray.bg_gray01 {
    bottom: 60px;
    width: 41.6666666667vw;
  }
}
.hero .bg_gray.bg_gray02 {
  top: -136px;
  right: 0;
  width: 31.25vw;
  height: 44.5833333333vw;
  border-radius: 0 0 0 50px;
}
.hero .deco {
  position: absolute;
}
.hero .deco.deco01 {
  right: 3.125vw;
  bottom: 11.5625vw;
  width: 23.0208333333vw;
  z-index: 1;
}
@media screen and (max-width: 93.75em) {
  .hero .deco.deco01 {
    display: none;
  }
}
.hero .deco.deco02 {
  left: 12.3958333333vw;
  bottom: -2.1875vw;
  width: 21.25vw;
}
.hero .deco.deco03 {
  left: 58.59375vw;
  bottom: -2.1875vw;
  width: 11.3541666667vw;
}
.hero .banner {
  position: absolute;
  right: 2.5vw;
  bottom: min(9.7916666667vw, 188px);
  width: 15.8333333333vw;
  z-index: 1;
}
@media screen and (max-width: 93.75em) {
  .hero .banner {
    bottom: 11.875vw;
  }
}
@media screen and (max-width: 81.25em) {
  .hero .banner {
    bottom: 9.7916666667vw;
  }
}
.hero .banner img {
  transition: var(--transit-default, all 0.4s ease-out);
}
.hero .banner img:hover {
  opacity: 0.7 !important;
}
.hero .btm_imgarea {
  position: absolute;
  bottom: -19.7916666667vw;
  right: 0;
  width: 23.3333333333vw;
  height: 26.25vw;
}
.hero .btm_imgarea .bg_gray {
  top: 6.1458333333vw;
  right: 0;
  width: 18.3333333333vw;
  height: 16.6666666667vw;
  border-radius: 20px 0 0 20px;
  z-index: -1;
}
.hero .btm_imgarea .btm_img {
  position: absolute;
  width: 100%;
  border-radius: 20px 0 0 20px;
}
.hero .btm_imgarea .btm_deco {
  position: absolute;
  left: 2.0833333333vw;
  bottom: 0;
  width: 18.75vw;
}
@media screen and (max-width: 106.25em) {
  .hero .btm_imgarea .btm_deco {
    left: inherit;
    right: 16px;
    bottom: 32px;
    width: 13.5416666667vw;
  }
}
.hero .hero_inner {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 36.6666666667vw calc(15.7291666667vw + 2.9166666667vw) 37.0833333333vw;
  width: 100%;
  padding-top: 16px;
  padding-right: 2.5vw;
}
@media screen and (max-width: 120em) {
  .hero .hero_inner {
    grid-template-columns: 704px calc(15.7291666667vw + 2.9166666667vw) 712px;
  }
}
@media screen and (max-width: 93.75em) {
  .hero .hero_inner {
    grid-template-columns: 40vw 26vw 40vw;
    min-height: 600px;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero .hero_inner {
    display: block;
    min-height: inherit;
  }
}
.hero .hero_inner .hero_txt_slider {
  position: absolute;
  left: 0;
  bottom: -5.7291666667vw;
  column-gap: 50px;
  animation: hero_txt_slider 150s linear infinite;
}
.hero .hero_inner .hero_txt_slider .hero--catch {
  font-size: 6.25vw;
  line-height: 1;
  white-space: nowrap;
}
.hero .hero_inner .img01 {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .hero .hero_inner .img01 {
    position: absolute;
  }
}
.hero .hero_inner .img01 img {
  width: 100%;
  border-radius: 0 40px 40px 0;
}
@media screen and (max-width: 47.9375em) {
  .hero .hero_inner .img01 img {
    width: 50%;
    border-radius: 0 20px 20px 0;
  }
}
.hero .hero_inner .img01 img.deco04 {
  bottom: 50px;
  right: -9.8958333333vw;
  width: 10.9375vw;
  max-width: inherit;
  z-index: -1;
}
@media screen and (max-width: 93.75em) {
  .hero .hero_inner .img01 img.deco04 {
    display: none;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero .hero_inner .img01 img.deco04 {
    display: block;
  }
}
.hero .hero_inner .hero_ttl {
  position: relative;
  padding-top: 56px;
  padding-left: 2.9166666667vw;
}
@media screen and (max-width: 80em) {
  .hero .hero_inner .hero_ttl {
    padding-top: 96px;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero .hero_inner .hero_ttl {
    width: 100%;
  }
}
.hero .hero_inner .hero_ttl .img {
  position: absolute;
  bottom: max(-3.125vw, -60px);
  right: -8.0208333333vw;
  width: 20.8333333333vw;
  height: 15.625vw;
  z-index: 1;
}
.hero .hero_inner .hero_ttl .img::before {
  content: "";
  position: absolute;
  top: -8px;
  right: -8px;
  width: 50%;
  height: 50%;
  background-color: #f2f3f1;
  border-radius: 0 20px 0 0;
}
.hero .hero_inner .hero_ttl .img img {
  width: 100%;
  border-radius: 20px;
}
.hero .hero_inner .hero_ttl .ttl {
  margin-left: 1.5625vw;
  font-size: 3.125rem;
  line-height: 1.76;
  letter-spacing: 0.1em;
  z-index: 1;
}
.hero .hero_inner .hero_ttl .txt {
  margin-top: 12px;
  line-height: 3;
}
.hero .hero_inner .img02 {
  padding-top: 40px;
}
@media screen and (max-width: 80em) {
  .hero .hero_inner .img02 {
    padding-top: 80px;
  }
}
.hero .hero_inner .img02 img {
  width: 100%;
  border-radius: 30px;
}

/* hero_sp
********************************************** */
.hero_sp {
  padding-top: 100px;
}
.hero_sp .hero_sp_top {
  position: relative;
}
.hero_sp .hero_sp_top .bg_gray01 {
  top: 20%;
  left: 0;
  width: 80%;
  height: 70%;
  z-index: -1;
  border-radius: 0 40px 40px 0;
}
.hero_sp .hero_sp_top .top {
  padding-right: 5%;
}
.hero_sp .hero_sp_top .top .img01 {
  flex: 1;
  max-width: 400px;
  margin-right: 30px;
}
.hero_sp .hero_sp_top .top .img01 img {
  border-radius: 0 16px 16px 0;
  margin-bottom: 20px;
}
.hero_sp .hero_sp_top .top .ttl {
  font-size: 2.1428571429rem;
  line-height: 1.8;
  letter-spacing: 0.1em;
}
.hero_sp .hero_sp_top .btm {
  padding-left: 5%;
}
.hero_sp .hero_sp_top .btm .txt {
  padding-top: 10px;
}
.hero_sp .hero_sp_top .btm .img02 {
  position: relative;
  flex: 1;
  max-width: 400px;
  margin-left: 12px;
}
.hero_sp .hero_sp_top .btm .img02 .deco01 {
  position: absolute;
  left: 24px;
  bottom: 8px;
  transform: translateX(-100%);
  width: 120px;
}
.hero_sp .hero_sp_top .btm .img02 .img {
  position: relative;
  border-radius: 16px 0 0 16px;
}
.hero_sp .hero_sp_btm {
  position: relative;
  padding-top: 80px;
}
.hero_sp .hero_sp_btm .hero_txt_slider {
  position: absolute;
  top: 8px;
  left: 0;
  column-gap: 18px;
  animation: hero_txt_slider 100s linear infinite;
  z-index: 1;
}
.hero_sp .hero_sp_btm .hero_txt_slider .hero--catch {
  font-size: 3.5714285714rem;
  line-height: 1;
  white-space: nowrap;
}
.hero_sp .hero_sp_btm .img01 {
  position: relative;
  width: 60%;
  max-width: 400px;
}
.hero_sp .hero_sp_btm .img01::before {
  content: "";
  position: absolute;
  right: -8px;
  bottom: -8px;
  width: 80%;
  height: 50%;
  z-index: -1;
  background-color: #f2f3f1;
  border-radius: 0 20px 0 0;
}
.hero_sp .hero_sp_btm .img01 .img {
  border-radius: 0 10px 10px 0;
}
.hero_sp .hero_sp_btm .img01 .deco02 {
  position: absolute;
  top: -32px;
  right: 60px;
  width: 200px;
  transform: translateX(100%);
}
.hero_sp .hero_sp_btm .img02 {
  position: relative;
  width: 70%;
  max-width: 400px;
  margin-left: auto;
  margin-top: -10vw;
  z-index: -2;
}
.hero_sp .hero_sp_btm .img02 .img {
  width: 100%;
  max-width: inherit;
  border-radius: 10px 0 0 10px;
}
.hero_sp .hero_sp_btm .img02 .deco03 {
  position: absolute;
  top: 16.9082125604vw;
  left: 24px;
  width: 120px;
  transform: translateX(-100%);
}
.hero_sp .hero_sp_btm .banner {
  width: 96%;
  max-width: 400px;
  margin: -16vw auto 0;
}
.hero_sp .hero_sp_btm .banner img {
  width: 100%;
}

/* home_concept
********************************************** */
.home_concept {
  padding-top: 42px;
}
@media screen and (max-width: 47.9375em) {
  .home_concept {
    padding-top: 48px;
  }
}
.home_concept .concept_top {
  margin-left: 12.8125vw;
}
@media screen and (max-width: 93.75em) {
  .home_concept .concept_top {
    margin-left: 8.125vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_top {
    align-items: center;
    width: 90%;
    margin: 0 auto 40px;
  }
}
.home_concept .concept_top .left {
  padding-top: 56px;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_top .left {
    padding-top: 0;
  }
}
.home_concept .concept_top .left img {
  position: absolute;
  bottom: 2.0833333333vw;
  left: -5.2083333333vw;
  width: 6.7708333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_top .left img {
    bottom: 10px;
    left: -70px;
    width: 66px;
  }
}
.home_concept .concept_top .left .en {
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_top .left .en {
    line-height: 2;
  }
}
.home_concept .concept_top .left .catch {
  margin-left: 35px;
  font-size: 2.5rem;
  line-height: 1.9;
}
@media screen and (max-width: 93.75em) {
  .home_concept .concept_top .left .catch {
    margin-left: 24px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_top .left .catch {
    margin-left: 8px;
    font-size: 2.2857142857rem;
  }
}
.home_concept .concept_top .right {
  position: relative;
  width: 100%;
  max-width: 1008px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/concept_bg_map.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}
.home_concept .concept_top .right::before {
  content: "";
  display: block;
  padding-top: 67.46%;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_top .right::before {
    content: inherit;
  }
}
.home_concept .concept_top .right .right--wrapper {
  margin-top: 70px;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_top .right .right--wrapper {
    margin-top: 36px;
  }
}
.home_concept .concept_top .right .right--wrapper .home_ttl .en {
  margin-bottom: 36px;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_top .right .right--wrapper .home_ttl .en {
    margin-bottom: 18px;
  }
}
.home_concept .concept_top .right .right--wrapper .home_ttl .jp {
  margin-bottom: 16px;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_top .right .right--wrapper .home_ttl .jp {
    margin-bottom: 8px;
  }
}
.home_concept .concept_btm {
  margin-top: -8.3333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_btm {
    margin-top: 0;
  }
}
.home_concept .concept_btm .ttlarea {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_btm .ttlarea {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
  }
}
.home_concept .concept_btm .ttlarea .deco {
  position: absolute;
  right: calc(50% - 270px);
  transform: translateX(100%);
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_btm .ttlarea .deco {
    position: inherit;
    justify-content: space-between;
    width: 100%;
    right: inherit;
    transform: inherit;
  }
}
.home_concept .concept_btm .ttlarea .deco .bubble {
  position: relative;
  width: 160px;
  height: 152px;
  margin-right: 16px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/concept_bg_balloon.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 93.75em) {
  .home_concept .concept_btm .ttlarea .deco .bubble {
    width: 130px;
    height: 126px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_btm .ttlarea .deco .bubble {
    width: 100px;
    height: 92px;
    margin-right: 0;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/concept_bg_balloon_re.svg);
  }
}
.home_concept .concept_btm .ttlarea .deco .bubble .txt {
  font-size: 1.25rem;
  text-align: center;
  line-height: 1.8;
  letter-spacing: 0;
}
@media screen and (max-width: 93.75em) {
  .home_concept .concept_btm .ttlarea .deco .bubble .txt {
    font-size: 1rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_btm .ttlarea .deco .bubble .txt {
    font-size: 0.8571428571rem;
  }
}
.home_concept .concept_btm .ttlarea .deco .bubble .txt .point {
  font-size: 2rem;
  line-height: 1.125;
}
@media screen and (max-width: 93.75em) {
  .home_concept .concept_btm .ttlarea .deco .bubble .txt .point {
    font-size: 1.625rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_btm .ttlarea .deco .bubble .txt .point {
    font-size: 1.4285714286rem;
  }
}
.home_concept .concept_btm .ttlarea .deco .chara {
  margin-top: -60px;
}
@media screen and (max-width: 93.75em) {
  .home_concept .concept_btm .ttlarea .deco .chara {
    width: 140px;
    margin-top: 0;
    object-fit: contain;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_btm .ttlarea .deco .chara {
    width: 96px;
  }
}
.home_concept .concept_btm .ttlarea .home_ttl {
  margin-bottom: 32px;
  text-align: center;
}
.home_concept .concept_btm .ttlarea .home_ttl .en {
  margin-top: -30px;
  font-size: 1.25rem;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_btm .slidearea .concept_slider .slider_itme:nth-child(odd) .item--wrapper {
    padding-bottom: 24px;
  }
}
.home_concept .concept_btm .slidearea .concept_slider .slider_itme:nth-child(even) .item--wrapper {
  padding-top: 48px;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_btm .slidearea .concept_slider .slider_itme:nth-child(even) .item--wrapper {
    padding-top: 24px;
  }
}
.home_concept .concept_btm .slidearea .concept_slider .slider_itme .item--wrapper {
  padding: 0 0.8333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_concept .concept_btm .slidearea .concept_slider .slider_itme .item--wrapper {
    padding: 0 2.8985507246vw;
  }
}
.home_concept .concept_btm .slidearea .concept_slider .slider_itme .item--wrapper .img {
  position: relative;
  margin-bottom: 14px;
}
.home_concept .concept_btm .slidearea .concept_slider .slider_itme .item--wrapper .txtarea .term {
  font-size: 1.25rem;
  line-height: 2;
  letter-spacing: 0.05em;
}
.home_concept .concept_btm .slidearea .concept_slider .slider_itme .item--wrapper .txtarea .ttl {
  font-size: 1.25rem;
  line-height: 2;
  letter-spacing: 0.05em;
}

/* home_point
********************************************** */
.home_point {
  padding-top: 82px;
}
@media screen and (max-width: 109.375em) {
  .home_point {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_point {
    padding-top: 40px;
  }
}
.home_point .point_inner {
  display: grid;
  grid-template-columns: calc((100vw - 630px) / 2) 1fr calc((100vw - 630px) / 2);
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 93.75em) {
  .home_point .point_inner {
    align-items: center;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    grid-template-areas: "img1 img1" "txtarea txtarea" "img2 img2";
  }
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea01 {
    grid-area: img1;
    padding-left: 10px;
  }
}
.home_point .point_inner .imgarea01 .deco01 {
  position: absolute;
  top: 0;
  right: -9.6875vw;
  width: 14.375vw;
}
@media screen and (max-width: 93.75em) {
  .home_point .point_inner .imgarea01 .deco01 {
    right: -5.5208333333vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea01 .deco01 {
    display: none;
  }
}
.home_point .point_inner .imgarea01 .img01 {
  width: 25vw;
  margin-left: 2.5vw;
  margin-bottom: 2.9166666667vw;
  border-radius: 20px;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea01 .img01 {
    display: none;
  }
}
.home_point .point_inner .imgarea01 .img02 {
  width: 28.3333333333vw;
  margin-bottom: 3.125vw;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea01 .img02 {
    display: none;
  }
}
.home_point .point_inner .imgarea01 .img03 {
  border-radius: 0 20px 20px 0;
  width: 31.25vw;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea01 .img03 {
    display: none;
  }
}
.home_point .point_inner .imgarea01 .img_sp {
  width: 100%;
}
.home_point .point_inner .txtarea {
  position: relative;
  width: 630px;
  padding-top: 5.2083333333vw;
  padding-bottom: 3.125vw;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea {
    grid-area: txtarea;
  }
}
@media screen and (max-width: 93.75em) {
  .home_point .point_inner .txtarea {
    padding-top: inherit;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea {
    width: 90%;
    margin: 0 auto;
  }
}
.home_point .point_inner .txtarea .home_ttl {
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea .home_ttl {
    white-space: inherit;
  }
}
.home_point .point_inner .txtarea .home_ttl .bubble {
  margin-bottom: 1.0416666667vw;
  font-size: 1.5rem;
  line-height: 1.3333333333;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea .home_ttl .bubble {
    margin-bottom: 10px;
    font-size: 1.1428571429rem;
  }
}
.home_point .point_inner .txtarea .home_ttl .jp-48 {
  margin-bottom: 1.875vw;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea .home_ttl .jp-48 {
    margin-bottom: 18px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea .txt {
    font-size: 0.9285714286rem;
  }
}
.home_point .point_inner .txtarea .txt01 {
  margin-bottom: 2.1875vw;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea .txt01 {
    margin-bottom: 20px;
  }
}
.home_point .point_inner .txtarea h3.home_ttl {
  margin-bottom: 1.875vw;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea h3.home_ttl {
    margin-bottom: 18px;
  }
}
.home_point .point_inner .txtarea .txt02,
.home_point .point_inner .txtarea .txt03 {
  margin-bottom: 1.875vw;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea .txt02,
  .home_point .point_inner .txtarea .txt03 {
    margin-bottom: 18px;
  }
}
.home_point .point_inner .txtarea .txt04 {
  margin-bottom: 3.125vw;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea .txt04 {
    margin-bottom: 30px;
  }
}
.home_point .point_inner .txtarea .btn a {
  width: 100%;
  max-width: 368px;
  padding: 30px 36px;
  margin: 0 auto;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .txtarea .btn a {
    padding: 24px 36px;
  }
}
.home_point .point_inner .imgarea02 {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea02 {
    grid-area: img2;
    padding-right: 10px;
  }
}
.home_point .point_inner .imgarea02 .bg_gray {
  position: absolute;
  top: 0;
  right: 0;
  width: 53.48%;
  border-radius: 40px 0 0 40px;
  background-color: #d7dad3;
  z-index: -1;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea02 .bg_gray {
    display: none;
  }
}
.home_point .point_inner .imgarea02 .bg_gray::before {
  content: "";
  display: block;
  padding-top: 215.9%;
}
.home_point .point_inner .imgarea02 .img01 {
  position: relative;
  display: block;
  margin-top: 7.8125vw;
  margin-left: auto;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea02 .img01 {
    display: none;
  }
}
.home_point .point_inner .imgarea02 .img01 .img {
  display: block;
  width: 31.6666666667vw;
  border-radius: 20px 0 0 20px;
}
.home_point .point_inner .imgarea02 .img01 .deco01 {
  position: absolute;
  bottom: -9.8958333333vw;
  right: 7.0833333333vw;
  width: 15.5208333333vw;
}
.home_point .point_inner .imgarea02 .img02 {
  position: absolute;
  top: 0;
  left: -3.75vw;
}
@media screen and (max-width: 87.5em) {
  .home_point .point_inner .imgarea02 .img02 {
    left: -1.5625vw;
  }
}
@media screen and (max-width: 87.5em) {
  .home_point .point_inner .imgarea02 .img02 {
    left: -1.4492753623vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea02 .img02 {
    display: none;
  }
}
.home_point .point_inner .imgarea02 .img02::before {
  content: "";
  position: absolute;
  right: -8px;
  bottom: -8px;
  width: 90%;
  height: 90%;
  background-color: #f2f3f1;
  border-radius: 0 0 8px 0;
}
.home_point .point_inner .imgarea02 .img02 .img {
  position: relative;
  width: 12.9166666667vw;
  border-radius: 8px;
  z-index: 1;
}
.home_point .point_inner .imgarea02 .img02 .deco02 {
  position: absolute;
  top: 0;
  left: -4.6875vw;
  width: 5.1041666667vw;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea02 .img02 .deco02 {
    display: none;
  }
}
.home_point .point_inner .imgarea02 .img03 {
  position: relative;
  margin-top: 24.1666666667vw;
  margin-left: -0.2604166667vw;
}
@media screen and (max-width: 47.9375em) {
  .home_point .point_inner .imgarea02 .img03 {
    display: none;
  }
}
.home_point .point_inner .imgarea02 .img03 .deco03 {
  position: absolute;
  top: -12.5vw;
  left: 10vw;
  width: 15.625vw;
}
.home_point .point_inner .imgarea02 .img03 .img {
  display: block;
  width: 30.8333333333vw;
  border-radius: 20px;
}
.home_point .point_inner .imgarea02 .img_sp {
  width: 100%;
}

/* home_review
********************************************** */
.home_review {
  padding-top: 40px;
  padding-bottom: 8.3333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_review {
    padding-bottom: 80px;
  }
}
.home_review .review_box {
  position: relative;
  width: 90%;
  max-width: 1312px;
  padding: 100px 40px max(5.2083333333vw, 60px);
  margin: 0 auto;
  background-color: rgba(199, 224, 196, 0.5019607843);
  border-radius: 64px;
}
@media screen and (max-width: 109.375em) {
  .home_review .review_box {
    margin: 0 auto;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box {
    padding: 50px 20px 30px;
    border-radius: 20px;
  }
}
.home_review .review_box .bg_txt {
  position: absolute;
  bottom: -0.075em;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12.5vw;
  line-height: 1;
  letter-spacing: 0.01em;
  white-space: nowrap;
  z-index: -1;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .bg_txt {
    display: none;
  }
}
.home_review .review_box .deco01 {
  position: absolute;
  top: -5.5208333333vw;
  left: 5vw;
  width: 17.7083333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .deco01 {
    top: -88px;
    left: 0;
    width: 176px;
  }
}
.home_review .review_box .deco02 {
  position: absolute;
  left: -5.9375vw;
  bottom: -7.6041666667vw;
  width: 20.8333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .deco02 {
    display: none;
  }
}
.home_review .review_box .deco03 {
  position: absolute;
  right: -4.7916666667vw;
  bottom: 0.9375vw;
  width: 14.375vw;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .deco03 {
    right: -16px;
    bottom: -56px;
    width: 136px;
  }
}
.home_review .review_box h2.home_ttl {
  margin-bottom: 62px;
}
.home_review .review_box h2.home_ttl .en {
  position: absolute;
  top: -0.5em;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.home_review .review_box h2.home_ttl .jp {
  line-height: 1.5;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box h2.home_ttl .jp {
    font-size: 1.2857142857rem;
  }
}
.home_review .review_box .content_box {
  position: relative;
  max-width: 920px;
  padding: 24px;
  margin: 0 auto 36px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 5px 5px 0 rgba(153, 136, 119, 0.2);
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .content_box {
    padding: 26px 12px;
    margin-bottom: 24px;
    border-radius: 8px;
  }
}
.home_review .review_box .content_box .chara {
  position: absolute;
  top: -9.6875vw;
  right: -11.875vw;
  width: 16.7708333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .content_box .chara {
    top: -56px;
    left: -30px;
    right: inherit;
    width: 170px;
  }
}
.home_review .review_box .content_box .box--wrapper {
  gap: 108px;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .content_box .box--wrapper {
    gap: 0;
  }
}
.home_review .review_box .content_box .box--wrapper .rgt {
  position: relative;
  height: 110px;
  padding-top: 14px;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .content_box .box--wrapper .rgt {
    height: auto;
    padding-top: 0;
    margin-bottom: 16px;
  }
}
.home_review .review_box .content_box .box--wrapper .rgt::before {
  content: "";
  position: absolute;
  top: 0;
  right: -54px;
  width: 1px;
  height: 100%;
  background-color: #3fa037;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .content_box .box--wrapper .rgt::before {
    content: inherit;
  }
}
.home_review .review_box .content_box .box--wrapper .rgt .home_ttl {
  text-align: right;
}
.home_review .review_box .content_box .box--wrapper .rgt .home_ttl .jp-16 {
  line-height: 1;
}
.home_review .review_box .content_box .box--wrapper .rgt .home_ttl .jp-16 .en {
  display: inline-block;
}
.home_review .review_box .content_box .box--wrapper .lft .num {
  margin-right: 36px;
  color: #3fa037;
  font-size: 5rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .content_box .box--wrapper .lft .num {
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 4.2857142857rem;
  }
}
.home_review .review_box .content_box .box--wrapper .lft .stararea {
  gap: 4px;
}
.home_review .review_box .content_box .box--wrapper .lft .stararea .star {
  width: 64px;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .content_box .box--wrapper .lft .stararea .star {
    width: min(12.077294686vw, 64px);
  }
}
.home_review .review_box .btmarea .txt {
  line-height: 2;
}
.home_review .review_box .btmarea .txt.txt01 {
  margin-bottom: 30px;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .btmarea .txt.txt01 {
    margin-bottom: 18px;
  }
}
.home_review .review_box .btmarea .btn a {
  width: 100%;
  max-width: 400px;
  padding: 34px 36px;
  margin: 0 auto 10px;
  color: #fff;
  background-color: #d79db8;
  border: 1px solid #d79db8;
}
@media screen and (max-width: 47.9375em) {
  .home_review .review_box .btmarea .btn a {
    padding: 28px 36px;
  }
}
.home_review .review_box .btmarea .btn a svg {
  position: absolute;
  top: calc(50% - 6px);
  right: 16px;
  display: block;
  width: 14px;
  fill: #fff;
}
.home_review .review_box .btmarea .btn a:hover {
  color: #d79db8;
  background-color: #fff;
}
.home_review .review_box .btmarea .btn a:hover svg {
  fill: #d79db8;
}

/* home_form
********************************************** */
.home_form {
  padding: 100px 0;
}

/* home_about
********************************************** */
@media screen and (max-width: 47.9375em) {
  .home_about {
    padding-top: 40px;
  }
}
.home_about .about_inner {
  position: relative;
  padding-bottom: 10.2083333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner {
    padding-top: 80px;
  }
}
.home_about .about_inner::before {
  content: "";
  position: absolute;
  top: 11.6666666667vw;
  left: 0;
  width: 100%;
  height: calc(100% - 11.6666666667vw);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_lightgreen.jpg);
  z-index: -10;
}
.home_about .about_inner .bg_rainbow {
  position: absolute;
  top: 6.25vw;
  left: 0;
  width: 100%;
}
.home_about .about_inner .house01 {
  position: absolute;
  top: -6.875vw;
  right: 2.5vw;
  width: 25vw;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .house01 {
    top: -56px;
    left: 24px;
    right: inherit;
    width: 156px;
  }
}
.home_about .about_inner .ttlarea {
  position: relative;
  gap: 3.9583333333vw;
  width: 98%;
  min-height: 600px;
  max-width: 1720px;
  margin-bottom: 10.8333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .ttlarea {
    align-items: inherit;
    gap: 20px;
    width: 100%;
  }
}
.home_about .about_inner .ttlarea .imgarea {
  width: 58.32%;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .ttlarea .imgarea {
    width: 95%;
  }
}
.home_about .about_inner .ttlarea .imgarea img {
  border-radius: 0 40px 40px 0;
}
.home_about .about_inner .ttlarea .txtarea {
  position: relative;
  flex: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .ttlarea .txtarea {
    width: 90%;
    margin: 0 auto;
  }
}
.home_about .about_inner .ttlarea .txtarea .catch {
  position: absolute;
  left: -24px;
  bottom: 0;
  z-index: -1;
  color: #fff;
  font-size: 10vw;
  line-height: 0.8;
  opacity: 0.6;
  white-space: nowrap;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .ttlarea .txtarea .catch {
    left: 0;
    font-size: min(21.2560386473vw, 7.1428571429rem);
  }
}
.home_about .about_inner .ttlarea .txtarea .home_ttl .jp-64 {
  position: relative;
  margin-bottom: 3.9583333333vw;
  white-space: nowrap;
}
@media screen and (max-width: 100em) {
  .home_about .about_inner .ttlarea .txtarea .home_ttl .jp-64 {
    font-size: clamp(48px, 4vw, 64px);
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .ttlarea .txtarea .home_ttl .jp-64 {
    margin-bottom: 30px;
    font-size: min(8.2125603865vw, 2.8571428571rem);
  }
}
.home_about .about_inner .ttlarea .txtarea .home_ttl .jp-64::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -0.2em;
  transform: translateX(-50%);
  z-index: -1;
  width: calc(100% + 30px);
  height: 30px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/promise_line.svg);
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .ttlarea .txtarea .home_ttl .jp-64::before {
    width: calc(100% + 16px);
    height: 20px;
    background-size: auto 100%;
  }
}
.home_about .about_inner .ttlarea .txtarea .home_ttl .jp-36 {
  margin-bottom: 1.25vw;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .ttlarea .txtarea .home_ttl .jp-36 {
    margin-bottom: 12px;
  }
}
.home_about .about_inner .content_list {
  position: relative;
}
.home_about .about_inner .content_list .bg_grn {
  position: absolute;
  background-color: #eee;
  z-index: -1;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_water_green.png);
  opacity: 1;
}
.home_about .about_inner .content_list .bg_grn.bg_grn01 {
  top: -5.8333333333vw;
  right: 0;
  width: 62.5vw;
  height: 884px;
  border-radius: 60px 0 0 60px;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .bg_grn.bg_grn01 {
    width: 80vw;
    border-radius: 30px 0 0 30px;
  }
}
.home_about .about_inner .content_list .bg_grn.bg_grn02 {
  bottom: -5.46875vw;
  left: 0;
  width: 44.1666666667vw;
  height: 1420px;
  border-radius: 0 60px 60px 0;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .bg_grn.bg_grn02 {
    width: 80vw;
    bottom: 48.309178744vw;
    border-radius: 0 30px 30px 0;
  }
}
.home_about .about_inner .content_list .bg_grn.bg_grn03 {
  bottom: calc(-10vw - 82px);
  right: 0;
  width: 25vw;
  height: 528px;
  border-radius: 60px 0 0 60px;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .bg_grn.bg_grn03 {
    display: none;
  }
}
.home_about .about_inner .content_list .content_item {
  position: relative;
}
.home_about .about_inner .content_list .content_item > .flex {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item > .flex {
    align-items: inherit;
  }
}
.home_about .about_inner .content_list .content_item > .flex .imgarea {
  position: relative;
  width: 50%;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item > .flex .imgarea {
    width: 100%;
    margin-bottom: 16px;
  }
}
.home_about .about_inner .content_list .content_item > .flex .imgarea .img01 {
  border-radius: 30px;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item > .flex .imgarea .img01 {
    border-radius: 16px;
  }
}
.home_about .about_inner .content_list .content_item > .flex .txtarea {
  position: relative;
  width: 43.75%;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item > .flex .txtarea {
    width: 100%;
  }
}
.home_about .about_inner .content_list .content_item > .flex .txtarea .catch {
  position: absolute;
  top: -6px;
  left: -88px;
  z-index: -1;
  color: rgba(255, 255, 255, 0.25);
  font-size: 6.25rem;
  line-height: 0.9;
  white-space: nowrap;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 93.75em) {
  .home_about .about_inner .content_list .content_item > .flex .txtarea .catch {
    top: 40px;
    left: -40px;
    font-size: 5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item > .flex .txtarea .catch {
    top: 0;
    left: -3%;
    font-size: min(13.0434782609vw, 5.7142857143rem);
  }
}
.home_about .about_inner .content_list .content_item > .flex .txtarea .home_ttl {
  margin-bottom: 22px;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item > .flex .txtarea .home_ttl {
    margin-bottom: 16px;
  }
}
.home_about .about_inner .content_list .content_item > .flex .txtarea .home_ttl .jp-20 {
  margin-bottom: 0.8em;
  font-size: 1.25rem;
  color: #3fa037;
  letter-spacing: 0.05em;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item > .flex .txtarea .home_ttl .jp-20 {
    font-size: 1.1428571429rem;
  }
}
.home_about .about_inner .content_list .content_item > .flex .txtarea .home_ttl .jp-20 .num {
  margin-left: 2px;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item > .flex .txtarea .home_ttl .jp-20 .num {
    font-size: 2rem;
  }
}
.home_about .about_inner .content_list .content_item > .flex .txtarea .home_ttl .jp-36 {
  line-height: 1.6666666667;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item > .flex .txtarea .home_ttl .jp-36 {
    white-space: inherit;
  }
}
.home_about .about_inner .content_list .content_item > .flex .txtarea .home_ttl .jp-24 {
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item > .flex .txtarea .home_ttl .jp-24 {
    font-size: 1.2142857143rem;
    white-space: inherit;
  }
}
.home_about .about_inner .content_list .content_item > .flex .txtarea .txt:not(:last-of-type) {
  margin-bottom: 1em;
}
.home_about .about_inner .content_list .content_item:not(.last-of-type) {
  margin-bottom: 82px;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item:not(.last-of-type) {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 106.25em) {
  .home_about .about_inner .content_list .content_item.content_item01 {
    margin-bottom: 110px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item01 {
    margin-bottom: 40px;
  }
}
.home_about .about_inner .content_list .content_item.content_item01 .imgarea .img--wrapper {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item01 .imgarea .img--wrapper {
    width: 76%;
  }
}
.home_about .about_inner .content_list .content_item.content_item01 .imgarea .img--wrapper::before {
  content: "";
  position: absolute;
  bottom: -8px;
  right: -8px;
  width: 50%;
  height: 50%;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_water_green-hide.png);
  border-radius: 30px;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item01 .imgarea .img--wrapper::before {
    bottom: -4px;
    right: -4px;
    border-radius: 16px;
  }
}
.home_about .about_inner .content_list .content_item.content_item01 .imgarea .img--wrapper .img01 {
  position: relative;
  border-radius: 30px;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item01 .imgarea .img--wrapper .img01 {
    border-radius: 16px;
  }
}
.home_about .about_inner .content_list .content_item.content_item01 .imgarea .img--wrapper .img02 {
  position: absolute;
  right: calc(-18.75vw + 40px);
  bottom: calc(-12.5vw + 80px);
  width: 18.75vw;
  border-radius: 10px;
  z-index: -1;
}
@media screen and (max-width: 93.75em) {
  .home_about .about_inner .content_list .content_item.content_item01 .imgarea .img--wrapper .img02 {
    right: calc(-18.75vw + 12.5vw);
    bottom: calc(-12.5vw + 20px);
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item01 .imgarea .img--wrapper .img02 {
    right: -40%;
    bottom: -20%;
    width: 50%;
  }
}
.home_about .about_inner .content_list .content_item.content_item02 .deco01 {
  position: absolute;
  top: -5.8333333333vw;
  left: 2.5vw;
  width: 17.7083333333vw;
}
@media screen and (max-width: 106.25em) {
  .home_about .about_inner .content_list .content_item.content_item02 .deco01 {
    width: 11.7708333333vw;
    z-index: 1;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item02 .deco01 {
    top: inherit;
    left: inherit;
    right: -4%;
    bottom: -90px;
    width: 110px;
  }
}
.home_about .about_inner .content_list .content_item.content_item02 > .flex .txtarea .catch {
  left: -77px;
  color: rgba(153, 178, 146, 0.25);
}
@media screen and (max-width: 93.75em) {
  .home_about .about_inner .content_list .content_item.content_item02 > .flex .txtarea .catch {
    left: -40px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item02 > .flex .txtarea .catch {
    left: -3%;
  }
}
.home_about .about_inner .content_list .content_item.content_item03 .deco01 {
  position: absolute;
  right: 2.5vw;
  bottom: -1.875vw;
  width: 15.3125vw;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item03 .deco01 {
    right: -4%;
    bottom: -90px;
    width: 110px;
  }
}
.home_about .about_inner .content_list .content_item.content_item03 > .flex .txtarea .catch {
  left: -80px;
}
@media screen and (max-width: 93.75em) {
  .home_about .about_inner .content_list .content_item.content_item03 > .flex .txtarea .catch {
    left: -40px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item03 > .flex .txtarea .catch {
    left: -3%;
  }
}
.home_about .about_inner .content_list .content_item.content_item03 > .flex .txtarea .home_ttl .jp-36 {
  margin-bottom: 22px;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item03 > .flex .txtarea .home_ttl .jp-36 {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 106.25em) {
  .home_about .about_inner .content_list .content_item.content_item04 {
    margin-bottom: 160px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item04 {
    margin-bottom: 40px;
  }
}
.home_about .about_inner .content_list .content_item.content_item04 .deco01 {
  position: absolute;
  right: 2.5vw;
  bottom: -4.4791666667vw;
  width: 19.8958333333vw;
  z-index: 1;
}
@media screen and (max-width: 106.25em) {
  .home_about .about_inner .content_list .content_item.content_item04 .deco01 {
    bottom: -6.0416666667vw;
  }
}
@media screen and (max-width: 93.75em) {
  .home_about .about_inner .content_list .content_item.content_item04 .deco01 {
    right: 1.0416666667vw;
    bottom: -4.1666666667vw;
    width: 13.2291666667vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item04 .deco01 {
    right: -4%;
    bottom: -62px;
    width: 150px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item04 .imgarea {
    margin-bottom: 40px;
  }
}
.home_about .about_inner .content_list .content_item.content_item04 .imgarea .img--wrapper {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item04 .imgarea .img--wrapper {
    width: 76%;
    margin-left: auto;
  }
}
.home_about .about_inner .content_list .content_item.content_item04 .imgarea .img--wrapper::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: -8px;
  width: 80%;
  height: 80%;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_water_green-hide.png);
  border-radius: 30px;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item04 .imgarea .img--wrapper::before {
    bottom: -4px;
    left: -4px;
    width: 20%;
    border-radius: 16px;
  }
}
.home_about .about_inner .content_list .content_item.content_item04 .imgarea .img--wrapper .img01 {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item04 .imgarea .img--wrapper .img01 {
    border-radius: 16px;
  }
}
.home_about .about_inner .content_list .content_item.content_item04 .imgarea .img--wrapper .img02 {
  position: absolute;
  left: calc(-20.8333333333vw + 80px);
  bottom: calc(-16.6666666667vw + 12.5vw);
  width: 20.8333333333vw;
  border-radius: 10px;
  z-index: -1;
}
@media screen and (max-width: 106.25em) {
  .home_about .about_inner .content_list .content_item.content_item04 .imgarea .img--wrapper .img02 {
    left: calc(-20.8333333333vw + 140px);
    bottom: calc(-16.6666666667vw + 8vw);
  }
}
@media screen and (max-width: 93.75em) {
  .home_about .about_inner .content_list .content_item.content_item04 .imgarea .img--wrapper .img02 {
    bottom: calc(-16.6666666667vw + 70px);
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item04 .imgarea .img--wrapper .img02 {
    left: -40%;
    bottom: -10%;
    width: 50%;
  }
}
.home_about .about_inner .content_list .content_item.content_item04 > .flex .txtarea .catch {
  left: -80px;
}
@media screen and (max-width: 93.75em) {
  .home_about .about_inner .content_list .content_item.content_item04 > .flex .txtarea .catch {
    left: -40px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item04 > .flex .txtarea .catch {
    left: -3%;
  }
}
.home_about .about_inner .content_list .content_item.content_item05 > .flex .txtarea .catch {
  color: rgba(153, 178, 146, 0.25);
}
@media screen and (max-width: 47.9375em) {
  .home_about .about_inner .content_list .content_item.content_item05 > .flex .txtarea .catch {
    left: -3%;
  }
}

/* home_menu
********************************************** */
.home_menu {
  position: relative;
  padding-top: 8.3333333333vw;
  padding-bottom: 146px;
}
@media screen and (max-width: 47.9375em) {
  .home_menu {
    padding-top: 80px;
    padding-bottom: 120px;
  }
}
.home_menu .bg_gray {
  position: absolute;
  background-color: #d7dad3;
  opacity: 0.5;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .bg_gray {
    display: none;
  }
}
.home_menu .bg_gray.bg_gray01 {
  top: 56px;
  right: 0;
  width: 27.0833333333vw;
  height: 30.4166666667vw;
  border-radius: 40px 0 0 40px;
}
.home_menu .bg_gray.bg_gray02 {
  bottom: 0;
  left: 0;
  width: 25vw;
  height: 37.5vw;
  border-radius: 0 40px 40px 0;
}
.home_menu .bg-arc {
  position: absolute;
  top: -4.5833333333vw;
  left: 0;
  width: 100%;
  height: 4.5833333333vw;
}
.home_menu .house01 {
  position: absolute;
  top: -9.375vw;
  right: 10vw;
  width: 12.6041666667vw;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .house01 {
    position: absolute;
    top: -72px;
    right: 0;
    width: 121px;
  }
}
.home_menu .content_box {
  position: relative;
  max-width: 1440px;
  padding: 160px 112px 128px;
  background-color: #fff;
  border-radius: 48px;
}
@media screen and (max-width: 87.5em) {
  .home_menu .content_box {
    padding: 160px 5.8vw 5.8vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box {
    padding: 90px 20px 36px;
    border-radius: 24px;
  }
}
.home_menu .content_box .deco01 {
  position: absolute;
  top: -6.1458333333vw;
  left: -2.9166666667vw;
  width: 16.6666666667vw;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .deco01 {
    display: none;
  }
}
.home_menu .content_box .deco02 {
  position: absolute;
  bottom: -4.2708333333vw;
  right: -10vw;
  width: 14.7916666667vw;
}
@media screen and (max-width: 112.5em) {
  .home_menu .content_box .deco02 {
    right: -3.125vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .deco02 {
    left: -4%;
    right: inherit;
    bottom: -60px;
    width: 114px;
  }
}
.home_menu .content_box .home_ttl.home_ttl01 {
  position: absolute;
  top: -44px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .home_ttl.home_ttl01 {
    top: -24px;
  }
}
.home_menu .content_box .home_ttl.home_ttl01 .en {
  line-height: 0.8;
  margin-bottom: 20px;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .home_ttl.home_ttl01 .en {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .content_list {
    row-gap: 32px;
  }
}
.home_menu .content_box .content_list .content_item {
  position: relative;
  width: 31.57%;
  padding-bottom: 32px;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .content_list .content_item {
    width: 100%;
  }
}
.home_menu .content_box .content_list .content_item .post--link::before {
  z-index: 1;
}
.home_menu .content_box .content_list .content_item .imgarea {
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 16px;
  transition: all 0.2s ease-out;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .content_list .content_item .imgarea {
    background-position: top;
  }
}
.home_menu .content_box .content_list .content_item .imgarea::before {
  content: "";
  display: block;
  padding-top: 154.1%;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .content_list .content_item .imgarea::before {
    padding-top: 130%;
  }
}
.home_menu .content_box .content_list .content_item .txtarea {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
}
.home_menu .content_box .content_list .content_item .txtarea .home_ttl {
  text-align: center;
  margin-bottom: 36px;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .content_list .content_item .txtarea .home_ttl {
    margin-bottom: 18px;
  }
}
.home_menu .content_box .content_list .content_item .txtarea .home_ttl .jp-32 {
  line-height: 1.25;
  white-space: nowrap;
}
.home_menu .content_box .content_list .content_item .txtarea .btn a {
  width: 75%;
  max-width: 288px;
  min-width: 240px;
  margin: 0 auto;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .content_list .content_item .txtarea .btn a {
    min-width: inherit;
  }
}
.home_menu .content_box .content_list .content_item .txtarea .btn a:hover {
  background-color: #f5c246;
  color: #63502f;
}
.home_menu .content_box .content_list .content_item .txtarea .btn a:hover::before {
  background-color: #63502f;
}
.home_menu .content_box .content_list .content_item.wall .imgarea {
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_01.jpg);
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .content_list .content_item.wall .imgarea {
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_01_sp.jpg);
  }
}
.home_menu .content_box .content_list .content_item.roof .imgarea {
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_02.jpg);
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .content_list .content_item.roof .imgarea {
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_02_sp.jpg);
  }
}
.home_menu .content_box .content_list .content_item.other .imgarea {
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_03.jpg);
}
@media screen and (max-width: 47.9375em) {
  .home_menu .content_box .content_list .content_item.other .imgarea {
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/menu_img_03_sp.jpg);
  }
}
.home_menu .content_box .content_list .content_item.other .home_ttl .jp-32 {
  margin-bottom: 16px;
}
.home_menu .content_box .content_list .content_item:hover .imgarea {
  border-radius: 60px;
}

/* home_works
********************************************** */
.home_works {
  position: relative;
  padding-top: 8px;
  padding-bottom: 76px;
}
@media screen and (max-width: 47.9375em) {
  .home_works {
    padding-bottom: 40px;
  }
}
.home_works .deco01 {
  position: absolute;
  top: -3.125vw;
  left: calc(50% - 27.5vw);
  transform: translateX(-100%);
  width: 13.6458333333vw;
}
@media screen and (max-width: 93.75em) {
  .home_works .deco01 {
    top: 60px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_works .deco01 {
    display: none;
  }
}
.home_works .deco02 {
  position: absolute;
  top: -1.3541666667vw;
  right: calc(50% - 27.5vw);
  transform: translateX(100%);
  width: 11.3541666667vw;
}
@media screen and (max-width: 93.75em) {
  .home_works .deco02 {
    top: 26px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_works .deco02 {
    top: -94px;
    right: 5%;
    transform: inherit;
    width: 100px;
  }
}
.home_works .home_ttl {
  margin-bottom: 78px;
}
@media screen and (max-width: 47.9375em) {
  .home_works .home_ttl {
    margin-bottom: 40px;
  }
}
.home_works .home_ttl .bubble {
  padding: 0 36px;
  margin-bottom: 1.083em;
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works .home_ttl .bubble {
    padding: 0;
    margin-bottom: 0.54em;
    font-size: 1.2857142857rem;
  }
}
.home_works .home_ttl .bubble::before {
  top: 70%;
}
.home_works .home_ttl .bubble::after {
  top: 70%;
}
.home_works .home_ttl .bubble span {
  font-size: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works .home_ttl .bubble span {
    font-size: 2rem;
  }
}
.home_works .home_ttl .jp-40 {
  position: relative;
}
.home_works .home_ttl .jp-40 .catch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10rem;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #fff;
  z-index: -1;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .home_works .home_ttl .jp-40 .catch {
    font-size: min(20.2898550725vw, 10rem);
  }
}
.home_works .works_content {
  width: 96%;
  max-width: 1696px;
}
@media screen and (max-width: 47.9375em) {
  .home_works .works_content {
    width: 90%;
  }
}
.home_works .works_content .posts-works .post {
  width: 32.076%;
}
@media screen and (max-width: 47.9375em) {
  .home_works .works_content .posts-works .post {
    width: 100%;
  }
}
.home_works .works_content .posts-works .post .post--txtarea .post--info {
  flex-direction: inherit;
}
@media screen and (max-width: 47.9375em) {
  .home_works .works_content .posts-works .post .post--txtarea .post--info {
    flex-direction: column;
  }
}
.home_works .works_content .posts-works .post .post--txtarea .post--info .post--ttl {
  width: calc(100% - 124px);
}
@media screen and (max-width: 47.9375em) {
  .home_works .works_content .posts-works .post .post--txtarea .post--info .post--ttl {
    width: 100%;
  }
}
.home_works .works_content .posts-works .post .post--txtarea .post--info .detail {
  margin-left: 0;
}
@media screen and (max-width: 47.9375em) {
  .home_works .works_content .posts-works .post .post--txtarea .post--info .detail {
    margin-left: auto;
  }
}
.home_works .works_content .btn {
  margin-top: 86px;
}
@media screen and (max-width: 47.9375em) {
  .home_works .works_content .btn {
    margin-top: 42px;
  }
}
.home_works .works_content .btn a {
  min-width: 496px;
  padding: 27px 36px;
  margin: 0 auto;
  font-size: 1.25rem;
  line-height: 2;
}
@media screen and (max-width: 47.9375em) {
  .home_works .works_content .btn a {
    font-size: 1.1428571429rem;
    min-width: 300px;
    padding: 20px 36px;
  }
}
.home_works .works_content .btn a::before {
  right: 24px;
}

/* home_contact
********************************************** */
.home_contact {
  position: relative;
  padding-top: 80px;
  padding-bottom: 30px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact {
    padding-top: 70px;
    padding-bottom: 60px;
  }
}
.home_contact.home_contact_btm {
  padding-bottom: 60px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact.home_contact_btm {
    padding-bottom: 80px;
  }
}
.home_contact.home_contact_btm .bg-arc {
  position: absolute;
  top: -4.5833333333vw;
  left: 0;
  width: 100%;
  height: 4.5833333333vw;
}
.home_contact .content_box {
  position: relative;
  max-width: 1440px;
  padding: 158px 192px 68px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/cta_bg.jpg);
  border-radius: 60px;
}
@media screen and (max-width: 93.75em) {
  .home_contact .content_box {
    padding: 158px 4vw 68px;
    border-radius: 30px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box {
    width: 96%;
    padding: 68px 20px 30px;
  }
}
.home_contact .content_box .deco01 {
  position: absolute;
  top: -7.3958333333vw;
  right: -7.0833333333vw;
  width: 17.0833333333vw;
}
@media screen and (max-width: 67.5em) {
  .home_contact .content_box .deco01 {
    right: -3.6458333333vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .deco01 {
    top: -70px;
    right: max(-2%, 26px);
    width: 120px;
  }
}
.home_contact .content_box .deco02 {
  position: absolute;
  left: -7.8125vw;
  bottom: -1.6666666667vw;
  width: 15vw;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .deco02 {
    left: -8px;
    bottom: -60px;
    width: 90px;
  }
}
.home_contact .content_box .home_ttl {
  position: relative;
  margin-bottom: 16px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .home_ttl {
    margin-bottom: 36px;
  }
}
.home_contact .content_box .home_ttl .jp-40 {
  position: relative;
  line-height: 1;
}
.home_contact .content_box .home_ttl .catch {
  position: absolute;
  bottom: -0.0125em;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10rem;
  line-height: 0.8;
  letter-spacing: 0.1em;
  color: #fff;
  opacity: 0.2;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .home_ttl .catch {
    bottom: -2px;
    font-size: min(14.4927536232vw, 11.4285714286rem);
  }
}
.home_contact .content_box .contact_top {
  margin-bottom: 36px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top {
    align-items: inherit;
    margin-bottom: 20px;
  }
}
.home_contact .content_box .contact_top .lft {
  width: 50%;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft {
    width: 100%;
    margin-bottom: 32px;
  }
}
.home_contact .content_box .contact_top .lft .txtarea {
  column-gap: 18px;
  margin-bottom: 10px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft .txtarea {
    align-items: center;
    margin-bottom: 0;
    column-gap: 10px;
  }
}
.home_contact .content_box .contact_top .lft .txtarea .bubble {
  width: 168px;
  height: 124px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/contact_bg_balloon01.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft .txtarea .bubble {
    width: 110px;
    height: 82px;
  }
}
.home_contact .content_box .contact_top .lft .txtarea .bubble .bubble--txt {
  display: block;
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft .txtarea .bubble .bubble--txt {
    font-size: 1.0714285714rem;
  }
}
.home_contact .content_box .contact_top .lft .txtarea .txt {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  padding-bottom: 12px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft .txtarea .txt {
    flex: 1;
    padding-bottom: 0;
    font-size: 1.0714285714rem;
  }
}
.home_contact .content_box .contact_top .lft .tel {
  margin-bottom: 10px;
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: 0.03em;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft .tel {
    font-size: 1.7142857143rem;
  }
}
.home_contact .content_box .contact_top .lft .tel img {
  width: 60px;
  margin-right: 8px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft .tel img {
    width: 40px;
  }
}
.home_contact .content_box .contact_top .lft .tel .num {
  font-size: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft .tel .num {
    font-size: 2.8571428571rem;
    white-space: nowrap;
  }
}
.home_contact .content_box .contact_top .lft .open {
  width: 90%;
  border-radius: 8px;
  margin: 0 auto 20px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft .open {
    width: 100%;
    border-radius: 4px;
    font-size: 0.8571428571rem;
  }
}
.home_contact .content_box .contact_top .lft .btn a {
  width: 100%;
  max-width: inherit;
  padding: 44px 36px;
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft .btn a {
    padding: 14px 36px;
    font-size: 1.2857142857rem;
  }
}
.home_contact .content_box .contact_top .lft .btn a::before {
  content: "";
  position: absolute;
  top: calc(50% - 4px);
  right: 24px;
  display: block;
  width: 0.5rem;
  height: 0.75rem;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  background-color: #63502f;
  transition: var(--transit-default, all 0.4s ease-out);
}
.home_contact .content_box .contact_top .lft .btn .txt {
  font-size: 1.25rem;
  line-height: 2.2;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .lft .btn .txt {
    font-size: 0.8571428571rem;
  }
}
.home_contact .content_box .contact_top .rgt {
  width: 45.45%;
  padding-bottom: 12px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt {
    width: 100%;
    padding-bottom: 0;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_top {
  position: relative;
  margin-bottom: 14px;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_top {
    max-width: 300px;
    margin: 0 auto 30px;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_top .chara01 {
  position: absolute;
  top: -4px;
  right: -20px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_top .chara01 {
    position: absolute;
    top: inherit;
    bottom: -48px;
    right: 0;
    width: 84px;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_top .txtarea {
  position: relative;
  width: 62.08%;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_top .txtarea {
    width: 65%;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_top .txtarea::before {
  content: "";
  position: absolute;
  top: 16px;
  left: calc(50% + 2px);
  transform: translateX(-50%);
  width: 114%;
  max-width: 340px;
  aspect-ratio: 340/184;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/contact_bg_balloon02.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_top .txtarea::before {
    top: 10px;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_top .txtarea .txt_img {
  position: relative;
  width: 298px;
  margin: 0 auto;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_top .txtarea .txt_img {
    width: 100%;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_top .txtarea .info--txt {
  position: relative;
  margin-top: -4px;
  line-height: 1.375;
  letter-spacing: 0.05em;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_top .txtarea .info--txt {
    margin-top: 0;
    font-size: 0.8571428571rem;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_top .txtarea .info--txt .point {
  margin-top: 4px;
  color: #f5c246;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_top .txtarea .info--txt .point {
    font-size: 2.8571428571rem;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_top .txtarea .info--txt .point .small {
  font-size: 3.5rem;
  color: #f5c246;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_top .txtarea .info--txt .point .small {
    font-size: 2.5714285714rem;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_btm {
  padding: 44px 40px 34px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_btm {
    padding: 20px;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_btm .txt {
  margin-bottom: 20px;
  font-size: 1.5rem;
  line-height: 1.3333333333;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_btm .txt {
    margin-bottom: 10px;
    font-size: 1.1428571429rem;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_btm .line_btn a {
  display: flex;
  width: 100%;
  max-width: inherit;
  padding: 40px 36px;
  background-color: #fff;
  border: inherit;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_btm .line_btn a {
    padding: 20px 24px;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_btm .line_btn a::before {
  content: inherit;
}
.home_contact .content_box .contact_top .rgt .rgt_btm .line_btn a svg {
  fill: #3fa037;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_btm .line_btn a svg {
    width: 36px;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_btm .line_btn a span {
  margin-left: 12px;
  font-size: 1.5rem;
  color: #3fa037;
  letter-spacing: 0.05em;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_top .rgt .rgt_btm .line_btn a span {
    font-size: 1.2857142857rem;
  }
}
.home_contact .content_box .contact_top .rgt .rgt_btm .line_btn a:hover {
  background-color: #3fa037;
}
.home_contact .content_box .contact_top .rgt .rgt_btm .line_btn a:hover span {
  color: #fff;
}
.home_contact .content_box .contact_top .rgt .rgt_btm .line_btn a:hover svg {
  fill: #fff;
}
.home_contact .content_box .contact_btm .area {
  margin-bottom: 20px;
  font-size: 1.25rem;
  line-height: 2.4;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_btm .area {
    font-size: 1rem;
    line-height: 1.8;
  }
}
.home_contact .content_box .contact_btm .area .ttl {
  display: inline-block;
  padding: 0 16px;
  margin-right: 18px;
  font-size: 1rem;
  line-height: 3;
  border: 1px solid #fff;
  border-radius: 8px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact .content_box .contact_btm .area .ttl {
    display: block;
    margin-right: 0;
    margin-bottom: 16px;
    line-height: 2.2;
  }
}
.home_contact .content_box .contact_btm .txt {
  line-height: 1.375;
  letter-spacing: 0.05em;
}

/* home_staff
********************************************** */
.home_staff {
  padding-bottom: 76px;
}
@media screen and (max-width: 47.9375em) {
  .home_staff {
    padding-bottom: 40px;
  }
}
.home_staff .imgarea {
  position: relative;
  width: 100%;
}
.home_staff .imgarea img {
  width: 100%;
  max-width: inherit;
}
@media screen and (max-width: 47.9375em) {
  .home_staff .imgarea img {
    height: 320px;
    object-fit: cover;
  }
}
.home_staff .imgarea .img_top {
  position: absolute;
  top: 0;
  left: 0;
}
.home_staff .imgarea .catch {
  position: absolute;
  line-height: 0.7em;
  white-space: nowrap;
}
.home_staff .imgarea .catch.catch01 {
  top: 5.46875vw;
  right: -0.8333333333vw;
  color: rgba(153, 178, 146, 0.25);
  font-size: 9.5833333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_staff .imgarea .catch.catch01 {
    top: 7.2463768116vw;
    font-size: min(11.5942028986vw, 13.1428571429rem);
  }
}
.home_staff .imgarea .catch.catch02 {
  bottom: 2.6041666667vw;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.5);
  font-size: 8.3333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_staff .imgarea .catch.catch02 {
    bottom: 7.2463768116vw;
    font-size: min(10.1449275362vw, 11.4285714286rem);
  }
}
.home_staff .txtarea {
  position: relative;
  margin-top: -2.5vw;
  margin-bottom: 96px;
}
@media screen and (max-width: 47.9375em) {
  .home_staff .txtarea {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
  }
}
.home_staff .txtarea .deco01 {
  position: absolute;
  left: 8.6458333333vw;
  bottom: 76px;
  width: 15.8333333333vw;
}
@media screen and (max-width: 93.75em) {
  .home_staff .txtarea .deco01 {
    left: 4.1666666667vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_staff .txtarea .deco01 {
    left: 0;
    width: 90px;
  }
}
.home_staff .txtarea .deco02 {
  position: absolute;
  right: 5.7291666667vw;
  bottom: 76px;
  width: 17.34375vw;
}
@media screen and (max-width: 93.75em) {
  .home_staff .txtarea .deco02 {
    right: 1.5625vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_staff .txtarea .deco02 {
    right: 0;
    width: 90px;
  }
}
.home_staff .txtarea .txt_box .home_ttl {
  margin-bottom: 32px;
}
@media screen and (max-width: 47.9375em) {
  .home_staff .txtarea .txt_box .home_ttl {
    margin-bottom: 16px;
  }
}
.home_staff .txtarea .txt_box .home_ttl .jp-36 {
  line-height: 1.6666666667;
}
.home_staff .txtarea .txt_box .txt {
  margin-bottom: 4em;
}
@media screen and (max-width: 47.9375em) {
  .home_staff .txtarea .txt_box .txt {
    margin-bottom: 112px;
  }
}
.home_staff .txtarea .txt_box .txt .media-1700 {
  display: none;
}
@media screen and (max-width: 106.25em) {
  .home_staff .txtarea .txt_box .txt .media-1700 {
    display: block;
  }
}
.home_staff .txtarea .txt_box .btn a {
  margin: 0 auto;
}
.home_staff .staff_slider .img {
  width: 18.3333333333vw;
  padding: 0 0.8333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_staff .staff_slider .img {
    padding: 0 3.8647342995vw;
  }
}

/* home_company
********************************************** */
.home_company {
  padding-top: 80px;
  padding-bottom: 48px;
}
@media screen and (max-width: 47.9375em) {
  .home_company {
    padding-top: 40px;
  }
}
.home_company .company_inner {
  display: grid;
  grid-template-columns: 26.5625vw 46.875vw 26.5625vw;
  width: 100%;
}
@media screen and (max-width: 115.625em) {
  .home_company .company_inner {
    grid-template-columns: calc((100vw - 700px) / 2) 1fr calc((100vw - 700px) / 2);
  }
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    grid-template-areas: "txtarea txtarea" "img1 img2";
  }
}
.home_company .company_inner .imgarea01 {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea01 {
    grid-area: img1;
  }
}
.home_company .company_inner .imgarea01 .deco01 {
  position: absolute;
  top: -40px;
  left: 6.7708333333vw;
  width: 15.8333333333vw;
}
@media screen and (max-width: 100em) {
  .home_company .company_inner .imgarea01 .deco01 {
    left: 20px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea01 .deco01 {
    top: 4.8309178744vw;
    left: 4.8309178744vw;
    width: 21.7391304348vw;
  }
}
.home_company .company_inner .imgarea01 .img01 {
  position: absolute;
  top: 242px;
  left: 0;
  width: 29.1666666667vw;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea01 .img01 {
    top: 30vw;
    left: 0;
    width: 90%;
  }
}
.home_company .company_inner .imgarea01 .img01 .img01--img {
  width: 100%;
  border-radius: 0 14px 14px 0;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea01 .img01 .img01--img {
    border-radius: 0 8px 8px 0;
  }
}
.home_company .company_inner .imgarea01 .img01 .img02 {
  position: absolute;
  right: -5vw;
  bottom: -10.625vw;
  width: 20.8333333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea01 .img01 .img02 {
    right: -20%;
    bottom: 10%;
    transform: translateY(100%);
    width: 70%;
  }
}
.home_company .company_inner .imgarea01 .img01 .img02::before {
  content: "";
  position: absolute;
  top: -8px;
  left: -8px;
  width: 90%;
  height: 50%;
  background-color: #f2f3f1;
  border-radius: 14px;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea01 .img01 .img02::before {
    top: -4px;
    left: -4px;
    border-radius: 8px;
  }
}
.home_company .company_inner .imgarea01 .img01 .img02 .img02--img {
  position: relative;
  width: 100%;
  border-radius: 14px;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea01 .img01 .img02 .img02--img {
    border-radius: 8px;
  }
}
.home_company .company_inner .imgarea02 {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea02 {
    grid-area: img2;
    z-index: -1;
  }
}
.home_company .company_inner .imgarea02 .bg_gray {
  top: 272px;
  right: 0;
  width: 31.25vw;
  height: 33.3333333333vw;
  border-radius: 40px 0 0 40px;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea02 .bg_gray {
    position: inherit;
    top: inherit;
    margin-top: 20vw;
    width: 100%;
    height: 60vw;
    border-radius: 20px 0 0 20px;
  }
}
.home_company .company_inner .imgarea02 .img01 {
  position: absolute;
  top: 0;
  left: 1.25vw;
  border-radius: 14px;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea02 .img01 {
    left: inherit;
    right: 0;
    display: block;
    width: 80%;
    border-radius: 8px 0 0 8px;
  }
}
.home_company .company_inner .imgarea02 .deco01 {
  position: absolute;
  top: 600px;
  left: 5.3125vw;
  width: 16.25vw;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .imgarea02 .deco01 {
    top: 57.9710144928vw;
    left: 10.1449275362vw;
    width: 24.154589372vw;
  }
}
.home_company .company_inner .txtarea {
  padding-top: 80px;
  padding-bottom: 156px;
  z-index: 1;
}
@media screen and (max-width: 100em) {
  .home_company .company_inner .txtarea {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .txtarea {
    grid-area: txtarea;
    width: 90%;
    padding: 0;
    margin: 0 auto;
  }
}
.home_company .company_inner .txtarea .home_ttl {
  position: relative;
  padding-top: 72px;
  margin-bottom: 52px;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .txtarea .home_ttl {
    padding-top: 20px;
    margin-bottom: 24px;
  }
}
.home_company .company_inner .txtarea .home_ttl .catch {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12.5rem;
  line-height: 0.7em;
  letter-spacing: 0.1em;
  color: #fff;
  white-space: nowrap;
  z-index: -1;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .txtarea .home_ttl .catch {
    font-size: min(19.3236714976vw, 48.309178744vw);
  }
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .txtarea .home_ttl .jp-48 {
    font-size: 1.7142857143rem;
  }
}
.home_company .company_inner .txtarea .txt {
  margin-bottom: 88px;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .txtarea .txt {
    margin-bottom: 40px;
  }
}
.home_company .company_inner .txtarea .txt .media-1850 {
  display: none;
}
@media screen and (max-width: 115.625em) {
  .home_company .company_inner .txtarea .txt .media-1850 {
    display: block;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .txtarea .txt .media-1850 {
    display: none;
  }
}
.home_company .company_inner .txtarea .txt p {
  white-space: nowrap;
}
@media screen and (max-width: 115.625em) {
  .home_company .company_inner .txtarea .txt p {
    text-shadow: 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF, 0 0 3px #FFFFFF;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .txtarea .txt p {
    white-space: inherit;
    text-shadow: inherit;
  }
}
.home_company .company_inner .txtarea .txt p:first-child {
  margin-bottom: 2.5em;
}
@media screen and (max-width: 47.9375em) {
  .home_company .company_inner .txtarea .txt p:first-child {
    margin-bottom: 1.5em;
  }
}
.home_company .company_inner .txtarea .btn a {
  margin: 0 auto;
}

/* home_info
********************************************** */
.home_info {
  padding-top: 96px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_lightgreen.jpg);
}
@media screen and (max-width: 47.9375em) {
  .home_info {
    padding-top: 48px;
  }
}
.home_info .home_ttl.inner {
  position: relative;
  width: 96%;
  max-width: 1696px;
  margin-bottom: 36px;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_info .home_ttl.inner {
    width: 90%;
    margin-bottom: 10px;
  }
}
.home_info .info_top {
  width: 96%;
  max-width: 1696px;
}
.home_info .info_top .box {
  position: relative;
  background-color: #fff;
  border-radius: 32px;
}
.home_info .info_top .box .catch {
  position: absolute;
  top: -126px;
  right: -16px;
  font-size: 11.25rem;
  line-height: 0.7em;
  letter-spacing: 0.05em;
  color: #fff;
  opacity: 0.25;
}
@media screen and (max-width: 93.75em) {
  .home_info .info_top .box .catch {
    top: -96px;
    font-size: 8.75rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_top .box .catch {
    top: -68px;
    font-size: 7rem;
  }
}
.home_info .info_top .box .home_ttl {
  padding-bottom: 12px;
}
.home_info .info_top .box .home_ttl .bubble::before {
  left: -32px;
  width: 20px;
  height: 36px;
}
.home_info .info_top .box .home_ttl .bubble::after {
  right: -32px;
  width: 20px;
  height: 36px;
}
.home_info .info_top .box .btn a {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}
.home_info .info_top .box.news_box {
  width: 41.03%;
  padding: 54px 64px 64px;
}
@media screen and (max-width: 93.75em) {
  .home_info .info_top .box.news_box {
    padding: 34px 44px 44px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_top .box.news_box {
    width: 100%;
    padding: 30px 20px;
  }
}
.home_info .info_top .box.news_box .deco01 {
  position: absolute;
  left: -1.25vw;
  bottom: -3.75vw;
  width: 8.9583333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_top .box.news_box .deco01 {
    display: none;
  }
}
.home_info .info_top .box.news_box .home_ttl {
  border-bottom: 1px solid #d9dfd3;
}
.home_info .info_top .box.news_box .news_list {
  margin-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_top .box.news_box .news_list {
    margin-bottom: 32px;
  }
}
.home_info .info_top .box.news_box .news_list .post {
  height: 136px;
  padding: 30px 20px 0;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_top .box.news_box .news_list .post {
    height: 96px;
    padding: 20px 10px 0;
  }
}
.home_info .info_top .box.news_box .news_list .post .post--date {
  margin-bottom: 0.75em;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_top .box.news_box .news_list .post .post--date {
    margin-bottom: 0.5em;
  }
}
.home_info .info_top .box.blog_box {
  width: 56.13%;
  padding: 54px 64px 56px;
}
@media screen and (max-width: 93.75em) {
  .home_info .info_top .box.blog_box {
    padding: 34px 44px 34px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_top .box.blog_box {
    margin-top: 100px;
    width: 100%;
    padding: 30px 20px;
  }
}
.home_info .info_top .box.blog_box .deco01 {
  position: absolute;
  top: -4.2708333333vw;
  left: 2.7083333333vw;
  width: 8.5416666667vw;
}
@media screen and (max-width: 87.5em) {
  .home_info .info_top .box.blog_box .deco01 {
    left: 12px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_top .box.blog_box .deco01 {
    top: -18.8405797101vw;
    left: 10px;
    width: 18.8405797101vw;
  }
}
.home_info .info_top .box.blog_box .deco02 {
  position: absolute;
  right: -0.7291666667vw;
  bottom: -3.5416666667vw;
  width: 12.3958333333vw;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_top .box.blog_box .deco02 {
    right: 10px;
    bottom: -16.9082125604vw;
    width: 24.154589372vw;
  }
}
.home_info .info_top .box.blog_box .posts-blog {
  padding-bottom: 30px;
  margin-bottom: 48px;
  border-bottom: 1px solid #d9dfd3;
}
.home_info .info_btm {
  position: relative;
  padding-top: 176px;
  padding-bottom: 288px;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_btm {
    padding-top: 90px;
    padding-bottom: 0;
  }
}
.home_info .info_btm .bg_grn {
  top: 112px;
  right: 0;
  width: 60vw;
  height: 35.4166666667vw;
  border-radius: 32px 0 0 32px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/pt_water_green-hide.png);
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_btm .bg_grn {
    display: none;
  }
}
.home_info .info_btm .img01 {
  position: absolute;
  left: 0;
  bottom: 40px;
  border-radius: 0 32px 0 0;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_btm .img01 {
    position: inherit;
    left: inherit;
    bottom: inherit;
    width: 80%;
    border-radius: 0 24px 0 0;
  }
}
.home_info .info_btm .insta_box {
  position: relative;
  max-width: 1280px;
  z-index: 1;
}
.home_info .info_btm .insta_box .txtarea {
  width: 216px;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_btm .insta_box .txtarea {
    width: 100%;
  }
}
.home_info .info_btm .insta_box .txtarea .insta_img {
  margin-left: 4px;
  margin-bottom: 26px;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_btm .insta_box .txtarea .insta_img {
    margin-bottom: 16px;
  }
}
.home_info .info_btm .insta_box .txtarea .txt {
  padding: 0 4px;
  margin-bottom: 2.25em;
  line-height: 1.75;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_btm .insta_box .txtarea .txt {
    margin-bottom: 1em;
  }
}
.home_info .info_btm .insta_box .insta_btn {
  width: 100%;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_btm .insta_box .insta_btn {
    margin-bottom: 40px;
  }
}
.home_info .info_btm .insta_box .insta_btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 21px 46px 21px 36px;
  background-color: #fff;
  border: inherit;
}
.home_info .info_btm .insta_box .insta_btn a .font-en {
  display: block;
  margin-left: 8px;
  font-size: 0.875rem;
}
.home_info .info_btm .insta_box .insta_btn a:hover {
  background-color: #f5c246;
}
.home_info .info_btm .insta_box .imgarea {
  position: relative;
  width: 76.875%;
  height: 488px;
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_btm .insta_box .imgarea {
    width: 100%;
    height: inherit;
    margin-bottom: 24px;
  }
}
.home_info .info_btm .insta_box .imgarea .deco01 {
  position: absolute;
  bottom: 0;
  right: -10.9375vw;
  width: 9.8958333333vw;
}
@media screen and (max-width: 103.125em) {
  .home_info .info_btm .insta_box .imgarea .deco01 {
    bottom: -216px;
    right: -3.125vw;
    width: 124px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_info .info_btm .insta_box .imgarea .deco01 {
    bottom: -276px;
    width: 100px;
  }
}
.home_info .info_btm .insta_box .imgarea #sb_instagram .sbi_photo_wrap {
  overflow: hidden;
}

/* 動き
********************************************** */
.anime-dly1 {
  animation-delay: 0.5s;
}

.anime-dly2 {
  animation-delay: 1s;
}

.anime-dly3 {
  animation-delay: 1.5s;
}

.anime-dly4 {
  animation-delay: 2s;
}

.anime-dly5 {
  animation-delay: 2.5s;
}

.hero.wayshow .fadein,
.hero_sp_top.wayshow .fadein {
  animation-name: FadeIn;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}
.hero.wayshow .fadein_top,
.hero_sp_top.wayshow .fadein_top {
  animation-name: FadeInTop;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}
.hero.wayshow .fadein_btm,
.hero_sp_top.wayshow .fadein_btm {
  animation-name: FadeInBtm;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}
.hero.wayshow .fadein_rgt,
.hero_sp_top.wayshow .fadein_rgt {
  animation-name: FadeInRgt;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}
.hero.wayshow .fadein_lft,
.hero_sp_top.wayshow .fadein_lft {
  animation-name: FadeInLft;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}

.fadein {
  opacity: 0;
}
.fadein.wayshow {
  animation-name: FadeIn;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}

.fadein_top {
  opacity: 0;
}
.fadein_top.wayshow {
  animation-name: FadeInTop;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}

.fadein_btm {
  opacity: 0;
}
.fadein_btm.wayshow {
  animation-name: FadeInBtm;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}

.fadein_rgt {
  opacity: 0;
}
.fadein_rgt.wayshow {
  animation-name: FadeInRgt;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}

.fadein_lft {
  opacity: 0;
}
.fadein_lft.wayshow {
  animation-name: FadeInLft;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}

.buruburu.wayshow {
  animation-name: Buruburu;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.4, 0.4, 0, 1);
  animation-fill-mode: forwards;
}

/* cursor
**************************************** */
#cursor {
  position: fixed;
  z-index: 99999;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  pointer-events: none;
  display: none;
  color: #fff;
  font-family: var(--font-en);
  letter-spacing: 0.1em;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(63, 160, 55, 0.2509803922);
}
@media screen and (max-width: 47.9375em) {
  #cursor {
    display: none;
  }
}

#cursor.is-viewmore {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: cursor_more 0.2s linear forwards;
}

/* home_news
********************************************** */
.home_news {
  padding: 40px 0;
  background-color: #fff;
  border-bottom: 1px solid #EEE;
}
@media screen and (max-width: 47.9375em) {
  .home_news {
    padding: 20px 5%;
  }
}

.home_news .news_list {
  flex: 1;
}

.news_list--ttl {
  display: block;
  position: relative;
  margin-right: 40px;
  padding: 0;
  line-height: 1.2;
}
@media screen and (max-width: 47.9375em) {
  .news_list--ttl {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: 16px;
  }
}

/* home_ttl
********************************************** */
.home_ttl .jp {
  display: block;
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  letter-spacing: 0.05em;
}
.home_ttl .jp.jp-24 {
  font-size: 1.5rem;
  line-height: 1.6666666667;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .jp.jp-24 {
    font-size: 1.2857142857rem;
  }
}
.home_ttl .jp.jp-32 {
  font-size: 2rem;
  line-height: 1.75;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .jp.jp-32 {
    font-size: 1.4285714286rem;
  }
}
.home_ttl .jp.jp-36 {
  font-size: 2.25rem;
  line-height: 1.5555555556;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .jp.jp-36 {
    font-size: 1.7142857143rem;
  }
}
.home_ttl .jp.jp-40 {
  font-size: 2.5rem;
  line-height: 1.8;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .jp.jp-40 {
    font-size: 2rem;
  }
}
.home_ttl .jp.jp-48 {
  font-size: 3rem;
  line-height: 1.5;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .jp.jp-48 {
    font-size: 2.2857142857rem;
  }
}
.home_ttl .jp.jp-64 {
  font-size: 4rem;
  line-height: 1.25;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .jp.jp-64 {
    font-size: 2.5714285714rem;
  }
}
.home_ttl .en {
  display: block;
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 400;
  letter-spacing: 0.1em;
}
.home_ttl .en.en-24 {
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .en.en-24 {
    font-size: 1.2857142857rem;
  }
}
.home_ttl .en.en-48 {
  font-size: 3rem;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .en.en-48 {
    font-size: 2.2857142857rem;
  }
}
.home_ttl .en.en-120 {
  font-size: 7.5rem;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .en.en-120 {
    font-size: 4.2857142857rem;
  }
}
.home_ttl .bubble {
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  line-height: 1.3333333333;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .bubble {
    font-size: 1.1428571429rem;
  }
}
.home_ttl .bubble::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
  width: 34px;
  height: 56px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl_deco_left.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .bubble::before {
    width: 20px;
    height: 38px;
  }
}
.home_ttl .bubble::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
  width: 34px;
  height: 56px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl_deco_right.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .home_ttl .bubble::after {
    width: 20px;
    height: 38px;
  }
}

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.page_ttl--bg-wrap {
  height: 500px;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--bg-wrap {
    height: 150px;
  }
}

.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90vw;
  z-index: -1;
  background-size: cover;
  background-position: center;
  border-radius: 40px;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--bg {
    border-radius: 16px;
  }
}

.page_ttl-jp {
  color: #fff;
  font-size: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-jp {
    font-size: 1.1428571429rem;
  }
}

.page_ttl-en {
  display: block;
  color: #fff;
  text-transform: uppercase;
  font-size: 6.25rem;
  line-height: 1;
  letter-spacing: 0.1em;
  white-space: nowrap;
  text-shadow: 1px 1px 0 #63502f, -1px -1px 0 #63502f, -1px 1px 0 #63502f, 1px -1px 0 #63502f, 0px 1px 0 #63502f, 0 -1px 0 #63502f, -1px 0 0 #63502f, 1px 0 0 #63502f;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-en {
    margin-bottom: 4px;
    font-size: 2.8571428571rem;
    white-space: inherit;
  }
}

.page_ttl--list_wrap {
  background-color: rgba(63, 160, 55, 0.25);
}

@media print, screen and (min-width: 48em) {
  .page_ttl--list li {
    min-height: 96px;
  }
}

.page_ttl--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em 2.5em;
  line-height: var(--line-height-hdr, 1.4);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--list a {
    padding: 1em 1.5em;
  }
}
.page_ttl--list a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.875rem;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.375rem;
  background-color: var(--clr-body, #000);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--list a::before {
    right: 0.7142857143rem;
  }
}
.page_ttl--list a:hover {
  color: var(--clr-main, #3fa037);
}
.page_ttl--list a:hover::before {
  background-color: var(--bg-main, #3fa037);
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  color: #63502f;
  line-height: 1.5;
  letter-spacing: 0.05em;
}

.ttl-01 {
  position: relative;
  font-size: 3rem;
  padding-bottom: 1.458em;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 {
    font-size: 2.1428571429rem;
  }
}
.ttl-01::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 44px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl-deco.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01::before {
    width: 60px;
    height: 33px;
  }
}
.ttl-01.font-en {
  color: #fff;
  font-size: 6.25rem;
  line-height: 1;
  text-shadow: 1px 1px 0 #63502f, -1px -1px 0 #63502f, -1px 1px 0 #63502f, 1px -1px 0 #63502f, 0px 1px 0 #63502f, 0 -1px 0 #63502f, -1px 0 0 #63502f, 1px 0 0 #63502f;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01.font-en {
    font-size: 2.8571428571rem;
  }
}

.ttl-02 {
  font-size: 2.5rem;
  font-family: var(--font-jp);
}
@media screen and (max-width: 47.9375em) {
  .ttl-02 {
    font-size: 2rem;
  }
}

.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .ttl-03 {
    font-size: 1.7142857143rem;
  }
}

.ttl-03 {
  font-size: 2.25rem;
  font-family: var(--font-jp);
}
@media screen and (max-width: 47.9375em) {
  .ttl-03 {
    font-size: 1.7142857143rem;
  }
}

.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03 {
  position: relative;
  z-index: 1;
}

.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03:not(:last-child) {
  margin-bottom: 1em;
}

.ttl-04 {
  font-size: 1.5rem;
}

.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec .column-3 .ttl-03,
  .lps_sec .column-4 .ttl-03 {
    font-size: 1.7142857143rem;
  }
}

/* section_ttl
********************************************** */
.section_ttl {
  color: #63502f;
  font-size: 2.5rem;
  font-family: var(--font-jp);
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .section_ttl {
    font-size: 2rem;
  }
}

.section_ttl-02 {
  padding: 0 0.375em 0.25em 0.375em;
  margin-bottom: 0.75em;
  color: #63502f;
  font-size: 2rem;
  font-family: var(--font-jp);
  line-height: 1.5;
  letter-spacing: 0.05em;
  border-bottom: 1px solid #63502f;
}
@media screen and (max-width: 47.9375em) {
  .section_ttl-02 {
    font-size: 1.7142857143rem;
  }
}

/* ##############################################################################

    ARCHIVE

############################################################################## */
/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
  overflow: hidden;
}

/* --- post --- */
.post {
  position: relative;
}
.post .post--img {
  overflow: hidden;
}
.post .post--img img {
  transition: var(--transit-default, all 0.4s ease-out);
}
.post:hover .post--img img {
  transform: scale(1.1);
}

.post--img .img-contain {
  max-width: 80%;
}

.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.post--date {
  color: #3fa037;
  font-family: var(--font-en);
  line-height: 1;
  letter-spacing: 0.05em;
}

.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}

.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cat_list a {
  display: inline-block;
  vertical-align: middle;
  padding: 0 1.625em;
  line-height: 2.5;
  margin: 2px 4px;
  font-family: var(--font-jp);
  text-align: center;
  background-color: #f5c246;
  border: 1px solid #f5c246;
  border-radius: 8px;
  float: left;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .cat_list a {
    line-height: 2;
    border-radius: 4px;
  }
}
.cat_list a:hover {
  background-color: #fff;
}

.post--ttl {
  font-family: var(--font-jp);
}

@media screen and (max-width: 68.75em) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}
/* side_column
**************************************** */
.side_column {
  width: 264px;
}
@media screen and (max-width: 68.75em) {
  .side_column {
    width: 100%;
  }
}

.side_section:not(:last-child) {
  margin-bottom: 64px;
}

.side--ttl {
  margin-bottom: 16px;
  padding: 0.8em 0.8em;
  color: #fff;
  font-size: 1.25rem;
  font-family: var(--font-jp);
  line-height: 1;
  border-radius: 4px;
  background-color: #3fa037;
}

/* --- list --- */
.side--list a {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  padding-left: 0.5em;
  display: block;
  line-height: 1.4;
  border-bottom: solid 1px #d7dad3;
}
.side--list a:hover {
  color: #3fa037;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 1rem;
}

.posts-side .post--img {
  width: 100%;
  aspect-ratio: 256/184;
  margin-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
}

.posts-side .post--date {
  margin-bottom: 4px;
  font-size: 0.875rem;
}

.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.side_column .archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 2.4;
}

.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all 0.4s ease-out);
}

.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}

.side_column .archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}

.archive-pulldown .archive_list {
  position: relative;
}

.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}

.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}

.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}

.archive-pulldown .archive_list--label {
  margin-right: 8px;
}

.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: #fff;
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}

.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}

.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* blog
**************************************** */
.posts-blog {
  flex-wrap: wrap;
  column-gap: 3.4%;
  row-gap: 40px;
}
.posts-blog .post {
  width: 31.0666666667%;
}
@media screen and (max-width: 47.9375em) {
  .posts-blog .post {
    width: 100%;
  }
}
.posts-blog .post .post--img {
  aspect-ratio: 256/184;
  margin-bottom: 12px;
  background-color: #d9dfd3;
  border-radius: 8px;
}
.posts-blog .post .post--date {
  margin-bottom: 4px;
}
.posts-blog .post .cat_list {
  margin-bottom: 10px;
}
.posts-blog .post .cat_list a {
  padding: 0 12px;
  font-size: 0.875rem;
  line-height: 2.1428571429;
  letter-spacing: 0.05em;
  border-radius: 4px;
}
.posts-blog .post .post--date {
  line-height: 1.75;
}

/* news
**************************************** */
/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}

.news--archive li {
  margin-left: 8px;
  margin-right: 8px;
}

.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list .post {
  padding: 30px 20px;
  margin-bottom: 0;
  border-bottom: 1px dotted #3fa037;
}
@media screen and (max-width: 47.9375em) {
  .news_list .post {
    padding: 20px 16px;
    display: block;
  }
}

.news_list .post--date {
  min-width: 96px;
}
@media screen and (max-width: 47.9375em) {
  .news_list .post--date {
    min-width: 86px;
  }
}

.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1d7fb7);
}

.news_list .post--link:hover svg {
  fill: var(--clr-main, #1d7fb7);
}

.news_list .post--info {
  margin-bottom: 0.75em;
}

.news_list .cat_list a {
  min-width: 96px;
  padding: 0 1em;
  margin-right: 4px;
  font-size: 1rem;
  line-height: 1.6;
}

.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 16px;
}

.news_list .post--ttl {
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0.05em;
  text-align: justify;
}

/* works
**************************************** */
.archive-works .main_column {
  overflow: inherit;
}

.posts-works {
  gap: 62px 1.886%;
  flex-wrap: wrap;
}
@media screen and (max-width: 47.9375em) {
  .posts-works {
    row-gap: 48px;
  }
}
.posts-works .post {
  width: 49.057%;
}
@media screen and (max-width: 47.9375em) {
  .posts-works .post {
    width: 100%;
  }
}
.posts-works .post .post--img {
  position: relative;
}
.posts-works .post .post--img::before {
  content: "";
  display: block;
  padding-top: 88.23%;
}
.posts-works .post .post--img img {
  position: absolute;
}
.posts-works .post .post--txtarea {
  padding: 0 5.8%;
}
.posts-works .post .post--txtarea .cat_list {
  margin-top: -24px;
  margin-bottom: 10px;
}
.posts-works .post .post--txtarea .cat_list a {
  min-width: inherit;
}
.posts-works .post .post--txtarea .post--info {
  flex-direction: column;
  row-gap: 20px;
}
.posts-works .post .post--txtarea .post--info .post--ttl {
  width: 100%;
  font-size: 1.25rem;
  line-height: 2;
  letter-spacing: 0.05em;
}
.posts-works .post .post--txtarea .post--info .post--ttl .post--link::before {
  height: calc(100% + 16px);
}
.posts-works .post .post--txtarea .post--info .detail {
  position: relative;
  display: block;
  width: fit-content;
  padding: 0 14px;
  margin-left: auto;
  line-height: 1;
  letter-spacing: 0.05em;
  font-family: var(--font-jp);
  white-space: nowrap;
}
.posts-works .post .post--txtarea .post--info .detail::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 24px;
  background-color: #3fa037;
  border-radius: 6px;
  opacity: 0.25;
  z-index: -1;
}

/* ##############################################################################

    SEARCH

############################################################################## */
#search-list .section_pdg {
  padding-top: 50px;
  padding-bottom: 50px;
}

#search-list .ttl-01 {
  margin: 70px 0 0;
}

/* ##############################################################################

    SINGLE

############################################################################## */
/* --- カスタム詳細ページ共通 --- */
.single .post--info {
  row-gap: 8px;
}

/* --- single-works --- */
.single-works .main_column {
  overflow: inherit;
}
@media screen and (max-width: 47.9375em) {
  .single-works .caution small {
    font-size: 0.7857142857rem;
  }
}
.single-works .works_info .table2 {
  width: 100%;
}
.single-works .works_info .table2 th {
  width: 210px;
}
@media screen and (max-width: 47.9375em) {
  .single-works .works_info .table2 th {
    width: inherit;
  }
}
.single-works .works_befoaf .content_wrapper {
  position: relative;
}
.single-works .works_befoaf .content_wrapper:not(:last-child) {
  margin-bottom: 20px;
}
@media screen and (max-width: 47.9375em) {
  .single-works .works_befoaf .content_wrapper:not(:last-child) {
    margin-bottom: 48px;
  }
}
.single-works .works_befoaf .content_wrapper .content {
  position: relative;
  width: calc((100% - 80px) / 2);
}
@media screen and (max-width: 47.9375em) {
  .single-works .works_befoaf .content_wrapper .content {
    width: 100%;
  }
}
.single-works .works_befoaf .content_wrapper .content span {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding: 0 1em;
  font-size: 1.125rem;
  font-family: var(--font-jp);
  letter-spacing: 0.05em;
  line-height: 1.6;
  background-color: #f5c246;
  border-radius: 8px 0 0 0;
}
.single-works .works_befoaf .content_wrapper .content img {
  width: 100%;
  margin: 0;
  border-radius: 8px;
}
@media screen and (max-width: 47.9375em) {
  .single-works .works_befoaf .content_wrapper .content.left {
    margin-bottom: 40px;
  }
}
.single-works .works_befoaf .content_wrapper .content.left::before {
  content: "";
  position: absolute;
  top: calc(50% + 12px);
  right: -50px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 20px;
  border-color: transparent transparent transparent #f5c246;
}
@media screen and (max-width: 47.9375em) {
  .single-works .works_befoaf .content_wrapper .content.left::before {
    top: inherit;
    left: 50%;
    bottom: -32px;
    right: inherit;
    transform: translateX(-50%);
    border-width: 20px 25px 0 25px;
    border-color: #f5c246 transparent transparent transparent;
  }
}
.single-works .works_report .works_report_inner {
  gap: 20px;
  flex-wrap: wrap;
}
.single-works .works_report .works_report_inner .item {
  width: calc((100% - 40px) / 3);
}
@media screen and (max-width: 47.9375em) {
  .single-works .works_report .works_report_inner .item {
    width: 100%;
  }
}
.single-works .works_report .works_report_inner .item .img {
  margin-bottom: 12px;
}
@media screen and (max-width: 47.9375em) {
  .single-works .works_report .works_report_inner .item .img {
    margin-bottom: 6px;
  }
}
.single-works .works_report .works_report_inner .item .img img {
  margin: 0;
  border-radius: 8px;
}
.single-works .works_report .works_report_inner .item .txt {
  font-size: 1.25rem;
  line-height: 1.5;
}
.single-works .works_comment .mce-content-body,
.single-works .works_voice .mce-content-body,
.single-works .works_free .mce-content-body {
  padding: 40px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 47.9375em) {
  .single-works .works_comment .mce-content-body,
  .single-works .works_voice .mce-content-body,
  .single-works .works_free .mce-content-body {
    padding: 20px;
    border-radius: 10px;
  }
}

/* --- single-blog --- */
/* 見出し */
.single-blog .main_column {
  padding: 40px;
  border-radius: 20px;
  background-color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .single-blog .main_column {
    padding: 20px;
    border-radius: 10px;
  }
}
.single-blog .main_column .blog--info h2,
.single-blog .main_column .blog--info h3,
.single-blog .main_column .blog--info h4 {
  font-family: var(--font-jp);
  line-height: 1.5;
  letter-spacing: 0.05em;
}
.single-blog .main_column .blog--info h2:not(:first-child),
.single-blog .main_column .blog--info h3:not(:first-child),
.single-blog .main_column .blog--info h4:not(:first-child) {
  margin-top: 2em;
}
.single-blog .main_column .blog--info h2:not(:last-child),
.single-blog .main_column .blog--info h3:not(:last-child),
.single-blog .main_column .blog--info h4:not(:last-child) {
  margin-bottom: 0.6em;
}
.single-blog .main_column .blog--info h2 {
  font-size: 2rem;
  padding: 0 0.375em 0.25em 0.375em;
  border-bottom: 1px solid #63502f;
}
@media screen and (max-width: 47.9375em) {
  .single-blog .main_column .blog--info h2 {
    font-size: 1.7142857143rem;
  }
}
.single-blog .main_column .blog--info h3 {
  font-size: 1.5rem;
  color: var(--clr-main, #1d7fb7);
}
@media screen and (max-width: 47.9375em) {
  .single-blog .main_column .blog--info h3 {
    font-size: 1.4285714286rem;
  }
}
.single-blog .main_column .blog--info h4:not(.ttl-04) {
  position: relative;
  font-size: 1.125rem;
  padding-left: 1em;
}
@media screen and (max-width: 47.9375em) {
  .single-blog .main_column .blog--info h4:not(.ttl-04) {
    font-size: 1.1428571429rem;
  }
}
.single-blog .main_column .blog--info h4:not(.ttl-04)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.3em;
  height: 100%;
  background-color: var(--clr-main, #1d7fb7);
  border-radius: 0.15em;
}
.single-blog .main_column .blog--info table {
  border-collapse: collapse;
}
.single-blog .main_column .blog--info table tr td {
  border: 1px solid #402c1a;
}
.single-blog .main_column .blog--info .aligncenter {
  text-align: center;
}
.single-blog .main_column .blog--info .mce-content-body a {
  display: block !important;
}
.single-blog .blog--related .post--date {
  margin-bottom: 10px;
}
.single-blog .blog--related .cat_list {
  margin-bottom: 10px;
}
.single-blog .blog--related .cat_list a {
  padding: 0 1em;
  line-height: 2;
}

/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: 2rem;
}
.eyecatch img {
  width: 100%;
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}

div#ez-toc-container {
  padding: 1.5rem;
  background-color: #fafafa;
}

.ez-toc-title-container {
  margin-bottom: 0.625rem;
}

div#ez-toc-container p.ez-toc-title {
  color: var(--clr-body, #333);
  font-weight: bold;
  font-size: 1.25rem;
}

#ez-toc-container.counter-hierarchy ul {
  font-size: 1.0625rem;
}

#ez-toc-container.counter-hierarchy ul li {
  margin-top: 0.5rem;
}

#ez-toc-container.counter-hierarchy ul ul {
  margin-left: 1rem;
}

#ez-toc-container.counter-hierarchy ul ul a {
  position: relative;
  display: inline-block;
  padding-left: 1rem;
}
#ez-toc-container.counter-hierarchy ul ul a::before {
  content: "";
  position: absolute;
  top: 0.625rem;
  left: 0;
  width: 0.5rem;
  height: 0;
  border-bottom: 1px solid;
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}

#pass input[name=post_password] {
  padding: 0.3125rem;
  border: solid 1px #aaa;
  outline: none;
}

#pass input[type=submit] {
  padding: 0.25rem 0.375rem;
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}

#pass input[type=submit]:hover {
  opacity: 0.7;
}

/* blog--share
**************************************** */
.blog--share {
  margin-top: 2rem;
}

.blog--share_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.375rem;
}
@media screen and (max-width: 47.9375em) {
  .blog--share_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.blog--share_list a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  padding: 0.5em;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: bold;
  border: 1px solid #ddd;
}

.blog--share_list li.twitter a {
  color: #2BA1F2;
}
.blog--share_list li.facebook a {
  color: #2477F2;
}
.blog--share_list li.line a {
  color: #27C754;
}
.blog--share_list li.pocket a {
  color: #EF4056;
}
.blog--share_list li.linkedin a {
  color: #2867B2;
}
.blog--share_list li.hatena a {
  color: #29A4DE;
}

.blog--share_list img {
  margin-right: 0.625rem;
}

.blog--share_list a:hover {
  opacity: 0.5;
}

/* blog--related
**************************************** */
.posts_scroll {
  overflow: auto;
  padding-bottom: 2rem;
}

.posts_scroll .post {
  display: block;
  width: 46%;
  flex-shrink: 0;
}
@media screen and (max-width: 47.9375em) {
  .posts_scroll .post {
    width: 70%;
  }
}

.posts_scroll .post:not(:last-child) {
  margin-right: 1rem;
}

.posts_scroll .post--img {
  margin-bottom: 1rem;
  border-radius: 8px;
  aspect-ratio: 256/184;
  overflow: hidden;
}

.posts_scroll .post--txt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.posts_scroll::-webkit-scrollbar {
  height: 0.375rem;
}

.posts_scroll::-webkit-scrollbar-thumb {
  background-color: #999;
}

.posts_scroll::-webkit-scrollbar-track-piece {
  background-color: #eee;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi {
    margin-top: 24px;
  }
}

.wp-pagenavi .pages {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.wp-pagenavi a {
  position: relative;
  padding: 0.5em 1em;
  color: #63502f;
  background-color: var(--btn-bg-main);
  border: 1px solid #f5c246;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wp-pagenavi a:hover {
  background-color: #fff;
}

.wp-pagenavi > *:not(.pages) {
  max-width: 38.4615384615%;
}

.archive .wp-pagenavi > *:not(.pages) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .archive .wp-pagenavi > *:not(.pages) {
    width: 2.2857142857rem;
    height: 2.2857142857rem;
  }
}

.wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
  width: 40px;
}

.wp-pagenavi > *:not(.pages) {
  margin-bottom: 4px;
}

.wp-pagenavi > *:not(.pages):not(:last-child) {
  margin-right: 4px;
}

.wp-pagenavi .extend {
  width: 24px !important;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 8px;
  height: 8px;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}

.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=prev]::before {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=next]::before {
  transform: rotate(-135deg);
}

.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -6px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: -5px;
  }
}

.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -6px;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: -5px;
  }
}

@media screen and (max-width: 47.9375em) {
  .wp-pagenavi-single a[rel=prev],
  .wp-pagenavi-single a[rel=next] {
    width: 32px;
  }
}
/* sitemap
*************************************************** */
.sitemap--menu {
  flex: 1;
}
@media print, screen and (min-width: 48em) {
  .sitemap--menu:not(:last-child) {
    margin-right: 7.4074074074%;
  }
}
@media screen and (max-width: 47.9375em) {
  .sitemap--menu:not(:last-child) {
    margin-bottom: 24px;
  }
}

.sitemap--menu a,
.sitemap--menu span {
  display: block;
}

.sitemap--menu a {
  position: relative;
}

.sitemap--menu a:hover {
  color: var(--clr-main, #1D7FB7);
}

.sitemap--menu > li > a {
  font-size: 1.125rem;
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 0.5em;
  margin-bottom: 1.25em;
}

.sitemap_sub_nav--wrap {
  padding-left: 1.25em;
  margin-bottom: 1.5em;
}

.sitemap_sub_nav a {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-left: 1.25em;
}

.sitemap_sub_nav a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid var(--clr-main, #1D7FB7);
  border-right: 1px solid var(--clr-main, #1D7FB7);
  transform: rotate(45deg);
}

/* lps_parts--button
********************************************** */
.lps_parts--button .btn:first-child {
  margin-left: auto;
}

.lps_parts--button .btn:last-child {
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .lps_parts--button.flex,
  .lps_parts--button .inner {
    width: fit-content;
    min-width: min(960px, 90%);
  }
  .lps_parts--button.flex {
    margin-left: auto;
    margin-right: auto;
  }
  .lps_parts--button .btn:only-child {
    width: fit-content;
    min-width: min(300px, 100%);
  }
  .lps_parts--column.column-2 + .lps_parts--button,
  .lps_parts--column.column-3 + .lps_parts--button,
  .lps_parts--column.column-4 + .lps_parts--button {
    margin-top: 0;
  }
}
/* lps_parts--column
********************************************** */
.lps_parts--column:not(.column-1) + .lps_parts--column {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 24px;
  }
}

.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}

.lps_parts--column:not(.column-1) .text {
  height: 100%;
}

.lps_parts--column .img {
  flex-shrink: 0;
  overflow: hidden;
}

.lps_parts--column .img + .text {
  padding-top: 24px;
}

.lps_parts--column .bg-wh .text {
  padding: 6%;
}

.lps_parts--column:not(.column-1) table {
  width: 100%;
}

.lps_parts--column.column-1 .box {
  padding: 40px;
}

.lps_parts--column.column-1 .img,
.lps_parts--column.column-2 .img {
  border-radius: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column.column-1 .img,
  .lps_parts--column.column-2 .img {
    border-radius: 20px;
  }
}
.lps_parts--column.column-1 .bg-wh,
.lps_parts--column.column-2 .bg-wh {
  border-radius: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column.column-1 .bg-wh,
  .lps_parts--column.column-2 .bg-wh {
    border-radius: 20px;
  }
}
.lps_parts--column.column-1 .bg-wh .img,
.lps_parts--column.column-2 .bg-wh .img {
  border-radius: 40px 40px 0 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column.column-1 .bg-wh .img,
  .lps_parts--column.column-2 .bg-wh .img {
    border-radius: 20px 20px 0 0;
  }
}

.lps_parts--column.column-3 .img,
.lps_parts--column.column-4 .img {
  border-radius: 20px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column.column-3 .img,
  .lps_parts--column.column-4 .img {
    border-radius: 10px;
  }
}
.lps_parts--column.column-3 .bg-wh,
.lps_parts--column.column-4 .bg-wh {
  border-radius: 20px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column.column-3 .bg-wh,
  .lps_parts--column.column-4 .bg-wh {
    border-radius: 10px;
  }
}
.lps_parts--column.column-3 .bg-wh .img,
.lps_parts--column.column-4 .bg-wh .img {
  border-radius: 20px 20px 0 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column.column-3 .bg-wh .img,
  .lps_parts--column.column-4 .bg-wh .img {
    border-radius: 10px;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 24px;
  }
  .lps_parts--column .lps_parts--child {
    display: block;
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .column_item {
    width: 100%;
  }
  .lps_parts--column .column_item:not(:last-child) {
    margin-bottom: 24px;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts--column .column_item:not(:last-child) {
    margin-right: 0;
  }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column.column-1 .box {
    padding: 24px;
  }
}
/* post-edit-link
********************************************** */
.post-edit-link {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 10;
  padding: 16px;
  border-radius: 50%;
  border: 1px solid #999;
}
@media screen and (max-width: 47.9375em) {
  .post-edit-link {
    display: none;
  }
}
.post-edit-link:hover {
  background-color: #ddd;
}
.post-edit-link::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuODrOOCpOODpOODvF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAxIDQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxIDQwMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5OTk5OTk7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcwLjEsMjUxLjljLTUuNSwwLTEwLDQuNS0xMCwxMHY4OC43YzAsMTYuNS0xMy40LDI5LjktMzAsMzBINDkuOWMtMTYuNSwwLTI5LjktMTMuNC0zMC0zMFY5MC4zCgljMC0xNi41LDEzLjQtMjkuOSwzMC0zMGg4OC43YzUuNSwwLDEwLTQuNSwxMC0xMGMwLTUuNS00LjUtMTAtMTAtMTBINDkuOUMyMi40LDQwLjQsMCw2Mi43LDAsOTAuM3YyNjAuMwoJYzAsMjcuNiwyMi40LDQ5LjksNDkuOSw0OS45aDI4MC4yYzI3LjYsMCw0OS45LTIyLjQsNDkuOS00OS45di04OC43QzM4MC4xLDI1Ni40LDM3NS42LDI1MS45LDM3MC4xLDI1MS45eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzc2LjEsMTQuN2MtMTcuNi0xNy42LTQ2LTE3LjYtNjMuNiwwTDEzNC40LDE5Mi45Yy0xLjIsMS4yLTIuMSwyLjctMi42LDQuNGwtMjMuNCw4NC42Yy0xLDMuNSwwLDcuMiwyLjYsOS43CgljMi41LDIuNSw2LjMsMy41LDkuNywyLjZsODQuNi0yMy40YzEuNy0wLjUsMy4yLTEuMyw0LjQtMi42TDM4Ny45LDkwYzE3LjUtMTcuNiwxNy41LTQ2LDAtNjMuNkwzNzYuMSwxNC43eiBNMTU2LjIsMTk5LjNMMzAyLDUzLjUKCWw0Nyw0N0wyMDMuMiwyNDYuNEwxNTYuMiwxOTkuM3ogTTE0Ni44LDIxOC4ybDM3LjYsMzcuNmwtNTIsMTQuNEwxNDYuOCwyMTguMnogTTM3My43LDc1LjhsLTEwLjYsMTAuNmwtNDctNDdsMTAuNi0xMC42CgljOS43LTkuNywyNS42LTkuNywzNS4zLDBsMTEuNywxMS43QzM4My41LDUwLjMsMzgzLjUsNjYuMSwzNzMuNyw3NS44eiIvPgo8L3N2Zz4K");
}

/* lps_parts--faq
********************************************** */
.lps_parts--faq .faq_item:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .faq_item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
  padding: 20px 48px 20px 104px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03,
  .lps_parts--faq .mce-content-body {
    padding: 16px 24px 16px 56px;
  }
}

.lps_parts--faq .ttl-03 {
  background-color: var(--clr-main, #1d7fb7);
  margin-bottom: 0 !important;
  font-size: 1.5rem;
  color: #fff;
  border-radius: 20px 20px 0 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03 {
    font-size: 1.4285714286rem;
    border-radius: 10px 10px 0 0;
  }
}

.lps_parts--faq .mce-content-body {
  position: relative;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .mce-content-body {
    border-radius: 0 0 10px 10px;
  }
}

.lps_parts--faq .ttl-03::after,
.lps_parts--faq .mce-content-body::after {
  position: absolute;
  left: 48px;
  display: block;
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 600;
  font-style: italic;
  font-size: 2rem;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03::after,
  .lps_parts--faq .mce-content-body::after {
    left: 20px;
    font-size: 1.8571428571rem;
  }
}

.lps_parts--faq .ttl-03::after {
  content: "Q";
  top: 20px;
  color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03::after {
    top: 15px;
  }
}

.lps_parts--faq .mce-content-body::after {
  content: "A";
  top: 20px;
  color: var(--clr-main, #1d7fb7);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .mce-content-body::after {
    top: 16px;
  }
}

.lps_parts--faq .mce-content-body .editor-ttl2 {
  margin-top: 0;
}

.lps_parts--faq .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .text img {
    float: none !important;
    margin-top: 2px;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 8px !important;
    border-radius: 8px;
  }
}

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 86px;
  counter-increment: number;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item {
    padding-left: 52px;
  }
}

.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child) {
    padding-bottom: 20px;
  }
}

.lps_parts--flow .ttl-03 {
  font-size: 2rem;
  color: var(--clr-main, #1d7fb7);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .ttl-03 {
    font-size: 1.4285714286rem;
  }
}
.lps_parts--flow .ttl-03::after {
  content: counter(number);
  position: absolute;
  top: -8px;
  left: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  background-color: #f5c246;
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 600;
  font-size: 1.625rem;
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .ttl-03::after {
    top: -6px;
    left: -52px;
    width: 2.8571428571rem;
    height: 2.8571428571rem;
    font-size: 1.2857142857rem;
  }
}

.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 64px;
  left: 38px;
  display: block;
  width: 0;
  height: calc(100% - 80px);
  border-right: 1px solid #f5c246;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child)::before {
    top: 44px;
    left: 20px;
    height: calc(100% - 60px);
  }
}

.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}

.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}

.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main, #1D7FB7);
  background-color: #fff;
}

.lps_parts--flow .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  border-radius: 16px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .text img {
    float: none !important;
    max-width: inherit;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 16px !important;
    border-radius: 8px;
  }
}

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default, all 0.4s ease-out);
}

.icon-new_tab {
  width: 0.875rem;
  height: 0.875rem;
}

.btn .icon-new_tab {
  right: 16px;
}

.icon-pdf {
  width: 1.6875rem;
  height: 0.75rem;
}

.btn .icon-pdf {
  left: 16px;
}

.lps_sec {
  position: relative;
}

.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* ttl
********************************************** */
.lps_sec .ttl-01 {
  text-align: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}

.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts + .ttl-02,
  .lps_parts--button + .ttl-02,
  .lps_parts--button + .lps_parts,
  .lps_parts + .lps_parts {
    margin-top: 40px;
  }
}

.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts + .lps_parts--button {
    margin-top: 16px;
  }
}

.lps_parts--button + .lps_parts--button {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--button + .lps_parts--button {
    margin-top: 8px;
  }
}

.lps_parts iframe {
  width: 100%;
}

.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}

.lps_parts--column .btn,
.lps_parts--slide .btn {
  text-align: center;
}

.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}

.lps_parts--column .btn a {
  margin-left: auto;
  margin-right: auto;
}

.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}
/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--child:only-child {
  width: 100%;
}

.lps_parts--img_text .lps_parts--child .img_text--img:only-child .img {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.lps_parts--img_text .img_text--child .img {
  height: 100%;
}

.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5%;
}

.lps_parts--img_text .img_text--txt--inner {
  width: 100%;
}

.lps_parts--img_text .text {
  justify-content: flex-start;
}

.lps_parts--img_text .img_text--txt table {
  width: 100%;
}

.lps_parts--img_text .inner .img_text--img {
  width: 50%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner .img_text--img {
    width: 100%;
  }
}

.lps_parts--img_text .inner:not(.inner-lg):not(.bg-wh) {
  gap: 6.25%;
}

.lps_parts--img_text .inner .img_text--img img {
  border-radius: 20px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner .img_text--img img {
    border-radius: 10px;
  }
}

.lps_parts--img_text .inner.bg-wh {
  border-radius: 10px;
}

.lps_parts--img_text .inner:not(.bg-wh) .img_text--txt {
  width: 43.75%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner:not(.bg-wh) .img_text--txt {
    width: 100%;
  }
}

.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}

.lps_parts--img_text .inner-lg .img_text--img img {
  border-radius: 0 40px 40px 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--img img {
    border-radius: 20px;
  }
}

.lps_parts--img_text .inner-lg.flex-reverse .img_text--img img {
  border-radius: 40px 0 0 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg.flex-reverse .img_text--img img {
    border-radius: 20px;
  }
}

.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
  left: 0;
}

.lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
  right: 0;
}

.lps_parts--img_text .inner-lg:not(.bg-wh) .img_text--txt {
  width: 45%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg:not(.bg-wh) .img_text--txt {
    width: 100%;
  }
}

.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
}

.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    display: flex;
    align-items: center;
    min-height: 607.64px;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding: 6%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 24px;
  }
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
  }
  .lps_parts--img_text.bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
/* lps_parts--slide
********************************************** */
.lps_parts--slide .img img {
  display: inline-block;
  border-radius: 40px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .img img {
    border-radius: 20px;
  }
}

.lps_parts--slide .img + .text {
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .img + .text {
    margin-top: 16px;
  }
}

.lps_parts--slide .slick-list {
  margin-bottom: 32px;
}

.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  text-decoration: none;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow {
    width: 56px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow {
    width: 40px;
  }
}

.lps_parts--slide .slick-prev {
  left: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-prev {
    left: 0;
  }
}

.lps_parts--slide .slick-next {
  right: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-next {
    right: 0;
  }
}

.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-dots {
    position: relative;
    bottom: 4px;
  }
}

.lps_parts--slide .slick-arrow::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow::after {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow::after {
    width: 16px;
    height: 16px;
  }
}

.lps_parts--slide .slick-prev::after {
  border-bottom: 1px solid;
  border-left: 1px solid;
}

.lps_parts--slide .slick-next::after {
  border-top: 1px solid;
  border-right: 1px solid;
}

.lps_parts--slide .slick-dots li {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
  margin: 6px;
}

.lps_parts--slide .slick-dots li.slick-active {
  background-color: #333;
}

.lps_parts--slide .slick-dots li:hover {
  opacity: 0.7;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}