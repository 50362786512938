
@use '../abstracts' as *;

  /* --- anchor --- */
  .anchor {
    position: absolute;
    top: -80px; /* 固定ヘッダーの高さ */
    left: 0;
    pointer-events: none;
    @include mq(med){
      .anchor {
        top: -64px; /* 固定ヘッダーの高さ */
      }
    }
  }