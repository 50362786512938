@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */

/* footer
*************************************************** */
.footer {
  .page_top-btn {
    position: fixed;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    z-index: 999;
    transition: var(--transit-default, all .4s ease-out);
    &.active {
      transform: translateX(0);
    }
    a {
      &:hover {
        opacity: .7;
      }
    }
    img {
      @include mq(sp) {
        width: 220px;
      }
    }
  }
  .footer--wrapper {
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      min-width: 1920px;
      width: 100%;
      height: 100%;
      clip-path: ellipse(50% 100% at bottom);
      background-color: #fff;
      @include mq(sp) {
        min-width: 1400px;
      }
    }
    .deco01 {
      position: absolute;
      right: vw(216);
      bottom: vw(118);
      width: vw(276);
      @include mq(sp) {
        left: spvw(24);
        right: inherit;
        width: spvw(76);
      }
    }
    .footer_top {
      max-width: 1060px;
      padding-top: vw(86);
      text-align: center;
      @include mq(sp) {
        padding-top: 60px;
      }
      .footer--logo {
        margin-bottom: 14px;
        @include mq(sp) {
          margin-bottom: 8px;
        }
        img {
          @include mq(sp) {
            width: 160px;
          }
        }
        .logo--txt {
          margin-top: 1em;
          font-size: rem(20);
          @include lh(20,40);
          letter-spacing: .05em;
          @include mq(sp) {
            margin-top: .5em;
            font-size: sprem(14);
          }
        }
      }
      .sns_area {
        margin-bottom: 32px;
        @include mq(sp) {
          margin-bottom: 20px;
        }
        .sns--list {
          li:not(:last-child) {
            margin-right: rem(26);
            @include mq(sp) {
              margin-right: 14px;
            }
          }
          a {
            display: grid;
            place-items: center;
            @include rect(30);
            &:hover {
              opacity: .7;
            }
          }
          svg {
            @include rect(24);
            fill: $bro-63;
          }
        }
      }
      .info {
        position: relative;
        width: fit-content;
        margin: 0 auto vw(46);
        @include mq(sp) {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          justify-content: center;
          column-gap: 16px;
          margin-bottom: 28px;
        }
        .bubble {
          position: absolute;
          top: -32px;
          left: -74px;
          width: 152px;
          height: 98px;
          padding-bottom: 12px;
          background-image: url(/wp/wp-content/themes/standard_sass/images/common/footer_bg_balloon.svg);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          @include mq(sp) {
            position: inherit;
            top: inherit;
            left: inherit;
            width: 100px;
            height: 64px;
            padding-bottom: 10px;
          }
          .txt {
            font-size: rem(18);
            letter-spacing: .05em;
            @include mq(sp) {
              font-size: sprem(13);
            }
          }
        }
        .access {
          margin-bottom: 20px;
          @include lh(16,30);
          letter-spacing: .05em;
          @include mq(sp) {
            margin-bottom: 10px;
            font-size: sprem(13);
            text-align: left;
          }
        }
        .tel {
          color: $grn-3f;
          font-size: rem(40);
          letter-spacing: .03em;
          line-height: 1;
          @include mq(sp) {
            width: 100%;
            font-size: sprem(24);
          }
          img {
            width: 60px;
            margin-right: 8px;
            @include mq(sp) {
              width: 40px;
            }
          }
          .num {
            font-size: rem(64);
            letter-spacing: .03em;
            @include mq(sp) {
              font-size: sprem(40);
            }
          }
        }
      }
    }
    .footer_ctr {
      max-width: 1060px;
      padding-right: vw(70);
      margin-bottom: vw(78);
      @include mq(sp) {
        padding-right: 0;
        margin-bottom: 40px;
      }
      .fnav {
        @include mq(sp) {
          width: 90%;
          margin: 0 auto;
        }
        .fnav--menu {
          padding: 20px 0 20px 36px;
          border-left: 2px dotted $grn-3f;
          @include mq(sp) {
            padding: 0;
            border-left: inherit;
          }
          & > .menu-item {
            a {
              &:hover {
                color: $grn-3f;
              }
            }
            & > a {
              position: relative;
              display: block;
              padding-left: 24px;
              font-family: var(--font-jp);
              letter-spacing: .05em;
              @include mq(sp) {
                padding-left: 20px;
              }
              &::before {
                content: '';
                position: absolute;
                top: .8em;
                left: 0;
                @include rect(12);
                background-color: $grn-3f;
                border-radius: 50%;
                @include mq(sp) {
                  top: .75em;
                  @include sprect(8);
                }
              }
            }
            .sub-menu {
              display: flex;
              flex-wrap: wrap;
              column-gap: vw(54);
              max-width: 460px;
              @include mq(sp) {
                column-gap: 20px;
              }
              .menu-item {
                width: calc((100% - vw(54)) / 2);
                @include mq(sp) {
                  width: auto;
                }
                a {
                  position: relative;
                  display: block;
                  padding-left: 40px;
                  font-size: rem(14);
                  font-family: var(--font-jp);
                  @include lh(14,40);
                  letter-spacing: .05em;
                  white-space: nowrap;
                  @include mq(sp) {
                    padding-left: 20px;
                  }
                  &::before {
                    content: '';
                    position: absolute;
                    top: calc(50% - 1px);
                    left: 0;
                    @include rect(28,2);
                    background-color: $grn-d9;
                    @include mq(sp) {
                      @include sprect(14,2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .footer_btm {
    padding-top: 20px;
    padding-bottom: 30px;
    @include mq(sp) {
      padding-top: 10px;
      padding-bottom: 16px;
    }
    .copyright {
      margin-bottom: .833em;
      font-size: rem(12);
      @include lh(12,22);
      @include mq(sp) {
        margin-bottom: .4em;
      }
    }
    .pbl {
      line-height: 1;
      a {
        img {
          width: 120px;
          opacity: .5;
          transition: var(--transit-default, all .4s ease-out);
        }
        &:hover {
          img {
            opacity: 1;
          }
        }
      }
    }
  }
}