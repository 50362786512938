@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs {
  padding: 16px 0;
}
.breadcrumbs a {
  color: var(--clr-main, #1d7fb7);
  text-decoration: underline;
}
.breadcrumbs a:hover {
  text-decoration: none;
}
.breadcrumbs .breadcrumb_last {
  display: table-cell;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}