@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default, all .4s ease-out);
}
.icon-new_tab {
  @include rect(14);
}
.btn .icon-new_tab {
  right: 16px;
}
.icon-pdf {
  @include rect(27,12);
}
.btn .icon-pdf {
  left: 16px;
}
.lps_sec {
  position: relative;
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* ttl
********************************************** */
.lps_sec .ttl-01 {
  text-align: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}
.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
  @include mq(sp) {
    margin-top: 40px;
  }
}
.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
  @include mq(sp) {
    margin-top: 16px;
  }
}
.lps_parts--button + .lps_parts--button {
  margin-top: 0;
  @include mq(sp) {
    margin-top: 8px;
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}
.lps_parts--column .btn,
.lps_parts--slide .btn {
  text-align: center;
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}