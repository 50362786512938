@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;

// 色
$grn-3f: #3fa037;
$grn-c6: #c6dfc3;
$grn-c7: #c7e0c4;
$grn-d9: #d9dfd3;
$yell-f5: #f5c246;
$pin-d7: #d79db8;
$blu-89: #89b9d0;
$gry-ea: #eaece8;
$gry-f2: #f2f3f1;
$gry-d7: #d7dad3;
$bro-63: #63502f;


// font-families
$font-primary: "Shuei KakuGo Gin M","こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";  
$font-secondary: "ヒラギノ角ゴ オールド W6 JIS2004", "Hiragino Sans Old W6 JIS2004";
$font-secondary--light: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
$font-jp: "Shuei Nijimi Go Gin B","こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
$font-en: 'Outfit','Josefin Sans', sans-serif;
$font-noto-sans: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

// CSS変数
:root {
  // typography
  --ltr-space-default: .1em;
  --line-height-default: 2.2;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary: "Shuei KakuGo Gin M","こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-primary-bold: "Shuei KakuGo Gin B","こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-secondary: "ヒラギノ角ゴ オールド W6 JIS2004", "Hiragino Sans Old W6 JIS2004";
  --font-secondary--light: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
  --font-jp:  "Shuei Nijimi Go Gin B","こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-en: 'Outfit','Josefin Sans', sans-serif;
  --font-noto-sans: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

  // font-sizes
  --fz-primary: 1.125rem;
  --fz-half: .5rem;



  // text colors
  --clr-main: #3fa037;
  --clr-txt-sub: #171C60;
  --clr-body: #63502f;
  --clr-dark-blue: #101560;
  --clr-link: #63502f;
  --clr-wht: #fff;
  --clr-blk: #000;

  // background colors
  --bg-main: #f2f3f1;
  --bg-sub: #eee;
  --bg-wht: #fff;
  --bg-off_wht: #dcedfc;
  --bg-blk: #000;

  //button colors
  --btn-bg-main: #f5c246;
  --btn-bg-main-hvr: var(--clr-dark-blue);
  --btn-clr-main: #fff;
  --btn-clr--sec: #fff;

  --btn-clr-wht: #333;
  --btn-bg-wht: #fff;
  --btn-clr-wht-hvr: #fff;
  --btn-bg-wht-hvr: var(--clr-dark-blue);


  // border colors
  --brd-main: #1D7FB7;

  // animation
  --transit-default: all 0.4s ease-out;


  // formy settings

}

// SASS Maps

$z-index: (
  "header" : 9999,
  "top": 9990,
  "aside" : 75,
  "middle" : 50,
  "main" : 1,
  "base" : 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160, // 2560px
  lg: 120, // 1920px
  pc: 85.375, // 1366px
  med: 67.5, // 1080px
  sp: 47.9375, // 767px
  sm: 40, // 640px
  xs: 34.375, // 500px
  se: 21.25, // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  'blue': (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  'red': (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  'green': (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  )
);

$aspect-ratios: (
  widescreen: '3/2',
  square: '1/1',
  portrait: '2/3'
);