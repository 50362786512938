@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: $fz-pc+px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(sp, min, ps) {
    @include mq(med) {
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: $fz-sp+px;
  }
}
body {
  font-family: var(--font-primary, YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif);
  line-height: var(--line-height-default, 2.2);
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default, .1em);
  *font-size: small;
  *font: x-small;
  color: var(--clr-body, #63502f);
  background-color: $gry-f2;
  -webkit-font-smoothing: antialiased;
  min-width: 1080px;
  height: 100%;
  padding-top: 136px;
  word-break: break-word;
  @include mq(sp, min, ps) {
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }
  @include mq(med) {
    padding-top: 100px;
    &.home {
      padding-top: 0;
    }
  }
  @include mq(sp) {
    min-width: inherit;
    -webkit-text-size-adjust: none;
  }
}
.home main {
  overflow: hidden;
}
.page main {
  @include mq(sp) {
    overflow: hidden;
  }
}
ul,
ol { list-style: none; }
small { 
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link, #333);
  text-decoration: none;
  transition: var(--transit-default, all .4s ease-out);
  outline : none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:focus {
  text-decoration: none !important;
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr, 1.4);
}
svg {
  transition: var(--transit-default, all .4s ease-out);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}
