@use "../abstracts" as *;

/* home_ttl
********************************************** */
.home_ttl {
  .jp {
    display: block;
    font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
    letter-spacing: .05em;
    &.jp-24 {
      font-size: rem(24);
      @include lh(24, 40);
      @include mq(sp) {
        font-size: sprem(18);
      }
    }
    &.jp-32 {
      font-size: rem(32);
      @include lh(32, 56);
      @include mq(sp) {
        font-size: sprem(20);
      }
    }
    &.jp-36 {
      font-size: rem(36);
      @include lh(36, 56);
      @include mq(sp) {
        font-size: sprem(24);
      }
    }
    &.jp-40 {
      font-size: rem(40);
      @include lh(40,72);
      @include mq(sp) {
        font-size: sprem(28);
      }
    }
    &.jp-48 {
      font-size: rem(48);
      @include lh(48, 72);
      @include mq(sp) {
        font-size: sprem(32);
      }
    }
    &.jp-64 {
      font-size: rem(64);
      @include lh(64, 80);
      @include mq(sp) {
        font-size: sprem(36);
      }
    }
  }
  .en {
    display: block;
    font-family: var(--font-en, 'Montserrat', sans-serif);
    font-weight: 400;
    letter-spacing: .1em;
    &.en-24 {
      font-size: rem(24);
      @include mq(sp) {
        font-size: sprem(18);
      }
    }
    &.en-48 {
      font-size: rem(48);
      line-height: 1;
      @include mq(sp) {
        font-size: sprem(32);
      }
    }
    &.en-120 {
      font-size: rem(120);
      line-height: 1;
      @include mq(sp) {
        font-size: sprem(60);
      }
    }
  }
  .bubble {
    position: relative;
    display: inline-block;
    font-size: rem(24);
    font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
    @include lh(24,32);
    letter-spacing: .05em;
    @include mq(sp) {
      font-size: sprem(16);
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -50px;
      transform: translateY(-50%);
      width: 34px;
      height: 56px;
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl_deco_left.svg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @include mq(sp) {
        width: 20px;
        height: 38px;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -50px;
      transform: translateY(-50%);
      width: 34px;
      height: 56px;
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl_deco_right.svg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @include mq(sp) {
        width: 20px;
        height: 38px;
      }
    }
  }
}
/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.page_ttl--bg-wrap {
  height: 500px;
  @include mq(sp) {
    height: 150px;
  }
}
.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90vw;
  z-index: -1;
  background-size: cover;
  background-position: center;
  border-radius: 40px;
  @include mq(sp) {
    border-radius: 16px;
  }
}
.page_ttl-jp {
  color: #fff;
  font-size: rem(40);
  @include mq(sp) {
    font-size: sprem(16);
  }
}
.page_ttl-en {
  display: block;
  color: #fff;
  text-transform: uppercase;
  font-size: rem(100);
  line-height: 1;
  letter-spacing: .1em;
  white-space: nowrap;
  text-shadow:1px 1px 0 $bro-63, -1px -1px 0 $bro-63,
              -1px 1px 0 $bro-63, 1px -1px 0 $bro-63,
              0px 1px 0 $bro-63,  0 -1px 0 $bro-63,
              -1px 0 0 $bro-63, 1px 0 0 $bro-63;
  @include mq(sp) {
    margin-bottom: 4px;
    font-size: sprem(40);
    white-space: inherit;
  }
}
.page_ttl--list_wrap {
  background-color: rgba(63, 160, 55, .25);
}
.page_ttl--list li {
  @include mq(sp, min, ps) {
    min-height: 96px;
  }
}
.page_ttl--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: .5em 2.5em;
  line-height: var(--line-height-hdr, 1.4);
  @include mq(sp) {
    padding: 1em 1.5em;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: rem(30);
    transform: translateY(-50%);
    @include rect(12,6);
    background-color: var(--clr-body, #000);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    transition: var(--transit-default, all .4s ease-out);
    @include mq(sp) {
      right: sprem(10);
    }
  }
  &:hover {
    color: var(--clr-main, #3fa037);
    &::before {
      background-color: var(--bg-main, #3fa037);
    }
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  color: $bro-63;
  line-height: 1.5;
  letter-spacing: .05em;
}
.ttl-01 {
  position: relative;
  font-size: rem(48);
  padding-bottom: 1.458em;
  @include mq(sp) {
    font-size: sprem(30);
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 44px;
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl-deco.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include mq(sp) {
      width: 60px;
      height: 33px;
    }
  }
  &.font-en {
    color: #fff;
    font-size: rem(100);
    line-height: 1;
    text-shadow: 1px 1px 0 $bro-63, -1px -1px 0 $bro-63,
                -1px 1px 0 $bro-63, 1px -1px 0 $bro-63,
                0px 1px 0 $bro-63,  0 -1px 0 $bro-63,
                -1px 0 0 $bro-63, 1px 0 0 $bro-63;
    @include mq(sp) {
      font-size: sprem(40);
    }
  }
}
.ttl-02 {
  font-size: rem(40);
  font-family: var(--font-jp);
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: rem(40);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.ttl-03 {
  font-size: rem(36);
  font-family: var(--font-jp);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03 {
  position: relative;
  z-index: 1;
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03:not(:last-child) {
  margin-bottom: 1em;
}
.ttl-04 {
  font-size: rem(24);
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(24);
  }
}

/* section_ttl
********************************************** */
.section_ttl {
  color: $bro-63;
  font-size: rem(40);
  font-family: var(--font-jp);
  line-height: 1.5;
  letter-spacing: .05em;
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.section_ttl-02 {
  padding: 0 .375em .25em .375em;
  margin-bottom: .75em;
  color: $bro-63;
  font-size: rem(32);
  font-family: var(--font-jp);
  line-height: 1.5;
  letter-spacing: .05em;
  border-bottom: 1px solid $bro-63;
  @include mq(sp) {
    font-size: sprem(24);
  }
}