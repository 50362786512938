@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
  overflow: hidden;
}

/* --- post --- */
.post {
  position: relative;
  .post--img {
    overflow: hidden;
    img {
      transition: var(--transit-default, all .4s ease-out);
    }
  }
  &:hover {
    .post--img {
      img {
        transform: scale(1.1);
      }
    }
  }
}
.post--img .img-contain {
  max-width: 80%;
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--date {
  color: $grn-3f;
  font-family: var(--font-en);
  line-height: 1;
  letter-spacing: .05em;
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  padding: 0 1.625em;
  @include lh(16,40);
  margin: 2px 4px;
  font-family: var(--font-jp);
  text-align: center;
  background-color: $yell-f5;
  border: 1px solid $yell-f5;
  border-radius: 8px;
  float: left;
  white-space: nowrap;
  @include mq(sp) {
    line-height: 2;
    border-radius: 4px;
  }
  &:hover {
    background-color: #fff;
  }
}
.post--ttl {
  font-family: var(--font-jp);
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}

/* side_column
**************************************** */
.side_column {
  width: 264px;
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:last-child) {
  margin-bottom: 64px;
}
.side--ttl {
  margin-bottom: 16px;
  padding: .8em .8em;
  color: #fff;
  font-size: rem(20);
  font-family: var(--font-jp);
  line-height: 1;
  border-radius: 4px;
  background-color: $grn-3f;
}

/* --- list --- */
.side--list a {
  padding-top: .8em;
  padding-bottom: .8em;
  padding-left: .5em;
  display: block;
  line-height: 1.4;
  border-bottom: solid 1px $gry-d7;
  &:hover {
    color: $grn-3f;
  }
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: rem(16);
}
.posts-side .post--img {
  width: 100%;
  aspect-ratio: 256/184;
  margin-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
}
.posts-side .post--date {
  margin-bottom: 4px;
  font-size: rem(14);
}
.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.side_column .archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 2.4;
}
.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all 0.4s ease-out);
}
.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}
.side_column .archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: #fff;
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* blog
**************************************** */
.posts-blog {
  flex-wrap: wrap;
  column-gap: 3.4%;
  row-gap: 40px;
  .post {
    width: calc((100% - (3.4% * 2)) / 3);
    @include mq(sp) {
      width: 100%;
    }
    .post--img {
      aspect-ratio: 256/184;
      margin-bottom: 12px;
      background-color: $grn-d9;
      border-radius: 8px;
    }
    .post--date {
      margin-bottom: 4px;
    }
    .cat_list {
      margin-bottom: 10px;
      a {
        padding: 0 12px;
        font-size: rem(14);
        @include lh(14,30);
        letter-spacing: .05em;
        border-radius: 4px;
      }
    }
    .post--date {
      @include lh(16,28);
    }
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list .post {
  padding: 30px 20px;
  margin-bottom: 0;
  border-bottom: 1px dotted $grn-3f;
  @include mq(sp) {
    padding: 20px 16px;
    display: block;
  }
}
.news_list .post--date {
  min-width: 96px;
  @include mq(sp) {
    min-width: 86px;
  }
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1d7fb7);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main, #1d7fb7);
}
.news_list .post--info {
  margin-bottom: .75em;
}
.news_list .cat_list a {
  min-width: 96px;
  padding: 0 1em;
  margin-right: 4px;
  font-size: rem(16);
  line-height: 1.6;
}
.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 16px;
}
.news_list .post--ttl {
  font-size: rem(14);
  @include lh(14,28);
  letter-spacing: .05em;
  text-align: justify;
}

/* works
**************************************** */
.archive-works {
  .main_column {
    overflow: inherit;
  }
}
.posts-works {
  gap: 62px 1.886%;
  flex-wrap: wrap;
  @include mq(sp) {
    row-gap: 48px;
  }
  .post {
    width: calc((100% - 1.886%) / 2);
    @include mq(sp) {
      width: 100%;
    }
    .post--img {
      position: relative;
      &::before {
        content: "";
        display: block;
        padding-top: 88.23%;
      }
      img {
        position: absolute;
      }
    }
    .post--txtarea {
      padding: 0 5.8%;
      .cat_list {
        margin-top: -24px;
        margin-bottom: 10px;
        a {
          min-width: inherit;
        }
      }
      .post--info {
        flex-direction: column;
        row-gap: 20px;
        .post--ttl {
          width: 100%;
          font-size: rem(20);
          @include lh(20,40);
          letter-spacing: .05em;
          .post--link {
            &::before {
              height: calc(100% + 16px);
            }
          }
        }
        .detail {
          position: relative;
          display: block;
          width: fit-content;
          padding: 0 14px;
          margin-left: auto;
          line-height: 1;
          letter-spacing: .05em;
          font-family: var(--font-jp);
          white-space: nowrap;
          &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            width: 100%;
            height: 24px;
            background-color: $grn-3f;
            border-radius: 6px;
            opacity: .25;
            z-index: -1;
          }
        }
      }
    }
  }
}


