@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 860px;
  @include auto-margin;
  padding-left: 86px;
  counter-increment: number;
  @include mq(sp) {
    padding-left: 52px;
  }
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 40px;
  @include mq(sp) {
    padding-bottom: 20px;
  }
}
.lps_parts--flow .ttl-03 {
  font-size: rem(32);
  color: var(--clr-main, #1d7fb7);
  @include mq(sp) {
    font-size: sprem(20);
  }
  &::after {
    content: counter(number);
    position: absolute;
    top: -8px;
    left: -80px;
    display: flex;
    @include center-flex;
    @include rect(64);
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background-color: $yell-f5;
    font-family: var(--font-en, 'Montserrat', sans-serif);
    font-weight: 600;
    font-size: rem(26);
    letter-spacing: 0;
    @include mq(sp) {
      top: -6px;
      left: -52px;
      @include sprect(40);
      font-size: sprem(18);
    }
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 64px;
  left: 38px;
  display: block;
  width: 0;
  height: calc(100% - 80px);
  border-right: 1px solid $yell-f5;
  @include mq(sp) {
    top: 44px;
    left: 20px;
    height: calc(100% - 60px);
  }
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}
.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main, #1D7FB7);
  background-color: #fff;
}
.lps_parts--flow .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  border-radius: 16px;
  @include mq(sp) {
    float: none !important;
    max-width: inherit;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 16px !important;
    border-radius: 8px;
  }
}
