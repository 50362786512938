@use './variables' as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */

//トップ動き
@keyframes FadeIn { 
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes FadeInTop { 
    0% {
        transform: translateY(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes FadeInBtm { 
    0% {
        transform: translateY(-30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes FadeInRgt { 
    0% {
        transform: translateX(-30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes FadeInLft { 
    0% {
        transform: translateX(30px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}
@keyframes Buruburu {
    0% {
        transform: translate(0, -1px) rotate(0deg);
    }
    20% {
        transform: translate(0, -2px) rotate(-3deg);
    }

    40% {
        transform: translate(0, -1px)rotate(3deg);
    }

    60% {
        transform: translate(0, -2px) rotate(-3deg);
    }

    80% {
        transform: translate(0, -1px)rotate(3deg);
    }

    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}

//カーソルストーカー
@keyframes cursor_more {
    0% {
        width: 16px;
        height: 16px;
        margin: -8px 0 0 -8px;
    }
    100% {
        width: 120px;
        height: 120px;
        margin: -30px 0 0 -30px;
    }
}

//hero_txt_slider
@keyframes hero_txt_slider {
    0% {
        transform: translate(-25%, 0);
    }
    100% {
        transform: translate(-75%, 0);
    }
}