@use '../abstracts' as *;
@use 'sass:math';

/* txt
**************************************** */
.txt-lg {
  font-size: rem(18);
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.txt-sm {
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.txt-wh,
.txt-wh * { color: var(--clr-wht, #fff); }
.txt-main,
.txt-main * { color: var(--clr-main, #1d7fb7); }
.txt-sub,
.txt-sub * { color: var(--clr-sub, #eee); }

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} { font-size: math.div($i, $fz-pc) + rem}
}

/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
}
.font-sta {
  font-family: var(--font-primary,"こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004");
}
.font-sta-bold {
  font-family: var(--font-primary-bold,"こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004");
}
.font-en {
  font-family: var(--font-en, 'Montserrat', sans-serif);
  font-weight: 400;
}

/* --- clip-txt --- */
// 省略行を1から4まで生成
@for $i from 1 through 4 {
  .clip-txt#{$i} {
    @include clip-txt($i);
  }
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@include mq(sp) {
  .txt-ctr-pc,
  .txt-rgt-pc { text-align: left; }
}

.block {
  display: block;
}

.txt-edge {
  color: #fff;
  font-family: var(--font-en, 'Montserrat', sans-serif);
  font-weight: 300;
  text-shadow: 1px 1px 0 $bro-63, -1px -1px 0 $bro-63,
  -1px 1px 0 $bro-63, 1px -1px 0 $bro-63,
  0px 1px 0 $bro-63,  0 -1px 0 $bro-63,
  -1px 0 0 $bro-63, 1px 0 0 $bro-63;
}

.txt-bold {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 32px;
    background-color: $grn-3f;
    border-radius: 8px;
    opacity: .25;
    z-index: -1;
    @include mq(sp) {
      bottom: -6px;
      height: 22px;
      border-radius: 4px;
    }
  }
}
.txt {
  .txt-bold {
    position: relative;
    font-family: var(--font-primary-bold);
    &::before {
      content: "";
      position: absolute;
      bottom: -6px;
      height: 1em;
      border-radius: 4px;
    }
  }
}

/* 縦書き
**************************************** */
.txt-vl {
  writing-mode: vertical-rl;
  font-feature-settings: initial;
  .txt-bold {
    position: relative;
    line-height: 1;
    display: inline-block;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -.14em;
      width: .64em;
      height: 100%;
      background-color: $grn-3f;
      border-radius: 8px;
      opacity: .25;
      z-index: -1;
    }
  }
}
.txt-vl * {
  font-feature-settings: initial;
}
.txt-vl .num {
  text-combine-upright: all;
}
// 文字は横向き
.sideways {
    text-orientation: sideways;
}