@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 136px;
  z-index: z(header);
  transition: var(--transit-default, all .4s ease-out);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: 54px;
    padding-right: 32px;
    @include mq(1800) {
      padding-left: 10px;
      padding-right: 0;
    }
    @include mq(1500) {
      padding-right: 16px;
    }
    @include mq(sp) {
      padding: 0;
    }
  }
  @include mq(med) {
    height: 60px;
  }
  &.header-sm {
    background-color: #fff;
  }
}
.page-contact .header {
  position: absolute;
}
// header--logo
.header--logo {
  @include mq(med) {
    position: absolute;
    top: 10px;
    z-index: 999;
  }
  a {
    display: flex;
    flex-direction: column;
    img {
      width: 132px;
      margin-right: 24px;
      margin-bottom: 8px;
      @include mq(1800) {
        margin-right: 12px;
      }
    }
    .logo--txt {
      @include lh(14,20);
      letter-spacing: .05em;
      transition: var(--transit-default, all .4s ease-out);
      @include mq(sp) {
        margin-left: 12px;
      }
    }
  }
}
.header-sm {
  .header--logo {
    .logo--txt {
      @include mq(med) {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
.footer--logo a {
  display: block
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: .7;
}
@include mq(med) {
  .header--logo img {
    width: auto;
    max-height: 40px;
  }
}

/* gnav
********************************************** */
.gnav a {
  display: flex;
  flex-direction: column;
  @include center-flex;
  white-space: nowrap;
}
.gnav--link {
  padding: 0 8px;
  width: 128px;
  border-radius: 0 0 8px 8px;
  @include mq(1700) {
    width: 110px;
  }
  @include mq(1500) {
    width: 9.33vw;
  }
  @include mq(1350) {
    width: 110px;
  }
  @include mq(1280) {
    width: 128px;
  }
  @include mq(med) {
    width: 100%;
  }
  .img {
    position: relative;
    @include rect(80);
    border-radius: 50%;
    margin-bottom: 8px;
    background-color: $gry-ea;
    @include mq(1280) {
      @include rect(70);
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      transition: var(--transit-default, all .4s ease-out);
    }
  }
  .ttl {
    color: var(--clr-body, #63502f);
    font-size: rem(14);
    line-height: 1.4;
    height: calc(14px * 2.8);
    @include mq(1280) {
      font-size: rem(13);
      height: auto;
    }
    @include mq(med) {
      font-size: rem(16);
    }
  }
  &:hover {
    .img {
      .menu--img {
        opacity: 0;
      }
    }
  }
}
.head_sub_nav--wrap {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 100;
  transition: var(--transit-default, all 0.4s ease-out);
  opacity: 0;
  pointer-events: none;
}
.menu-item-has-children {
  position: relative;
}
.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.head_sub_nav a {
  display: block;
  padding: 16px 24px;
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  font-size: rem(24);
}
.cta_tel .num {
  font-size: rem(30);
}
.gnav--tel {
  margin-left: vw(82);
  @include mq(1900) {
    margin-left: 16px;
  }
  @include mq(1500) {
    margin-left: 0;
  }
  @include mq(1280) {
    position: absolute;
    top: 136px;
    right: 220px;
    height: 100px !important;
    padding: 0 20px;
    background: #fff;
    border-radius: 0 0 0 20px;
  }
  @include mq(med) {
    position: inherit;
    top: inherit;
    right: inherit;
    height: auto !important;
    padding: 24px 0;
    background-color: inherit;
  }
}
.gnav--contact {
  margin-left: vw(28);
  @include mq(1700) {
    margin-left: 16px;
  }
  @include mq(1500) {
    position: absolute;
    top: 136px;
    right: 0;
    margin-left: 0;
  }
  @include mq(med) {
    position: relative;
    top: inherit;
    left: inherit;
  }
}
.gnav--tel a {
  display: flex !important;
  flex-direction: column;
}
.gnav--contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 256px;
  height: 160px;
  border-radius: 0 0 24px 24px;
  background-color: $grn-3f;
  border: 1px solid $grn-3f;
  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 16px);
    right: 12px;
    display: block;
    @include rect(8,12);
    -webkit-clip-path: polygon(100% 50%, 0 0, 0 100%);
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    background-color: #fff;
    transition: var(--transit-default, all .4s ease-out);
    @include mq(med) {
      top: calc(50% - 4px);
    }
  }
  @include mq(1700) {
    width: 220px;
  }
  @include mq(med) {
    width: 100%;
    height: inherit;
    border-radius: 20px;
  }
  svg {
    margin-bottom: 12px;
    fill: #fff;
    @include mq(1500) {
      margin-bottom: 8px;
    }
  }
  .contant--txt {
    margin-bottom: 6px;
    color: #fff;
    font-size: rem(18);
    line-height: 1;
    letter-spacing: .05em;
    transition: var(--transit-default, all .4s ease-out);
    @include mq(1700) {
      font-size: rem(16);
    }
  }
  .contant--subtxt {
    padding: 8px 34px;
    color: $grn-3f;
    line-height: 1;
    letter-spacing: .05em;
    background-color: #fff;
    border-radius: 8px;
    transition: var(--transit-default, all .4s ease-out);
    @include mq(1700) {
      padding: 6px 30px;
      font-size: rem(14);
    }
  }
  &:hover {
    background-color: #fff;
    &::before {
      background-color: $grn-3f;
    }
    svg {
      fill: $grn-3f;
    }
    .contant--txt {
      color: $grn-3f
    }
    .contant--subtxt {
      color: #fff;
      background-color: $grn-3f;
    }
  }
}
.gnav--cta .tel {
  display: flex;
  margin-bottom: 12px;
  color: $grn-3f;
  font-size: rem(24);
  line-height: 1;
  letter-spacing: .03em;
  @include mq(1700) {
    margin-bottom: 8px;
    font-size: rem(20);
  }
  img {
    width: 28px;
    margin-right: 6px;
  }
}
.gnav--cta .num {
  font-size: rem(32);
  @include mq(1700) {
    font-size: rem(26);
  }
}
.gnav--cta .hours {
  padding: 12px 22px;
  font-size: rem(14);
  line-height: 1;
  letter-spacing: .05em;
  background-color: #fff;
  border-radius: 8px;
  @include mq(1700) {
    padding: 8px 26px;
    font-size: rem(13);
  }
  @include mq(1280) {
    background-color: $gry-ea;
  }
}
.header-sm .gnav--cta .hours {
  background-color: $gry-ea;
}

@include mq(med, min, ps) {
  .gnav,
  .gnav ul,
  .gnav li,
  .gnav a {
    height: 100%;
  }
  .gnav {
    display: flex;
  }
  .gnav_btn,
  .tel_btn,
  .gnav_contact-sp {
    display: none;
  }
  .gnav--link {
    text-align: center;
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover,
  .gnav--menu > li.current-menu-item > .gnav--link {
    background-color: #3fa03740;
    .img {
      background-color: #fff;
    }
  }
  .gnav--menu_sp {
    display: none;
  }
  .head_sub_nav a {
    background-color: #fff;
  }
  .gnav_sub_toggle {
    display: none;
  }
  .gnav--contact a {
    position: relative;
    height: 160px;
    @include mq(1500) {
      height: 140px;
    }
  }
  /* hover */
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a:hover {
    opacity: .7;
  }
}
@include mq(med) {
  .gnav,
  .gnav_overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav {
    width: 100%;
    margin: 0;
    padding: 110px 7% 24px;
    overflow: scroll;
    background-color: #f7f7f7;
    z-index: 300;
    -webkit-overflow-scrolling: touch;
  }
  .gnav_overlay {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .gnav.active,
  .gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav--menu {
    display: none;
  }
  .gnav--menu_sp,
  .gnav--cta {
    display: block;
  }
  .gnav--menu_sp li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu_sp li {
    border-bottom: 2px dotted $grn-3f;
  }
  .gnav--menu_sp a {
    display: block;
    padding: 18.5px 16px;
  }
  .gnav--menu_sp li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 1px solid var(--clr-main, #1d7fb7);
    border-right: 1px solid var(--clr-main, #1d7fb7);
    transform: rotate(45deg);
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main, #1d7fb7);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a {
    padding-left: 32px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -20px;
    width: 64px;
    height: 64px;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-main, #1d7fb7);
  }
  .gnav_sub_toggle::before,
  .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 11px;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
  .head_sub_nav {
    position: relative;
  }
  .tel_btn,
  .gnav_btn,
  .gnav_contact-sp {
    position: fixed;
    top: 0;
    width: 70px;
    height: 70px;
    z-index: 9999;
  }
  .tel_btn {
    right: 64px;
  }
  .tel_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
  }
  .tel_btn svg {
    fill: var(--clr-main, #1d7fb7);
  }
  .gnav_btn {
    right: 82px;
    transition: var(--transit-default, all .4s ease-out);
    background-color: $grn-3f;
    border-radius: 0 0 8px 8px;
  }
  .gnav_btn--lines {
    position: relative;
    width: 32px;
    height: 28px;
  }
  .gnav_btn--lines span {
    position: absolute;
    transition: var(--transit-default, all .4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span:not(:nth-of-type(3)) {
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #fff;
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    bottom: 14px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    left: 50%;
    bottom: -2px;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    font-size: sprem(11);
    letter-spacing: 0;
    line-height: 1;
    white-space: nowrap;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(12px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    transform: translateY(2px) rotate(45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    opacity: 0;
  }

  .gnav_contact-sp {
    right: 10px;
    a {
      width: 100%;
      height: 100%;
      border-radius: 0 0 8px 8px;
      background-color: $grn-3f;
      svg {
        width: 36px;
        margin-bottom: 4px;
        fill: #fff;
      }
      span {
        display: block;
        color: #fff;
        font-size: sprem(12);
        line-height: 1;
        letter-spacing: 0;
      }
    }
  }

  /* ---CTA --- */
  .gnav--tel {
    margin-left: 0;
    text-align: center;
  }
  .gnav--contact {
    margin-left: 0;
  }
  .gnav--contact a {
    padding: 16px;
    margin-left: auto;
    margin-right: auto;
  }
}
@include mq(sp) {
  .gnav--menu_sp a {
    padding: 19px 16px;
  }
}