@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- カスタム詳細ページ共通 --- */
  .single {
    .post--info {
      row-gap: 8px;
    }
  }

  /* --- single-works --- */
  .single-works {
    .main_column {
      overflow: inherit;
    }
    .caution {
      small{
        @include mq(sp) {
          font-size: sprem(11);
        }
      }
    }
    //看板詳細
    .works_info {
      .table2 {
        width: 100%;
        th {
          width: 210px;
          @include mq(sp) {
            width: inherit;
          }
        }
      }
    }
    //施工中の写真
    .works_befoaf {
      .content_wrapper {
        position: relative;
        &:not(:last-child) {
          margin-bottom: 20px;
          @include mq(sp) {
            margin-bottom: 48px;
          }
        }
        .content {
          position: relative;
          width: calc((100% - 80px) / 2);
          @include mq(sp) {
            width: 100%;
          }
          span {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            padding: 0 1em;
            font-size: rem(18);
            font-family: var(--font-jp);
            letter-spacing: .05em;
            line-height: 1.6;
            background-color: $yell-f5;
            border-radius: 8px 0 0 0;
          }
          img {
            width: 100%;
            margin: 0;
            border-radius: 8px;
          }
          &.left {
            @include mq(sp) {
              margin-bottom: 40px;
            }
            &::before {
              content: "";
              position: absolute;
              top: calc(50% + 12px);
              right: -50px;
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 25px 0 25px 20px;
              border-color: transparent transparent transparent $yell-f5;
              @include mq(sp) {
                top:inherit;
                left: 50%;
                bottom: -32px;
                right: inherit;
                transform: translateX(-50%);
                border-width: 20px 25px 0 25px;
                border-color: $yell-f5 transparent transparent transparent;
              }
            }
          }
        }
      }
    }
    // 塗装工事の現場レポート
    .works_report {
      .works_report_inner {
        gap: 20px;
        flex-wrap: wrap;
        .item {
          width: calc((100% - 40px) / 3);
          @include mq(sp) {
            width: 100%;
          }
          .img {
            margin-bottom: 12px;
            @include mq(sp) {
              margin-bottom: 6px;
            }
            img {
              margin: 0;
              border-radius: 8px;
            }
          }
          .txt {
            font-size: rem(20);
            line-height: 1.5;
          }
        }
      }
    }
    .works_comment,
    .works_voice,
    .works_free {
      .mce-content-body {
        padding: 40px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 16px rgba(0, 0, 0, .05);
        @include mq(sp) {
          padding: 20px;
          border-radius: 10px;
        }
      }
    }
  }

  /* --- single-blog --- */
  /* 見出し */
  .single-blog {
    .main_column {
      padding: 40px;
      border-radius: 20px;
      background-color: #fff;
      @include mq(sp) {
        padding: 20px;
        border-radius: 10px;
      }
      .blog--info {
        //ttl
        h2,
        h3,
        h4 {
          font-family: var(--font-jp);
          line-height: 1.5;
          letter-spacing: .05em;
        }
        h2:not(:first-child),
        h3:not(:first-child),
        h4:not(:first-child) {
          margin-top: 2em;
        }
        h2:not(:last-child),
        h3:not(:last-child),
        h4:not(:last-child) {
          margin-bottom: .6em;
        }
        h2 {
          font-size: rem(32);
          padding: 0 .375em .25em .375em;
          border-bottom: 1px solid $bro-63;
          @include mq(sp) {
            font-size: sprem(24);
          }
        }
        h3 {
          font-size: rem(24);
          color: var(--clr-main, #1d7fb7);
          @include mq(sp) {
            font-size: sprem(20);
          }
        }
        h4:not(.ttl-04) {
          position: relative;
          font-size: rem(18);
          padding-left: 1em;
          @include mq(sp) {
            font-size: sprem(16);
          }
        }
        h4:not(.ttl-04)::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: .3em;
          height: 100%;
          background-color: var(--clr-main, #1d7fb7);
          border-radius: .15em;
        }
        table {
          border-collapse: collapse;
          tr {
            td {
              border: 1px solid #402c1a;
            }
          }
        }
        .aligncenter {
          text-align: center;
        }
        .mce-content-body a {
          display: block !important;
        }
      }
    }
    .blog--related {
      .post--date {
        margin-bottom: 10px;
      }
      .cat_list {
        margin-bottom: 10px;
        a {
          padding: 0 1em;
          line-height: 2; 
        }
      }
    }
  }

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: rem(32);
    img {
      width: 100%;
    }
  }

  /* --- 目次 --- */
  .ez-toc-debug-messages {
    display: none;
  }
  div#ez-toc-container {
    padding: rem(24);
    background-color: #fafafa;
  }
  .ez-toc-title-container {
    margin-bottom: rem(10);
  }
  div#ez-toc-container p.ez-toc-title {
    color: var(--clr-body, #333);
    font-weight: bold;
    font-size: rem(20);
  }
  #ez-toc-container.counter-hierarchy ul {
    font-size: rem(17);
  }
  #ez-toc-container.counter-hierarchy ul li {
    margin-top: rem(8);
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: rem(16);
  }
  #ez-toc-container.counter-hierarchy ul ul a {
    position: relative;
    display: inline-block;
    padding-left: rem(16);
    &::before {
      content: "";
      position: absolute;
      top: rem(10);
      left: 0;
      width: rem(8);
      height: 0;
      border-bottom: 1px solid;
    }
  }

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: rem(5);
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: rem(4) rem(6);
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: rem(4);
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default, all .4s ease-out);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* blog--share
**************************************** */
  .blog--share {
    margin-top: rem(32);
  }
  .blog--share_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(6);
    @include mq(sp) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .blog--share_list a {
    display: flex;
    @include center-flex;
    height: rem(40);
    padding: .5em;
    line-height: 1;
    font-size: rem(12);
    font-weight: bold;
    border: 1px solid #ddd;
  }
  .blog--share_list li {
    &.twitter a { color: #2BA1F2; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .blog--share_list img {
    margin-right: rem(10);
  }
  .blog--share_list a:hover {
    opacity: .5;
  }

/* blog--related
**************************************** */
  .posts_scroll {
    overflow: auto;
    padding-bottom: rem(32);
  }
  .posts_scroll .post {
    display: block;
    width: 46%;
    flex-shrink: 0;
    @include mq(sp) {
      width: 70%;
    }
  }
  .posts_scroll .post:not(:last-child) {
    margin-right: 1rem;
  }
  .posts_scroll .post--img {
    margin-bottom: 1rem;
    border-radius: 8px;
    aspect-ratio: 256/184;
    overflow: hidden;
  }
  .posts_scroll .post--txt {
    overflow: hidden; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; 
  }
  .posts_scroll::-webkit-scrollbar {
    height: rem(6);
  }
  .posts_scroll::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  .posts_scroll::-webkit-scrollbar-track-piece {
    background-color: #eee;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 24px;
    }
  }
  .wp-pagenavi .pages {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: .5em 1em;
    color: $bro-63;
    background-color: var(--btn-bg-main);
    border: 1px solid $yell-f5;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .wp-pagenavi a:hover {
    background-color: #fff;
  }
  .wp-pagenavi > *:not(.pages) {
    max-width: calc(100% / 2.6);
  }
  .archive .wp-pagenavi > *:not(.pages) {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(40);
    @include mq(sp) {
      @include sprect(32);
    }
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 40px;
  }
  .wp-pagenavi > *:not(.pages) {
    margin-bottom: 4px;
  }
  .wp-pagenavi > *:not(.pages):not(:last-child) {
    margin-right: 4px;
  }
  .wp-pagenavi .extend {
    width: 24px !important;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    width: 8px;
    height: 8px;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: -6px;
    @include mq(sp) {
      left: -5px;
    }
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: -6px;
    @include mq(sp) {
      right: -5px;
    }
  }

@include mq(sp) {
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 32px;
  }  
}
