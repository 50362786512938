@use '../abstracts' as *;

/* contact
********************************************** */
.contact .cta_tel {
  width: 100%;
  max-width: 400px;
  @include auto-margin;
}
.contact .cta_tel a {
  padding: 24px 40px;
  color: $bro-63;
  background-color: $yell-f5;
  border-radius: 8px;
  @include mq(sp) {
    display: block;
    padding: 16px;
  }
  .tel {
    display: flex;
    @include mq(sp) {
      margin-bottom: 8px;
    }
    img {
      display: block;
      width: 30px;
      margin-right: 8px;
    }
  }
}