@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: 26px 36px;
  border-radius: 8px;
  width: fit-content;
  min-width: min(288px,100%);
  @include lh(16,26);
  letter-spacing: .05em;
  font-family: var(--font-jp);
  text-align: center;
  background-color: var(--btn-bg-main, #f5c246);
  border: 1px solid var(--btn-bg-main, #f5c246);
  &[href$=".pdf"] {
    padding: 27px 52px;
  }
  @include mq(sp) {
    min-width: 100%;
    padding: 20px 36px;
  }
  &:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before {
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    right: 16px;
    display: block;
    @include rect(8,12);
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    background-color: $bro-63;
    transition: var(--transit-default, all .4s ease-out);
  }
  &:hover {
    background-color: #fff;
  }
}
.flex > .btn a { min-width: 100%; }
.btn.txt-ctr a {
  @include auto-margin;
}
.btn a {
  svg {
    fill: var(--btn-clr-main, #fff);
  }
  &:hover {
    svg {
      fill: $bro-63;
    }
  }
}
.btn.btn-next_page a {
  min-width: 100%;
  text-align: left;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #333);
  background-color: var(--btn-bg-wht, #fff);
  border: inherit;
  &:hover {
    color: #fff;
    background-color: $yell-f5;
    &:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])::before {
      background-color: #fff;
    }
  }
}
.btn-wh svg {
  fill: var(--btn-clr-wht, #333);
}
.btn-wh a:hover svg {
  fill: var(--btn-clr-wht-hvr, #fff);
}
@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}